import { ProposalSummary } from "../models/app/proposal";
import Button from "./Button";
import { CardDataFieldLabel } from "./Card";
import DialogBox, { useDialogBoxState } from "./DialogBox";
import { dialogWidths } from "./DialogBox/DialogBox";

export type ProposalCoverLetterButtonProps = {
    proposal: ProposalSummary
    className?: string
}

const ProposalCoverLetterButton = ({
    proposal,
    className,
}: ProposalCoverLetterButtonProps) => {
    const dialogState = useDialogBoxState();

    const portfolioHref = proposal.portfolioUrl.startsWith("http") ?
        proposal.portfolioUrl :
        `//${proposal.portfolioUrl}`;

    return (
        <span className={className}>
            <Button variant="secondary" onClick={dialogState.open}>Cover letter</Button>
            <DialogBox {...dialogState} title="Cover letter" minWidth={dialogWidths.medium}>
                <div className="space-y-6 mt-6 text-left">
                    <div>
                        <CardDataFieldLabel>Introduction</CardDataFieldLabel>
                        <p>
                            {proposal.introduction}
                        </p>
                    </div>
                    <div>
                        <CardDataFieldLabel>Relevant experience</CardDataFieldLabel>
                        <p>
                            {proposal.relevantExperience}
                        </p>
                    </div>
                    <div>
                        <CardDataFieldLabel>Portfolio link</CardDataFieldLabel>
                        <a href={portfolioHref} target="_blank">
                            {proposal.portfolioUrl || "-"}
                        </a>
                    </div>
                </div>
            </DialogBox>
        </span>
    );
};

export default ProposalCoverLetterButton;
