import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";

import Button from "../Button";
import FormRadioGroup from "../FormRadioGroup";
import { useOrganizationContext } from "../../api/current-organization/organizationContext";
import { RoleFormValues, buildRoleFormValidationSchemaFields } from "../RoleForm";
import { roleAssignmentTypeOptions, roleVisibilityOptions } from "../../models/app/role";
import { formClassNames } from "../CreateGigForm/classNames";
import CreateFormPageLayout from "../CreateGigForm/CreateGigFormPageLayout";

export type CreateRoleFormStepVAssignmentType = Pick<RoleFormValues, "roleAssignmentTypeId">;

export type CreateRoleFormStepAssignmentTypeProps = {
    onSubmit: (value: CreateRoleFormStepVAssignmentType) => void
    onBackClick: (value: CreateRoleFormStepVAssignmentType) => void
    defaultValues: CreateRoleFormStepVAssignmentType
}

const CreateRoleFormStepAssignmentType = ({
    onSubmit,
    onBackClick,
    defaultValues,
}: CreateRoleFormStepAssignmentTypeProps) => {
    const schema = buildRoleFormValidationSchemaFields();

    const validationSchema: yup.SchemaOf<CreateRoleFormStepVAssignmentType> = yup.object({
        roleAssignmentTypeId: schema.fields.roleAssignmentTypeId,
    });

    const methods = useForm<CreateRoleFormStepVAssignmentType>({
        resolver: yupResolver(validationSchema),
        defaultValues,
    });
    
    const handleBackClick = () => {
        onBackClick(methods.getValues());
    };

    return (
        <form
            className={formClassNames}
            onSubmit={methods.handleSubmit(onSubmit)}
            noValidate
        >
            <CreateFormPageLayout
                title={"Role assignment type"}
                description={"Is this role to be assigned directly or open to applications?"}
                content={
                    <Controller
                        name="roleAssignmentTypeId"
                        control={methods.control}
                        render={({ field: { onChange, value } }) => (
                            <FormRadioGroup
                                required
                                label={"Role assignment type"}
                                options={roleAssignmentTypeOptions}
                                error={methods.formState.errors.roleAssignmentTypeId}
                                defaultValue={value}
                                onChange={onChange}
                                value={value}
                            />
                        )}
                    />
                }
                iconName="ClipboardText"
                buttons={
                    <>
                        <Button variant="secondary" onClick={handleBackClick} type="button">Back</Button>
                        <Button type="submit">Next</Button>
                    </>
                }
            />
        </form>
    );
};

export default CreateRoleFormStepAssignmentType;