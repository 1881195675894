import { useEffect, useState } from "react";
import { GigApiFetcherResponse, useAuthenticatedGigApiFetcher } from "./common/fetching";
import { useGiggedApiSWR } from "./common/giggedApiSWR";
import { RoleAssignmentDetailsDto } from "../models/app/roleAssignment";
import { ApiRoleAssignmentDetailsDto } from "../models/api/roleAssignment";
import { mapFromApiRoleAssignmentDetailsDto } from "../models/mappers/roleAssignment";
    
export const useCancelRoleAssignment = () => useAuthenticatedGigApiFetcher<never, void, string>("PUT",
    (roleAssignmentId) => ({
        url: `api/me/role-assignments/${roleAssignmentId}/actions/cancel`,
    }));

export type UseRoleAssignmentReturn<TGigId extends string | undefined> = TGigId extends undefined ?
    { roleAssignment: undefined } :
    {
        roleAssignment: RoleAssignmentDetailsDto
        cancelRoleAssignment: () => Promise<GigApiFetcherResponse<void>>
        isLoading: boolean
        isCancellingRoleAssignment: boolean
    }

export const useRoleAssignment = <TRoleAssignmentId extends string | undefined>(roleAssignmentId?: TRoleAssignmentId): UseRoleAssignmentReturn<TRoleAssignmentId> => {
    const [roleAssignment, setRoleAssignment] = useState<RoleAssignmentDetailsDto | undefined>();
    const [cancelRoleAssignment, isCancellingRoleAssignment] = useCancelRoleAssignment();
    
    const url = `api/role-assignments/${roleAssignmentId}`;

    const apiCall = useGiggedApiSWR<ApiRoleAssignmentDetailsDto>(url);

    useEffect(() => {
        if (apiCall.data === undefined) {
            setRoleAssignment(undefined);
            return;
        } 
        
        setRoleAssignment(mapFromApiRoleAssignmentDetailsDto(apiCall.data));
    }, [apiCall.data]);

    if (roleAssignmentId === undefined) return { roleAssignment: undefined } as UseRoleAssignmentReturn<TRoleAssignmentId>;

    return {
        ...apiCall,
        roleAssignment,
        isLoading: apiCall.isLoading,
        cancelRoleAssignment: async () => {
            const response = await cancelRoleAssignment(roleAssignmentId);

            if (response.success) {
                apiCall.mutate();
            }

            return response;
        },
        isCancellingRoleAssignment
    } as UseRoleAssignmentReturn<TRoleAssignmentId>;
};