import { MsalProvider } from "@azure/msal-react";
import { msalInstance } from ".";
import AuthContext from "./authContext";
import { useLoginRequest } from "./useLoginRequest";
import Loader from "../components/Loader";

export type AuthContextProviderProps = {
    children: React.ReactNode
}

const AuthContextProvider = ({
    children,
}: AuthContextProviderProps) => {
    const { loginRequest, isLoading } = useLoginRequest();

    if (isLoading || !loginRequest) {
        return <Loader />;
    }

    return (
        <AuthContext.Provider value={{
            loginRequest
        }}>
            <MsalProvider instance={msalInstance}>
                {children}
            </MsalProvider>
            
        </AuthContext.Provider>
    );
};

export default AuthContextProvider;