import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";

import Button from "../Button";
import FormRadioGroup from "../FormRadioGroup";
import CreateFormPageLayout from "./CreateGigFormPageLayout";
import { GigFormValues, buildGigFormValidationSchemaFields } from "../GigForm";
import { initialStageOptions } from "../../models/app/gig";
import { formClassNames } from "./classNames";
import { useOrganizationContext } from "../../api/current-organization/organizationContext";

export type CreateGigFormStep6CurrentStageFields = Pick<GigFormValues, "initialStageId">;

export type CreateGigFormStep6CurrentStageProps = {
    onSubmit: (value: CreateGigFormStep6CurrentStageFields) => void
    onBackClick: (value: CreateGigFormStep6CurrentStageFields) => void
    defaultValues: CreateGigFormStep6CurrentStageFields
}

const CreateGigFormStep6CurrentStage = ({
    onSubmit,
    onBackClick,
    defaultValues,
}: CreateGigFormStep6CurrentStageProps) => {
    const { gigTerminology } = useOrganizationContext();

    const validationSchema: yup.SchemaOf<CreateGigFormStep6CurrentStageFields> = yup.object({
        initialStageId: buildGigFormValidationSchemaFields.initialStageId,
    });

    const methods = useForm<CreateGigFormStep6CurrentStageFields>({
        resolver: yupResolver(validationSchema),
        defaultValues,
    });
    
    const handleBackClick = () => {
        onBackClick(methods.getValues());
    };

    return (
        <form
            className={formClassNames}
            onSubmit={methods.handleSubmit(onSubmit)}
            noValidate
        >
            <CreateFormPageLayout
                title={`Let us know where you're currently at with your ${gigTerminology.toLowerCase()}.`}
                description="Has there been any work done on it previously or are you starting from scratch?"
                content={
                    <Controller
                        name="initialStageId"
                        control={methods.control}
                        render={({ field: { onChange, value } }) => (
                            <FormRadioGroup
                                required
                                label={`Stage of the ${gigTerminology.toLowerCase()}`}
                                options={initialStageOptions}
                                error={methods.formState.errors.initialStageId}
                                defaultValue={value}
                                onChange={onChange}
                                value={value}
                            />
                        )}
                    />
                }
                iconName="Path"
                buttons={
                    <>
                        <Button variant="secondary" onClick={handleBackClick} type="button">Back</Button>
                        <Button type="submit">Next</Button>
                    </>
                }
            />
        </form>
    );
};

export default CreateGigFormStep6CurrentStage;