import { useApplications } from "../api/applications";
import ApplicationSummaryCard from "../components/ApplicationSummaryCard";
import Card from "../components/Card";
import Loader from "../components/Loader";
import PageContent from "../components/PageContent";
import Typography from "../components/Typography";
import { useIsLargeScreen } from "../hooks/screenSizes";

const ApplicationsListPage = () => {
    const { applications, isLoading } = useApplications();
    const isLargeScreen = useIsLargeScreen();

    if (isLoading) return <Loader />;
    return (
        <PageContent>
            <Typography variant={isLargeScreen ? "display-large" : "display-small"} component="h1" gutterBottom>Role Applications</Typography>
            {applications.length === 0 ? (
                <Card>
                    <Typography variant="title-large" component="p">None yet...</Typography>
                </Card>
            ) : (
                <div className="space-y-8">
                    {applications.map(application => (
                        <ApplicationSummaryCard key={application.id} application={application} />
                    ))}
                </div>
            )}
        </PageContent>
    );
};

export default ApplicationsListPage;