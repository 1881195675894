import { useTalentRoleAssignments } from "../api/talentRoleAssignments";
import Card from "../components/Card";
import Loader from "../components/Loader";
import PageContent from "../components/PageContent";
import Typography from "../components/Typography";
import { useIsLargeScreen } from "../hooks/screenSizes";
import TalentRoleAssignmentCard from "../components/RoleAssigmentCardTalent";

const TalentRoleAssignmentsPage = () => {
    const { roleAssignments, isLoading } = useTalentRoleAssignments();
    const isLargeScreen = useIsLargeScreen();

    if (isLoading) return <Loader />;

    return (
        <PageContent>
            <Typography variant={isLargeScreen ? "display-large" : "display-small"} component="h1" gutterBottom>Role Assignments</Typography>
            {roleAssignments.length === 0 ? (
                <Card>
                    <Typography variant="title-large" component="p">None yet...</Typography>
                </Card>
            ) : (
                <div className="space-y-8">
                    {roleAssignments.map(roleAssignment => (
                        <TalentRoleAssignmentCard
                            key={roleAssignment.roleAssignmentId}
                            roleAssignment={roleAssignment}
                        />
                    ))}
                </div>
            )}
        </PageContent >
    );
};

export default TalentRoleAssignmentsPage;