import { Link, useNavigate } from "react-router-dom";
import { useAcceptProposal } from "../../api/proposal";
import Button from "../../components/Button";
import Card, { CardDataField } from "../../components/Card";
import DialogBox, { useDialogBoxState } from "../../components/DialogBox";
import ProposalCoverLetterButton from "../../components/ProposalCoverLetterButton";
import ProposalFilesButton from "../../components/ProposalFilesButton";
import Typography from "../../components/Typography";
import UserAvatar from "../../components/UserAvatar";
import { ProposalSummary } from "../../models/app/proposal";
import { useOrganizationContext } from "../../api/current-organization/organizationContext";
import { useAppPaths } from "../../Routes";
import { DEFAULT_PROPOSAL_MILESTONE } from "../../utils/constants";
import { useClientPersonalInformationContext } from "../../api/clientPersonalInformation";
import { dialogWidths } from "../../components/DialogBox/DialogBox";
import BadgeList from "../../components/BadgeList";

export type GigProposalCardProps = {
    proposal: ProposalSummary
}

const GigProposalCard = ({
    proposal,
}: GigProposalCardProps) => {
    const { gigTerminology } = useOrganizationContext();
    const appPaths = useAppPaths();
    const confirmHireDialogState = useDialogBoxState();
    const [acceptProposal, isSubmitting] = useAcceptProposal();
    const navigate = useNavigate();

    const {
        isLoading: isLoadingClientPersonalInformation
    } = useClientPersonalInformationContext();

    if (isLoadingClientPersonalInformation) {
        return <p>Loading...</p>;
    }

    const handleConfirmHire = async () => {
        const response = await acceptProposal(proposal.id);

        if (response.success) {
            navigate(appPaths.gigs.ongoing);
        }
    };

    const handleHire = async () => {
        confirmHireDialogState.open();
    };

    const userDisplayName = `${proposal.talentFirstName} ${proposal.talentLastName}`;
    const displayBadgeSection = proposal.badges.length > 0;

    return (
        <div className="md:flex md:space-x-4 max-w-lg md:max-w-none mx-auto">
            <Card className="pb-0 md:pb-6 flex flex-col justify-center items-center rounded-b-none md:rounded-lg md:w-1/5 min-w-[150px]">
                <Link target="blank" to={appPaths.talents.findTalentDetails(proposal.talentId)}>
                    <UserAvatar name={userDisplayName} userImageUrl={proposal.talentProfileImageThumbnailUrl} />
                </Link>
                {displayBadgeSection &&
                    <BadgeList badges={proposal.badges} keyPrefix={proposal.talentId.toString()} className="mt-2" />
                }
            </Card>
            <Card key={proposal.id} className="grow flex">
                <div className="md:flex md:grow items-center space-y-4 md:space-y-0 rounded-t-none md:rounded-lg">
                    <div className="flex flex-col md:flex-row md:ml-4 md:space-x-4 space-y-2 md:space-y-0 items-center md:justify-between md:w-full text-center md:text-left">
                        <CardDataField label={gigTerminology} className="w-64 truncate">{proposal.gigTitle}</CardDataField>
                        <CardDataField label="Expected duration">{proposal.gigExpectedDurationLabel}</CardDataField>
                        <div className="flex flex-col md:flex-row gap-4">
                            <ProposalCoverLetterButton proposal={proposal} />
                            <ProposalFilesButton proposal={proposal} />
                            <Button variant="primary" onClick={handleHire}>Hire</Button>
                        </div>
                    </div>
                </div>
                <div className="md:flex md:justify-between md:items-center">
                    {proposal.milestones.length > 0 && proposal.milestones[0].description !== DEFAULT_PROPOSAL_MILESTONE &&
                        <div className="mt-4 flex flex-col md:ml-4 text-center md:text-left">
                            <Typography variant="body" component="p" className="font-bold">Proposed milestones</Typography>
                            {proposal.milestones.map((proposalMilestone, i) =>
                                <Typography variant="body" component="p" className="text-title-small">
                                    {i + 1}. {proposalMilestone.description}
                                </Typography>
                            )}
                        </div>
                    }
                    {/* {proposal.milestones.length === 1 && proposal.milestones[0].description === DEFAULT_PROPOSAL_MILESTONE &&
                        <div className="mt-4 flex flex-col md:ml-4 text-center md:text-left">
                            <CardDataField label="Proposed amount">
                                £{!proposal.isVATRegistered ? proposal.milestones[0].amount.toFixed(2) : ( proposal.milestones[0].amount * config.VatMultiplier).toFixed(2)}
                                {proposal.isVATRegistered ? <span> (incl. 20% VAT)</span> : null}
                            </CardDataField>
                        </div>
                    } */}
                </div>

                <DialogBox
                    {...confirmHireDialogState}
                    title={`Are you sure you want to hire ${userDisplayName}?`}
                    maxWidth={dialogWidths.small}
                >
                    <div className="flex items-center sm:justify-between sm:space-x-4 flex-col-reverse sm:flex-row">
                        <Button className="w-full sm:w-fit mt-4 sm:mt-0" disabled={isSubmitting} variant="secondary" onClick={confirmHireDialogState.close}>Cancel</Button>
                        <Button className="w-full sm:w-fit" loading={isSubmitting} variant="primary" onClick={handleConfirmHire}>Confirm</Button>
                    </div>
                </DialogBox>
            </Card>
        </div>
    );
};

export default GigProposalCard;
