import React, { useState } from "react";
import { silentlyReplaceSearchParams } from "../../utils/searchParams";
import { useRoles } from "../../api/roles";
import FindARoleContext from "./findARoleContext";
import { roleStatuses } from "../../models/app/role";

export type FindARoleContextProviderProps = {
    children: React.ReactNode
}

const FindARoleContextProvider = ({
    children,
}: FindARoleContextProviderProps) => {
    
    const {
        roles,
        isLoading,
        setSearchTerm: setUseRolesSearchTerm,
        setSkillIds: setUseRolesSkillIds,
        sortOrder,
        orderBy,
        searchTerm: searchTermQueryParam,
        skillIds: skillIdsQueryParam,
        setSortOrder,
        setOrderBy,
        refetchRoles
    } = useRoles({
        roleStatusIds: [roleStatuses.open, roleStatuses.active],
        allClients: true,
        initialOrderBy: "CreatedAt",
        initialSortOrder: "desc",
    });

    const filteredRoles = roles?.filter(role => 
        role.roleStatusId === roleStatuses.open || 
        (role.roleStatusId === roleStatuses.active && role.allowMultipleHires)
    );

    const [searchTerm, setSearchTerm] = useState(searchTermQueryParam);
    const [skillIds, setSkillIds] = useState<string[]>(skillIdsQueryParam);

    const anyActiveFilters =
    searchTerm.length > 0 ||
    skillIds.length > 0;
    
    const clearAllFilters = () => {
        setSearchTerm("");
        setSkillIds([]);
        setUseRolesSearchTerm("");
        setUseRolesSkillIds([]);
        silentlyReplaceSearchParams("");
    };
    
    const searchNow = () => {
        setUseRolesSearchTerm(searchTerm);
        setUseRolesSkillIds(skillIds);
    };

    return (
        <FindARoleContext.Provider value={{
            roles: filteredRoles as typeof roles,
            isLoading,
            searchTerm,
            skillIds,
            sortOrder,
            orderBy,
            anyActiveFilters,
            refetchRoles,
            setSearchTerm,
            setSkillIds,
            searchNow,
            setSortOrder,
            setOrderBy,
            clearAllFilters,
        }}>
            {children}
        </FindARoleContext.Provider>
    );
};

export default FindARoleContextProvider;
