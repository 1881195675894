import Typography from "../Typography";
import * as icons from "phosphor-react";
import TitleUnderline from "../TitleUnderline";

export type IconName =
    "TextAa" |
    "Clock" |
    "CalendarCheck" |
    "HourglassLow" |
    "ClipboardText" |
    "Path" |
    "Money" |
    "Laptop" |
    "SquaresFour" |
    "Users" |
    "Eye";

export type CreateFormPageLayoutProps = {
    title: string,
    description?: string,
    content: JSX.Element,
    buttons: JSX.Element,
    iconName: IconName
    helpText?: React.ReactNode
}

const CreateFormPageLayout = ({
    title,
    description,
    content,
    buttons,
    iconName,
    helpText
}: CreateFormPageLayoutProps) => {

    const Icon = icons[iconName];

    return (
        <div className="bg-surface md:flex-grow flex flex-col">
            <div className="md:flex md:h-fullmd:flex-grow">
                <div className="md:w-1/2 w-full bg-gray-100 md:pr-8">
                    <Icon size={48} weight="duotone" className="mr-auto mb-6 md:mb-2 text-primary-text" />
                    <Typography
                        variant="display-medium"
                        component="h1"
                        className="text-left text-[36px] md:text-display-medium"
                        gutterBottom
                    >
                        {title}
                    </Typography>
                    <TitleUnderline className="md:mx-0 mr-auto" />
                    {description && (
                        <Typography
                            variant="title-small"
                            component="p"
                            className="text-[16px] text-left"
                        >
                            {description}
                        </Typography>
                    )}
                </div>
                <div className="flex flex-col space-y-4 md:w-1/2 w-full md:pl-8 mt-16 md:mt-0">
                    <div>
                        {content}
                    </div>
                    <div>
                        {helpText}
                    </div>
                </div>
            </div>
            <div className="flex space-x-4 md:mt-auto mt-8 md:ml-auto child:flex-grow md:child:flex-grow-0">
                {buttons}
            </div>
        </div>
    );
};

export default CreateFormPageLayout;
