import React from "react";
import { SortOrder } from "../../api/common/sortOrder";

import contextPlaceholderFunc from "../../utils/contextPlaceholderFun";
import { UseRolesReturn } from "../../api/roles";

export type FindARoleContextType = Pick<UseRolesReturn, "roles" | "isLoading" | "setSearchTerm"> & {
    searchTerm: string
    skillIds: string[]
    sortOrder: SortOrder
    orderBy: string
    anyActiveFilters: boolean
    setSkillIds: (skillIds: string[]) => void
    setSortOrder: (order: SortOrder) => void
    setOrderBy: (value: string) => void
    clearAllFilters: () => void
    searchNow: () => void
    refetchRoles: () => void
}

export const FindARoleContext = React.createContext<FindARoleContextType>({
    roles: [],
    isLoading: false,
    anyActiveFilters: false,
    setSearchTerm: contextPlaceholderFunc,
    setSkillIds: contextPlaceholderFunc,
    setSortOrder: contextPlaceholderFunc,
    setOrderBy: contextPlaceholderFunc,
    searchTerm: "",
    skillIds: [],
    sortOrder: "asc",
    orderBy: "Title",
    clearAllFilters: contextPlaceholderFunc,
    searchNow: contextPlaceholderFunc,
    refetchRoles: contextPlaceholderFunc
});

export const useFindARoleContext: () => FindARoleContextType = () => React.useContext(FindARoleContext);

export default FindARoleContext;