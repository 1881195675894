import { CareerHistoryFormValues } from "./CareerHistoryFormValues";
import HistoryListAddItemButton from "./HistoryListAddItemButton";
import CareerHistoryCard from "./CareerHistoryCard";
import { useTalentCareerHistories } from "../../api/talentCareerHistories";
import { useDialogBoxState } from "../../components/DialogBox";
import { successToast } from "../../toast";
import { TalentCareerHistoryJobDto } from "../../models/app/talentCareerHistory";
import { CareerHistoryFormDialog } from "./CareerHistoryFormDialog";
import FormInputLabel from "../../components/FormInputLabel";
import { talentProfileEditTooltips } from "../../tooltipsContent";
import { useOrganizationContext } from "../../api/current-organization/organizationContext";

export const CareerHistory = () => {
    const {
        careerHistories,
        addJob,
        removeJob,
        updateJob,
    } = useTalentCareerHistories();

    const { gigTerminologyPlural } = useOrganizationContext();

    const createCareerHistoryDialogState = useDialogBoxState();

    const handleAddJob = async (values: CareerHistoryFormValues) => {
        if (values.startDate === null) throw new Error("Expected start date not to be null.");

        const response = await addJob({
            ...values,
            startDate: values.startDate as Date,
            endDate: values.endDate || undefined,
        });

        if (response.success) {
            successToast("Job successfully added.");
            createCareerHistoryDialogState.close();
        }

        return response;
    };

    const makeUpdateJobHandler = (id: string) => async (values: CareerHistoryFormValues) => {
        const response = await updateJob(id, {
            ...values,
            startDate: values.startDate as Date,
            endDate: values.endDate || undefined,
        });

        if (response.success) {
            successToast("Job successfully updated.");
        }

        return response;
    };

    const makeRemoveJobHandler = (id: string) => async () => {
        const response = await removeJob(id);

        if (response.success) {
            successToast("Job successfully removed.");
        }

        return response;
    };

    return (
        <>
            <div>
                <FormInputLabel className="ml-0 mb-4" tooltipText={talentProfileEditTooltips.careerHistory(gigTerminologyPlural)}>Career History</FormInputLabel>
                <div className="space-y-4">
                    <HistoryListAddItemButton onClick={createCareerHistoryDialogState.open}>Add job</HistoryListAddItemButton>
                    {careerHistories.map((careerHistory: TalentCareerHistoryJobDto) => (
                        <CareerHistoryCard
                            key={careerHistory.id}
                            careerHistory={careerHistory}
                            onRemove={makeRemoveJobHandler(careerHistory.id)}
                            updateJob={makeUpdateJobHandler(careerHistory.id)}
                        />
                    ))}
                </div>
            </div>
            <CareerHistoryFormDialog
                {...createCareerHistoryDialogState}
                initialValues={{
                    startDate: null,
                    endDate: null,
                    companyTitle: "",
                    jobTitle: "",
                    description: "",
                }}
                resetOnSubmit
                onSubmit={handleAddJob}
            />
        </>
    );
};

export default CareerHistory;