import clsx from "clsx";
import * as icons from "phosphor-react";

export type CardDataFieldLabelProps = {
    children: React.ReactNode
    iconName?: "Clock"
    className?: string
}

const CardDataFieldLabel = ({
    children,
    iconName,
    className
}: CardDataFieldLabelProps) => {
    const Icon = iconName && icons[iconName];

    return (
        <label className={clsx("flex items-center mb-1 space-x-2", className)}>
            <span className="font-bold w-full">{children}</span>
            {Icon && (
                <Icon className="text-primary-text inline" size={16} weight="bold" />
            )}
        </label>
    );
};

export default CardDataFieldLabel;
