import { yupResolver } from "@hookform/resolvers/yup";
import clsx from "clsx";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import ApiError from "../../api/common/apiError";
import { GigApiFetcherResponse } from "../../api/common/fetching";
import { useOrganizationContext } from "../../api/current-organization/organizationContext";
import { ApiFile } from "../../models/api/file";
import { talentProposalTooltips } from "../../tooltipsContent";
import { convertFileToDataUrl } from "../../utils/convertFileToDataUrl";
import Button from "../Button";
import { formClassNames } from "../CreateGigForm/classNames";
import FileManager from "../FileManager";
import FormTextAreaInput from "../FormTextAreaInput";
import FormTextInput from "../FormTextInput";
import FormToggle from "../FormToggle";
import GeneralFormError from "../GeneralFormError";
import ProposalFormMilestones from "./CreateProposalFormMilestones";
import { CreateProposalFormValues, validationSchemaFields } from "./CreateProposalFormValues";

const validationSchema = yup.object(validationSchemaFields);

export type CreateProposalFormProps = {
    initialValues?: CreateProposalFormValues
    onSubmit: (value: CreateProposalFormValues, proposalFiles: ApiFile[]) => Promise<GigApiFetcherResponse<unknown>>
    submitButtonText: string
    isSubmitting: boolean
    proposalFiles?: ApiFile[]
}

const CreateProposalForm = ({
    initialValues,
    onSubmit,
    submitButtonText,
    isSubmitting,
    proposalFiles: initialProposalFiles = [],
}: CreateProposalFormProps) => {
    const { gigTerminology, gigTerminologyPlural, giggedClientTerminology } = useOrganizationContext();

    const [proposalFiles, setProposalFiles] = useState<ApiFile[]>(initialProposalFiles);
    const [submissionError, setSubmissionError] = useState<ApiError | undefined>(undefined);

    const handleSubmit = async (values: CreateProposalFormValues) => {

        const response = await onSubmit(values, proposalFiles);

        if (!response.success) setSubmissionError(response.error);
    };

    const methods = useForm<CreateProposalFormValues>({
        defaultValues: initialValues,
        resolver: yupResolver(validationSchema)
    });

    const talentDefinedMilestones = methods.watch("talentDefinedMilestones");

    const handleFileDrop = async (file: File) => {
        const dataUrl = await convertFileToDataUrl(file);

        const proposalFile: ApiFile = {
            id: Math.random().toString(),
            url: dataUrl,
            name: file.name,
            totalBytes: file.size
        };

        setProposalFiles([...proposalFiles, proposalFile]);
    };

    const handleFileDelete = (fileId: string) => {
        const updatedProposalFiles = proposalFiles.filter(proposalFile => proposalFile.id !== fileId);
        setProposalFiles(updatedProposalFiles);
    };

    return (
        <form className={clsx(formClassNames, "p-8 space-y-8")} noValidate onSubmit={methods.handleSubmit(handleSubmit)}>

            <>
                <Controller
                    name="talentDefinedMilestones"
                    control={methods.control}
                    render={({ field: { onChange, value } }) => (
                        <FormToggle
                            label={`${gigTerminology} milestones`}
                            description={`Split this ${gigTerminology.toLowerCase()} into milestones`}
                            tooltip={talentProposalTooltips.milestones(gigTerminology, giggedClientTerminology)}
                            onChange={onChange}
                            checked={value}
                        />)}
                />
                {talentDefinedMilestones &&
                    <ProposalFormMilestones methods={methods} />
                }
            </>
            
            <FormTextInput
                required
                tooltip={talentProposalTooltips.duration(gigTerminology)}
                id="create-proposal-form-estimated-duration-days"
                label="Estimated duration (days)"
                placeholder="Enter the estimated duration in days"
                error={methods.formState.errors.estimatedDurationDays}
                defaultValue={0}
                type="number"
                min={1}
                step={1}
                {...methods.register("estimatedDurationDays")}
            />

            <FormTextAreaInput
                required
                tooltip={talentProposalTooltips.introduction(giggedClientTerminology)}
                id="create-proposal-form-introduction"
                label="Introduction"
                placeholder="Enter an introduction"
                register={methods.register("introduction")}
                error={methods.formState.errors.introduction}
                defaultValue={""}
            />

            <FormTextAreaInput
                required
                tooltip={talentProposalTooltips.experience(giggedClientTerminology, gigTerminology, gigTerminologyPlural)}
                id="create-proposal-form-relevant-experience"
                label="Relevant experience"
                placeholder="Enter your relevant experience"
                register={methods.register("relevantExperience")}
                error={methods.formState.errors.relevantExperience}
                defaultValue={""}
            />

            <FormTextInput
                id="create-proposal-form-portfolio-url"
                tooltip={talentProposalTooltips.portfolio}
                label="Portfolio URL"
                placeholder="Enter your portfolio URL"
                error={methods.formState.errors.portfolioUrl}
                defaultValue={""}
                {...methods.register("portfolioUrl")}
            />

            <FileManager
                label="Relevant files"
                tooltip={talentProposalTooltips.relevantFiles(giggedClientTerminology)}
                inputId="proposal-files-upload-button"
                maxFileSizeKb={5000}
                existingFiles={proposalFiles}
                onFileDrop={handleFileDrop}
                onFileDelete={handleFileDelete}
            />

            <GeneralFormError error={submissionError} className="ml-8" />

            <div className="flex justify-end">
                <Button loading={isSubmitting} type="submit">{submitButtonText}</Button>
            </div>
        </form>
    );
};

export default CreateProposalForm;