import FormTextInput from "../FormTextInput";
import { RoleFormValues } from "./RoleFormValues";
import FormTextAreaInput from "../FormTextAreaInput";
import SkillsInput from "../SkillsInput";
import { Controller, UseFormReturn, useFieldArray } from "react-hook-form";
import RoleCategorySearchDropdown from "../RoleCategorySearchDropdown";
import FormDropdown from "../FormDropdown";
import { roleAssignmentTypeOptions, roleAssignmentTypes, roleVisibilityOptions } from "../../models/app/role";

export type CreateRoleFormStepSummaryProps = {
    methods: UseFormReturn<RoleFormValues>
    initialValues: RoleFormValues
}

const RoleFormFields = ({
    methods,
    initialValues,
}: CreateRoleFormStepSummaryProps) => {
    const { append: appendSkill, remove: removeSkill } = useFieldArray<RoleFormValues, "skills">({
        control: methods.control,
        name: "skills",
        keyName: "id",
    });

    const { skills } = methods.watch();
    const { roleAssignmentTypeId, roleVisibilityTypeId } = methods.watch();

    return (
        <>
            <div className="md:flex">
                <div className="space-y-8 md:pr-8 md:w-1/2">
                    <FormTextInput
                        required
                        id="create-role-form-summary-title"
                        label="Role title"
                        placeholder="Enter name of the role"
                        error={methods.formState.errors.title}
                        defaultValue={initialValues.title}
                        {...methods.register("title")}
                    />
                    <FormTextAreaInput
                        required
                        id="create-role-form-summary-description"
                        label="Role description"
                        placeholder="Enter role description"
                        error={methods.formState.errors.description}
                        defaultValue={initialValues.description}
                        register={methods.register("description")}
                    />
                    <RoleCategorySearchDropdown
                        defaultValues={initialValues}
                        {...methods.register("roleCategoryId")}
                    />
                </div>
                <div className="space-y-8 md:pl-8 md:w-1/2 mt-8 md:mt-0">
                    <SkillsInput
                        addSkill={appendSkill}
                        removeSkill={removeSkill}
                        skills={skills}
                        error={methods.formState.errors.skills}
                        showError={methods.formState.submitCount > 0}
                        hideDropdown={skills.length === 8}
                        maxSkills={8}
                    />
                    <Controller
                        name="roleAssignmentTypeId"
                        control={methods.control}
                        render={({ field: { onChange, value } }) => (
                            <FormDropdown
                                required
                                label={"Role assignment type"}
                                options={roleAssignmentTypeOptions}
                                error={methods.formState.errors.roleAssignmentTypeId}
                                onChange={onChange}
                                value={value}
                            // tooltip={clientEditGigTooltips.gigStage(gigTerminology)}
                            />
                        )}
                    />
                    {roleAssignmentTypeId === roleAssignmentTypes.applications ? (
                        <Controller
                            name="roleVisibilityTypeId"
                            control={methods.control}
                            render={({ field: { onChange, value } }) => (
                                <FormDropdown
                                    required
                                    label={"Role visibility"}
                                    options={roleVisibilityOptions}
                                    error={methods.formState.errors.roleVisibilityTypeId}
                                    onChange={onChange}
                                    value={value}
                                // tooltip={clientEditGigTooltips.gigStage(gigTerminology)}
                                />
                            )}
                        />
                    ) :  null}
                </div>

            </div>
        </>
    );
};

export default RoleFormFields;
