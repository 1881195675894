import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import useAccessToken from "../auth/useAccessToken";
import { fetchRoleCategories } from "../api/roleCategories";
import { FormSearchDropdown } from "./FormSearchDropdown";
import { FormSearchDropdownOption, LoadOptionsCallback } from "./FormSearchDropdown/FormSearchDropdown";
import { RoleFormValues, buildRoleFormValidationSchemaFields } from "./RoleForm";

export type CreateFormStepRoleCategoryFields = Pick<RoleFormValues, "roleCategoryId">;

export type RoleCategorySearchDropdownProps = {
    defaultValues: CreateFormStepRoleCategoryFields
}

const RoleCategorySearchDropdown = ({
    defaultValues,
}: RoleCategorySearchDropdownProps) => {
    const accessToken = useAccessToken();
    const schema = buildRoleFormValidationSchemaFields();
    const [allCategoryOptions, setAllCategoryOptions] = useState<FormSearchDropdownOption[]>([]);

    const fetchCategoryOptions = async (searchQuery: string) => {
        const categoriesResponse = await fetchRoleCategories(searchQuery, accessToken);

        return categoriesResponse
            .map(category => ({
                value: category.id,
                label: category.name,
            }));
    };

    useEffect(() => {
        if (!accessToken) return;

        const loadAllCategories = async () => {
            const fetchedCategoryOptions = await fetchCategoryOptions("");
            setAllCategoryOptions(fetchedCategoryOptions);
        };

        loadAllCategories();
    }, [accessToken]);

    const loadCategoryOptions = (inputValue: string, callback: LoadOptionsCallback) => {
        fetchCategoryOptions(inputValue)
            .then(options => callback(options));
    };

    const debouncedLoadCategoryOptions = useDebouncedCallback(loadCategoryOptions, 700);

    const validationSchema: yup.SchemaOf<CreateFormStepRoleCategoryFields> = yup.object({
        roleCategoryId: schema.fields.roleCategoryId
    });

    const methods = useForm<CreateFormStepRoleCategoryFields>({
        resolver: yupResolver(validationSchema),
        defaultValues
    });

    return (
        <Controller
            name="roleCategoryId"
            control={methods.control}
            render={({ field: { onChange, value } }) => (
                <FormSearchDropdown
                    value={value}
                    label="Role category"
                    loadOptions={debouncedLoadCategoryOptions}
                    onChange={onChange}
                    defaultOptions={allCategoryOptions}
                    required={true}
                    error={methods.formState.errors.roleCategoryId}
                />
            )}
        />
    );
};

export default RoleCategorySearchDropdown;