import useSWR, { KeyedMutator } from "swr";

import authenticatedGigApiFetcher from "./fetching";
import ApiError, { isApiError } from "./apiError";
import { useAcquireAccessToken } from "../../auth/useAccessToken";
import { useNavigate } from "react-router-dom";
import { fourOFourPath } from "../../Routes";

export type UseGiggedApiSWRReturn<T> = {
    data?: T,
    isLoading: boolean
    isValidating: boolean
    error?: ApiError
    refetch: () => void
    mutate: KeyedMutator<T>
}

export type UseGiggedApiSWROptions = {
    disableAutoReValidation?: boolean,
    preventRedirectOn404?: boolean
}

export const useGiggedApiSWR = <T,>(url: string | null, options?: UseGiggedApiSWROptions): UseGiggedApiSWRReturn<T> => {
    const { acquireAccessToken } = useAcquireAccessToken();
    const navigate = useNavigate();

    const fetcher = async (url: string) => {
        try {
            const accessToken = await acquireAccessToken();
            return await authenticatedGigApiFetcher<unknown, T>({url, method: "GET", body: undefined, token: accessToken});
        }
        catch (error) {
            if (isApiError(error) && error.statusCode === 404 && !options?.preventRedirectOn404) {
                navigate(fourOFourPath);
            }

            console.error(`Unhandled error making request to ${url}:\n`, error);
            throw error;
        }
    };

    const swrOptions = options?.disableAutoReValidation ?
        {
            revalidateIfStale: false,
            revalidateOnFocus: false,
            revalidateOnReconnect: false
        } :
        undefined;

    const { data, error, mutate, isValidating } = useSWR<T, ApiError>(
        url !== null ? url : null,
        fetcher,
        swrOptions);

    return {
        data,
        isLoading: !error && !data,
        isValidating,
        error,
        refetch: () => mutate(),
        mutate
    };
};