import { useMemo } from "react";
import { useSelector } from "react-redux";
import { InboxContainer } from "../../components/Conversations";
import PageContent from "../../components/PageContent";
import { AppState } from "../../store";
import ViewChatArchiveButton from "./ViewChatArchiveButton";

const InboxPage = () => {
    const conversations = useSelector((state: AppState) => state.conversations);
    const sid = useSelector((state: AppState) => state.sid);

    const openedConversation = useMemo(
        () => conversations.find((conversation) => conversation.sid === sid),
        [sid, conversations]
    );

    return (
        <PageContent>    
            <ViewChatArchiveButton />
            <InboxContainer openedConversation={openedConversation} conversations={conversations} />
        </PageContent>
    );
};

export default InboxPage;