import { useForm } from "react-hook-form";
import * as yup from "yup";
import defaultIfUndefined from "../../utils/defaultIfUndefined";
import { roleAssignmentTypes, roleVisibilityTypes } from "../../models/app/role";

export const buildRoleFormValidationSchemaFields = () => {

    return yup.object({
        title: yup.string().required("Role title is required."),
        description: yup.string().required("Role description is required."),
        roleCategoryId: yup.string().required("Role category is required."),
        skills: yup
            .array()
            .min(3, "You must select at least 3 skills")
            .of(
                yup.object().shape({
                    id: yup.string(),
                    name: yup.string(),
                }),
            ),
        roleAssignmentTypeId: yup.number().required().min(0, "Role assignment type is required."),
        roleVisibilityTypeId: yup.number().required().min(0, "Role visibility is required."),
    });
};

export type RoleFormValues = {
    title: string
    description: string
    roleCategoryId: string
    skills: { id: string, name: string }[],
    roleStatusId: number
    roleVisibilityTypeId: number
    roleAssignmentTypeId: number
};

export const useRoleForm = (
    defaultValues?: RoleFormValues,
) => {
    return useForm<RoleFormValues>({
        defaultValues: {
            title: defaultValues?.title || "",
            description: defaultValues?.description || "",
            roleCategoryId: defaultValues?.roleCategoryId || "",
            skills: defaultValues?.skills || [],
            roleStatusId: defaultValues?.roleStatusId || 1,
            roleAssignmentTypeId: defaultIfUndefined(defaultValues?.roleAssignmentTypeId, -1),
            roleVisibilityTypeId: defaultValues?.roleAssignmentTypeId === roleAssignmentTypes.assignment
                ? roleVisibilityTypes.organisation
                : defaultIfUndefined(defaultValues?.roleVisibilityTypeId, -1),
        },
    });
};