import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import Button from "../Button";
import FormTextAreaInput from "../FormTextAreaInput";
import { RoleFormValues, buildRoleFormValidationSchemaFields } from "../RoleForm";
import { formClassNames } from "../CreateGigForm/classNames";
import CreateFormPageLayout from "../CreateGigForm/CreateGigFormPageLayout";

export type CreateFormStepDescriptionFields = Pick<RoleFormValues, "description">;

export type CreateRoleFormStepDescriptionProps = {
    onSubmit: (value: CreateFormStepDescriptionFields) => void
    onBackClick: (value: CreateFormStepDescriptionFields) => void
    defaultValues: CreateFormStepDescriptionFields
}

const CreateRoleFormStepDescription = ({
    onSubmit,
    onBackClick,
    defaultValues,
}: CreateRoleFormStepDescriptionProps) => {
    const schema = buildRoleFormValidationSchemaFields();

    const validationSchema: yup.SchemaOf<CreateFormStepDescriptionFields> = yup.object({
        description: schema.fields.description
    });

    const methods = useForm<CreateFormStepDescriptionFields>({
        resolver: yupResolver(validationSchema),
        defaultValues
    });

    const handleBackClick = () => {
        onBackClick(methods.getValues());
    };

    return (
        <>
            <form
                className={formClassNames}
                onSubmit={methods.handleSubmit(onSubmit)}
                noValidate
            >
                <CreateFormPageLayout
                    title="Overview of role"
                    description="Please give a detailed overview of the role, including key responsibilities and requirements."
                    content={
                        <div className="mb-6">
                            <FormTextAreaInput
                                required
                                id="create-gig-form-description"
                                label="Role description"
                                placeholder="Enter role description"
                                register={methods.register("description")}
                                error={methods.formState.errors.description}
                                defaultValue={defaultValues.description}
                                rows={7}
                                isResizeable
                                className="mb-2"
                            />
                        </div>
                    }
                    iconName="ClipboardText"
                    buttons={
                        <>
                            <Button onClick={handleBackClick} variant="secondary" type="button">Back</Button>
                            <Button type="submit">Next</Button>
                        </>
                    }
                />
            </form>
        </>
    );
};

export default CreateRoleFormStepDescription;