import { yupResolver } from "@hookform/resolvers/yup";
import clsx from "clsx";
import { useState } from "react";
import * as yup from "yup";
import ApiError from "../../api/common/apiError";
import { GigApiFetcherResponse } from "../../api/common/fetching";
import Button from "../Button";
import { formClassNames } from "../CreateGigForm/classNames";
import GeneralFormError from "../GeneralFormError";
import { ApplicationFormValues, validationSchemaFields } from "../ApplicationForm/ApplicationFormValues";
import { CreateApplicationDto } from "../../models/app/application";
import { useForm } from "react-hook-form";
import { ApplicationFormFields } from "../ApplicationForm";

const validationSchema = yup.object(validationSchemaFields);

export type CreateApplicationFormProps = {
    onSaveAsDraft: (value: CreateApplicationDto) => Promise<GigApiFetcherResponse<unknown>>
    onSaveAndSubmit: (value: CreateApplicationDto) => Promise<GigApiFetcherResponse<unknown>>
    submitButtonText: string
    roleId: string
    isSubmitting: boolean
}

const CreateApplicationForm = ({
    submitButtonText,
    onSaveAndSubmit,
    onSaveAsDraft,
    roleId,
    isSubmitting
}: CreateApplicationFormProps) => {

    const [submissionError, setSubmissionError] = useState<ApiError | undefined>(undefined);

    const methods = useForm<ApplicationFormValues>({
        resolver: yupResolver(validationSchema)
    });

    const handleSaveAsDraft = async (data: ApplicationFormValues) => {
        const response = await onSaveAsDraft({
            ...data,
            roleId: roleId
        });

        if (!response.success) setSubmissionError(response.error);
    };

    const handleSaveAndSubmit = async (data: ApplicationFormValues) => {
        const response = await onSaveAndSubmit({
            ...data,
            roleId: roleId
        });    

        if (!response.success) setSubmissionError(response.error);
    };

    return (
        <form className={clsx(formClassNames, "p-8 space-y-8")} noValidate onSubmit={methods.handleSubmit(handleSaveAndSubmit)}>
            
            <ApplicationFormFields
                methods={methods}
            />

            <GeneralFormError error={submissionError} className="ml-8" />

            <div className="flex flex-col space-y-4 justify-end md:flex-row-reverse md:w-fit md:space-y-0 md:ml-auto">
                <Button loading={isSubmitting} type="submit">{submitButtonText}</Button>
                <Button type="button" loading={isSubmitting} className="w-full md:w-auto md:mr-4" variant="secondary" onClick={methods.handleSubmit(handleSaveAsDraft)}>Save as draft</Button>
            </div>
        </form>
    );
};

export default CreateApplicationForm;