import pluralizeString from "./utils/pluralizeString";

export const talentProfileEditTooltips = {
    careerHistory: (gigTerminologyPlural: string) => `Add any relevant jobs or projects you've previously worked on. Remember to keep this section updated as you complete more ${gigTerminologyPlural.toLowerCase()}.`,
    roleTitle: (giggedClientTerminologyPlural: string) => `Add a title to tell our ${giggedClientTerminologyPlural.toLowerCase()} what you do. Keep it short and snappy.`,
    aboutMe: "Use this section to give a brief overview of your skills, experience, and current availability.",
    industrialExperience: (giggedClientTerminologyPlural: string) => `Some of our ${giggedClientTerminologyPlural.toLowerCase()} will be looking for specific industry experience. Add yours here to make sure you stand out from the crowd.`,
    addSkills: (gigTerminology: string) => `Make sure to add all the skills you have relevant to your experience. This will increase your chances of being matched to a ${gigTerminology.toLowerCase()} by our super-smart algorithm!`,
    addSpecialization: (giggedClientTerminologyPlural: string) => `Select one role specialisation that best describes the type of work you do. This helps us match you with ${giggedClientTerminologyPlural.toLowerCase()} who need your unique expertise. Email us at 'hello@gigged.ai' to request an addition to our specialisations list if yours isn't currently listed.`,
    receiveNewGigNotifications: (gigTerminology: string) => `Check this if you want to receive notifications whenever a new ${gigTerminology.toLowerCase()} is posted that matches your skillset.`,
};

export const clientProfileEditTooltips = {
    displayNameItm: (giggedClientTerminology: string) => `The name of this ${giggedClientTerminology.toLowerCase()} and how it will appear to other users on the platform.`,
    companyCostCode: "Your cost code should represent a unique identifier for tracking costs.",
    clientOverview: (giggedClientTerminology: string) => `This should be an overview of what this ${giggedClientTerminology.toLowerCase()} does and its role as part of the overall organisation.`,
    profileImage: (giggedClientTerminology: string) => `Add or update the profile image that represents your ${giggedClientTerminology.toLowerCase()}.`,
};

export const clientEditGigTooltips = {
    gigName: (gigTerminology: string) => `Use a catchy title - this will help your ${gigTerminology.toLowerCase()} stand out to the right freelancer.`,
    gigStage: (gigTerminology: string) => `Let us know where you are currently at with your ${gigTerminology.toLowerCase()} - has there been any work done on it previously or are you starting from scratch?`,
    gigDuration: (gigTerminology: string) => `Estimate how long your ${gigTerminology.toLowerCase()} will take.`,
    gigSkills: (gigTerminology: string, talentTerminologyPlural: string) => `Make sure to add the top three skills that are required for this ${gigTerminology.toLowerCase()} - this will ensure our matching algorithm matches you with the best ${talentTerminologyPlural.toLowerCase()}.`,
    gigStartDate: (gigTerminology: string) => `Tell us when you need your ${gigTerminology.toLowerCase()} to begin.`,
    gigDeadline: (gigTerminology: string) => `Add a deadline if you have a set date the ${gigTerminology.toLowerCase()} should be completed.`,
    gigDescription: (gigTerminology: string) => `Provide a detailed description of your ${gigTerminology.toLowerCase()}. We recommend including as much information as possible.`,
    gigFiles: (gigTerminology: string) => `Upload any additional files relevant to the ${gigTerminology.toLowerCase()} you are posting for example a job description`,
    complianceCheck: (talentTerminology: string) => `Ensure ${pluralizeString(talentTerminology.toLowerCase())} have undergone a compliance check through our partners at Amiqus before hiring.`,
    gigFilesPublic: (gigTerminology: string, talentTerminology: string) => `If enabled, all attached ${gigTerminology.toLowerCase()} files will be publicly available for ${pluralizeString(talentTerminology.toLowerCase())} to view/download on your ${gigTerminology.toLowerCase()} detail page.`,
    remoteWork: (gigTerminology: string) => `Select whether or not this ${gigTerminology.toLowerCase()} can be completed remotely.`
};

export const talentProposalTooltips = {
    milestones: (gigTerminology: string, giggedClientTerminiology: string) =>  `Turning milestones on will allow you to propose splitting the ${gigTerminology.toLowerCase()} up into smaller, more measurable pieces of work which are paid on completion, provided the ${giggedClientTerminiology.toLowerCase()} is happy with the work.`,
    duration: (gigTerminology: string) => `The number of days you propose it will take you to complete the whole ${gigTerminology.toLowerCase()}.`,
    introduction: (giggedClientTerminology: string) => `Use this space to give a quick overview as to who you are. This doesn't have to be super formal - it's a chance to give the ${giggedClientTerminology.toLowerCase()} an introduction to not only your skills, but also how you like to work, when you're available, and what your favoured method of communication is.`,
    experience: (giggedClientTerminology: string, gigTerminology: string, gigTerminologyPlural: string) => `Use this space to demonstrate why you're the ideal candidate for the ${gigTerminology.toLowerCase()} by highlighting your experience, including any similar ${gigTerminologyPlural.toLowerCase()} you've worked on and relevant industry expertise. If the ${giggedClientTerminology.toLowerCase()} requires particular tech skills, make sure to specify where and when you've used them.`,
    portfolio: "Show off your work - make yourself stand out from the crowd by adding a link to your portfolio if you have one.",
    relevantFiles: (giggedClientTerminology: string) => `Upload any files here that might help make you stand out to the ${giggedClientTerminology.toLowerCase()} e.g. portfolio examples, previous work or anything else relevant to your proposal.`,
    milestoneDescription: (gigTerminology: string) => `Describe this milestone e.g. for a UX ${gigTerminology.toLowerCase()}, this could be 'Compile user research reports, personas and user flows', or for a web development ${gigTerminology.toLowerCase()}, something like 'Create website designs and wireframes'.`,
};

export const clientMilestoneTooltips = {
    milestones: "To get work started on a milestone, click 'Pay now'. Once you are satisfied with the output, you must mark it as complete to unlock the next milestone."
};