import { useMsal } from "@azure/msal-react";
import { useGiggedApiSWR } from "../api";
import { UserGetMeDto } from "../models/app/userMe";

export type UseCurrentUserReturn = {
    userGivenName: string
    userFamilyName: string
    email: string
    userRole: "client" | "talent" | undefined
    talentId?: string
    clientId?: string
    clientName?: string
    idpUserId: string
}

const useCurrentUser = (): UseCurrentUserReturn => {
    const { accounts } = useMsal();
    const idTokenClaims = accounts.length > 0 ? accounts[0].idTokenClaims : undefined;

    const url = "api/me";

    const getMeCall = useGiggedApiSWR<UserGetMeDto>(url, { disableAutoReValidation: true });

    const talentAccountStatus = idTokenClaims?.extension_talentAccountStatus;
    const clientAccountStatus = idTokenClaims?.extension_giggedClientAccountStatus;
    const selectedAccountType = idTokenClaims?.extension_selectedAccountType;

    const isTalent = 
        !!talentAccountStatus && talentAccountStatus !== "None" || 
        selectedAccountType === "talent";

    const isClient = 
        !!clientAccountStatus && clientAccountStatus !== "None" ||
        selectedAccountType === "giggedClient";

    return {
        userGivenName: idTokenClaims?.given_name as string || "",
        userFamilyName: idTokenClaims?.family_name as string || "",
        email: idTokenClaims?.email as string || "",
        userRole: isTalent ? "talent" :
            isClient ? "client" : undefined,
        talentId: getMeCall.data?.talentId,
        clientId: getMeCall.data?.giggedClientId,
        clientName: getMeCall.data?.giggedClientName,
        idpUserId: idTokenClaims?.sub || ""
    };
};

export default useCurrentUser;