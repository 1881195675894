import { useForm } from "react-hook-form";
import * as yup from "yup";
import { todayAtMidnight } from "../../utils/dateFormatters";
import defaultIfUndefined from "../../utils/defaultIfUndefined";

export const buildGigFormValidationSchemaFields = ({
    title: yup.string().required("Name is required."),
    expectedDurationId: yup.number().required().min(0, "Duration is required."),
    startDate: yup.date()
        .required("Start date is required.")
        .min(todayAtMidnight(), "Start date cannot be in the past.")
        .nullable()
        .typeError("Start date must be a valid date."),
    deadlineDate: yup.date().test("deadline-greater-than-start", (value, context) => {
        if (!value) return true;

        if (value <= context.parent.startDate) {
            return context.createError({ message: "Deadline must be after start." });
        }

        return true;
    }).nullable().optional().typeError("Deadline must be a valid date."),
    description: yup.string().required("Description is required."),
    initialStageId: yup.number().required().min(0, "Current stage is required."),
    skills: yup
        .array()
        .length(3, "You must select 3 skills")
        .of(
            yup.object().shape({
                id: yup.string(),
                name: yup.string(),
            }),
        ),
    isCompletedRemotely: yup.string().required("This field is required.")
});

export type GigFormValues = {
    title: string,
    expectedDurationId: number,
    startDate: Date | null,
    deadlineDate: Date | null,
    description: string,
    initialStageId: number,
    gigStatusId: number,
    skills: { id: string, name: string }[],
    isComplianceCheckRequired: boolean
    isGigFilesPublic: boolean
    isCompletedRemotely: string
};

export const useGigForm = (
    defaultValues?: GigFormValues,    
) => {
    return useForm<GigFormValues>({
        defaultValues: {
            title: defaultValues?.title || "",
            expectedDurationId: defaultIfUndefined(defaultValues?.expectedDurationId, -1),
            startDate: defaultValues?.startDate || null,
            deadlineDate: defaultValues?.deadlineDate || null,
            description: defaultValues?.description || "",
            initialStageId: defaultIfUndefined(defaultValues?.initialStageId, -1),
            gigStatusId: defaultValues?.gigStatusId || 1,
            skills: defaultValues?.skills || [],
            isComplianceCheckRequired: defaultValues?.isComplianceCheckRequired || false,
            isGigFilesPublic: defaultValues?.isGigFilesPublic || false,
            isCompletedRemotely: defaultValues?.isCompletedRemotely ? defaultValues?.isCompletedRemotely : ""
        },
    });
};