import { Rating } from "@mui/material";
import { Star, UsersThree } from "phosphor-react";
import { useOrganizationContext } from "../../api/current-organization/organizationContext";
import { useTalent } from "../../api/talent";
import useCurrentUser from "../../auth/useCurrentUser";
import InfoBox from "../../components/InfoBox";
import ProfileImage from "../../components/ProfileImage";
import ReportButton from "../../components/Reports";
import SanitizeHtml from "../../components/SanitizeHtml";
import Typography from "../../components/Typography";
import { useTalentId } from "../../Routes";
import formatDateOnlyString from "../../utils/formatDateString";
import GigCount from "./GigCount";
import InviteToGigButton from "./InviteToGigFormDialog";
import SkillsAndExperienceCard from "./SkillsAndExperienceCard";
import TalentProfileDetailsCard from "./TalentProfileDetailsCard";
import Loader from "../../components/Loader";
import { getClickableLink } from "../../utils/getClickableLink";
import Tooltip from "../../components/Tooltip";
import BadgeList from "../../components/BadgeList";
import InviteToRoleButton from "./InviteToRoleFormDialog";

const FindTalentDetailsPage = () => {
    const { userRole, talentId: currentUserTalentId } = useCurrentUser();
    const { organizationConfig, talentTerminology, giggedClientTerminology } = useOrganizationContext();
    const talentId = useTalentId();

    const canViewProfile =
        !organizationConfig?.isTalentProfilesPrivate ||
        currentUserTalentId === talentId ||
        userRole === "client";

    if (!canViewProfile) {
        return (
            <div className="flex flex-col my-auto items-center mt-8">
                <InfoBox className="w-1/2">
                    Sorry, you don't have the correct privileges to view this page.
                </InfoBox>
            </div>);
    }

    const { talent, isLoading } = useTalent(talentId);

    if (!talent || isLoading) return <Loader />;

    const name = `${talent.firstName} ${talent.lastName}`;
    const displayBadgeSection = talent?.badges.length > 0;

    return (
        <>
            <div className="pt-20 md:pt-20 pb-20 md:pb-12 bg-tertiary">
                <div className="max-w-6xl mx-auto">
                    <div className="mx-4 md:mx-8">
                        <div className="items-start text-center space-y-6 sm:flex sm:text-left sm:space-y-0">
                            <ProfileImage
                                className="inline-block mx-auto sm:block"
                                imageUrl={talent.profileImageUrl}
                                name={name}
                                size="lg"
                            />
                            <div className="self-stretch grow sm:ml-6">
                                <Typography variant="headline-small" component="h1" className="text-center sm:text-left my-2 mb-4 text-tertiary-bg-text break-words">{name}</Typography>
                                {organizationConfig.isTalentAgencyEnabled && talent?.agency && (
                                    <span className="flex mx-auto sm:mx-0 !sm:mr-auto bg-surface rounded-md px-[0.5rem] w-fit mb-4">
                                        <Tooltip
                                            tooltipText={talent.agency.description && talent.agency.description.length > 0 ? talent.agency.description :
                                                `This is the agency that the ${talentTerminology.toLowerCase()} is affiliated with.`
                                            }
                                        >
                                            {talent.agency.url ? (
                                                <a
                                                    href={talent.agency?.url ? getClickableLink(talent.agency?.url) : ""}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="w-fit flex flex-row items-center space-x-1"
                                                >
                                                    <UsersThree size={18} />
                                                    <p className="text-[0.9rem]">
                                                        <b> {talent.agency.name}</b>
                                                    </p>
                                                </a>
                                            ) : (
                                                <span className="w-fit flex flex-row items-center space-x-1">
                                                    <UsersThree size={18} />
                                                    <p className="text-[0.9rem]">
                                                        <b> {talent.agency.name}</b>
                                                    </p>
                                                </span>
                                            )}
                                        </Tooltip>
                                    </span>
                                )}
                                {displayBadgeSection &&
                                    <BadgeList badges={talent.badges} keyPrefix={talent.id.toString()} className="mb-4" />
                                }
                                {userRole === "client" && (
                                    <div className="flex flex-col md:flex-row md:space-x-2 mx-6 md:mx-auto items-center justify-center md:justify-start">
                                        <InviteToGigButton talentId={talent.id} className="mt-2 w-full md:w-48 h-12" />
                                        <InviteToRoleButton talentId={talent.id} className="mt-2 md:w-48 w-full" />
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="bg-primary h-[3px] mt-8 mb-8 sm:mb-12" />
                        <div className="block sm:flex space-x-0 space-y-8 sm:space-x-12 sm:space-y-0">
                            <div className="grid grid-cols-2 sm:flex sm:flex-col gap-4 sm:w-1/4">
                                {talent.currentGiggedClientName && (
                                    <div>
                                        <label className="text-tertiary-bg-text text-label-small whitespace-nowrap font-semibold">Current {giggedClientTerminology.toLowerCase()}</label>
                                        <p className="text-tertiary-bg-text">{talent.currentGiggedClientName}</p>
                                    </div>
                                )}
                                {talent.currentRoleTitle && (
                                    <div>
                                        <label className="text-tertiary-bg-text text-label-small whitespace-nowrap font-semibold">Current role</label>
                                        <p className="text-tertiary-bg-text">{talent.currentRoleTitle}</p>
                                    </div>
                                )}
                                {talent.location && (
                                    <div>
                                        <label className="text-tertiary-bg-text text-label-small whitespace-nowrap font-semibold">Location</label>
                                        <p className="text-tertiary-bg-text">{talent.location.name}</p>
                                    </div>
                                )}
                                <div>
                                    <label className="text-tertiary-bg-text text-label-small whitespace-nowrap font-semibold">Member since</label>
                                    <p className="text-tertiary-bg-text">{formatDateOnlyString(talent.memberSince)}</p>
                                </div>
                                <div className="flex flex-col text-tertiary-bg-text gap-1">
                                    <label className="text-label-small whitespace-nowrap font-bold">Rated</label>
                                    <Rating
                                        value={talent.ratingAverage ?? 0}
                                        icon={<Star className="text-tertiary-bg-text" weight="fill" size={16} />}
                                        emptyIcon={<Star className="text-tertiary-bg-text" size={16} />}
                                        readOnly
                                    />
                                    <p className="text-label-small">
                                        From {talent.completedGigsCount} {talent.completedGigsCount == 1 ? "rating" : "ratings"}
                                    </p>
                                </div>
                            </div>
                            <div className="space-y-6">
                                {talent.aboutMe && (
                                    <p className="text-tertiary-bg-text whitespace-pre-wrap">
                                        <SanitizeHtml html={talent.aboutMe} />
                                    </p>
                                )}
                                {!(talent.ongoingGigsCount === 0 && talent.completedGigsCount === 0 && talent.cancelledGigsCount === 0) && (
                                    <div className="flex space-x-8">
                                        <GigCount status="Ongoing" count={talent.ongoingGigsCount} />
                                        <GigCount status="Completed" count={talent.completedGigsCount} />
                                        <GigCount status="Cancelled" count={talent.cancelledGigsCount} />
                                    </div>
                                )}
                                {organizationConfig.isReportsEnabled && (
                                    <div>
                                        <ReportButton
                                            type="Talent"
                                            entityId={talentId}
                                            className="text-white hover:text-surface-accent"
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="-mt-6 max-w-6xl mx-auto">
                <div className="sm:flex sm:items-start space-y-4 sm:space-y-0 sm:space-x-6 mx-4 md:mx-8">
                    <TalentProfileDetailsCard className="sm:w-1/2 grow" talent={talent} />
                    <SkillsAndExperienceCard className="sm:min-w-[355px]" talent={talent} />
                </div>
            </div>
        </>
    );
};

export default FindTalentDetailsPage;
