import { Link } from "react-router-dom";
import { useAppPaths } from "../Routes";
import { editableProposalStatuses, ProposalSummary } from "../models/app/proposal";
import Card from "./Card";
import LinkButton from "./LinkButton";
import ProposalStatusChip from "./ProposalStatusChip";
import Typography from "./Typography";
import { useOrganizationContext } from "../api/current-organization/organizationContext";
import ProposalCoverLetterButton from "./ProposalCoverLetterButton";

export type ProposalsSummaryCardProps = {
    proposal: ProposalSummary
}
const ProposalsSummaryCard = ({
    proposal
}: ProposalsSummaryCardProps) => {
    const appPaths = useAppPaths();
    const isGigOnHold = proposal.gigStatusId === 0;
    const { gigTerminology, giggedClientTerminology } = useOrganizationContext();

    return (
        <Card key={proposal.id} className="space-y-6 md:space-y-0 flex flex-col md:flex-row items-center md:justify-between">
            <div className="flex flex-col space-y-4">
                <div className="space-y-2">
                    <Link to={appPaths.gigs.findAGigDetails(proposal.gigId)}>
                        <Typography className="text-center md:text-left font-medium" variant="title-medium" component="p">{proposal.gigTitle}</Typography>
                    </Link>
                    <Typography className="text-center md:text-left" variant="title-small" component="p">{proposal.giggedClientName}</Typography>
                </div>
                <ProposalStatusChip onHold={isGigOnHold} proposalStatusId={proposal.proposalStatusId} />
            </div>
            <div className="flex flex-col md:flex-row-reverse md:space-x-4 md:space-x-reverse space-y-4 md:space-y-0 items-center">
                <ProposalCoverLetterButton proposal={proposal} />
                {editableProposalStatuses.includes(proposal.proposalStatusId) && (
                    <LinkButton disabled={isGigOnHold} className="md:ml-auto" to={appPaths.proposals.edit(proposal.id)} variant="tertiary">{isGigOnHold ? `${gigTerminology} placed on hold by ${giggedClientTerminology.toLowerCase()}` : "Edit proposal"}</LinkButton>
                )}
            </div>
        </Card>
    );
};

export default ProposalsSummaryCard;
