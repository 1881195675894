import clsx from "clsx";
import { Briefcase, Eye, FolderOpen } from "phosphor-react";
import { Link } from "react-router-dom";
import { useClientDashboard } from "../../api/clientDashboard";
import { useOrganizationContext } from "../../api/current-organization/organizationContext";
import Card from "../../components/Card";
import DashboardTable from "../../components/Dashboard/DashboardTable";
import LargeDashboardButton from "../../components/Dashboard/LargeDashboardButton";
import SmallDashboardButton from "../../components/Dashboard/SmallDashboardButton";
import PageContent from "../../components/PageContent";
import Typography from "../../components/Typography";
import { useAppPaths } from "../../Routes";
import Loader from "../../components/Loader";

const ClientDashboardPage = () => {
    const appPaths = useAppPaths();
    const { dashboardData, isLoading } = useClientDashboard();
    const { gigTerminology, gigTerminologyPlural, talentTerminology } = useOrganizationContext();

    if (isLoading || !dashboardData) {
        return (
            <Loader />
        );
    }

    return (
        <PageContent>
            <Card className="space-y-6">
                <div className="space-y-6 md:space-y-0 md:flex md:space-x-6 md:child:grow">
                    <LargeDashboardButton
                        variant="dark"
                        Icon={Briefcase}
                        name="Post a role"
                        href={appPaths.roles.createIntro}
                    />
                    <LargeDashboardButton
                        variant="dark"
                        Icon={Briefcase}
                        name={`Post a ${gigTerminology.toLowerCase()}`}
                        href={appPaths.gigs.createIntro}
                    />
                </div>
                <div className="space-y-6 md:space-y-0 md:flex md:space-x-6 md:child:grow">
                    <LargeDashboardButton
                        variant="light"
                        Icon={FolderOpen}
                        name={<h1>View role <br />applications</h1>}
                        href={appPaths.roles.open}
                    />
                    <LargeDashboardButton
                        variant="light"
                        Icon={FolderOpen}
                        name={<h1>View {gigTerminology.toLowerCase()} <br />proposals</h1>}
                        href={appPaths.gigs.indexPosted}
                    />
                </div>
                <div className=" space-y-6 md:space-y-0 md:flex md:space-x-6 md:child:grow">
                    <SmallDashboardButton
                        Icon={Briefcase}
                        name={`Posted ${gigTerminologyPlural}`}
                        count={dashboardData.postedGigsCount}
                        href={appPaths.gigs.indexPosted}
                    />
                    <SmallDashboardButton
                        Icon={Briefcase}
                        name={`Ongoing ${gigTerminologyPlural}`}
                        count={dashboardData.ongoingGigsCount}
                        href={appPaths.gigs.ongoing}
                    />
                    <SmallDashboardButton
                        Icon={Briefcase}
                        name={`Completed ${gigTerminologyPlural}`}
                        count={dashboardData.completedGigsCount}
                        href={appPaths.gigs.completed}
                    />
                    <SmallDashboardButton
                        Icon={Briefcase}
                        name={`Draft ${gigTerminologyPlural}`}
                        count={dashboardData.draftGigsCount}
                        href={appPaths.gigs.indexDraft}
                    />
                </div>
                <Card className={clsx("!bg-white", dashboardData.ongoingGigs.length === 0 && "flex flex-col min-h-[360px]")}>
                    <Typography variant="headline-small" component="h2" className="mb-6 flex-none">Ongoing {gigTerminologyPlural}</Typography>
                    {dashboardData.ongoingGigs.length === 0 ? (
                        <div className="flex-1 flex justify-center items-center">
                            <div className="flex flex-col items-center">
                                <img
                                    src="/gigs-empty-state-icon.svg"
                                    alt="No ongoing gigs icon"
                                />
                                <p className="mt-2 opacity-60">No ongoing gigs</p>
                            </div>
                        </div>
                    ) : (
                        <DashboardTable
                            data={dashboardData?.ongoingGigs}
                            headings={[
                                `${gigTerminology} title`,
                                `Hired ${talentTerminology.toLowerCase()}`,
                                "Expected duration",
                                "",
                            ]}
                            renderRowCells={(gig) => (
                                <>
                                    <td>{gig.title}</td>
                                    <td>{`${gig.hiredTalentFirstName} ${gig.hiredTalentLastName}`}</td>
                                    <td>{gig.expectedDurationLabel}</td>
                                    <td>
                                        <Link to={appPaths.gigs.details(gig.id)}>
                                            <Eye size={20} />
                                        </Link>
                                    </td>
                                </>
                            )}
                        />
                    )}
                </Card>
            </Card>
        </PageContent>
    );
};

export default ClientDashboardPage;