import clsx from "clsx";
import { useNavigate } from "react-router-dom";

import Button from "../Button";
import ApiError from "../../api/common/apiError";
import Typography from "../Typography";
import DialogBox, { useDialogBoxState } from "../DialogBox";
import { useAppPaths } from "../../Routes";
import GeneralFormError from "../GeneralFormError";
import { RoleFormFields, RoleFormValues, useRoleForm } from "../RoleForm";
import { formClassNames } from "../CreateGigForm/classNames";

export type CreateRoleFormStepSummaryProps = {
    onSaveAndPost: (value: RoleFormValues) => void
    onSaveAsDraft: (values: RoleFormValues) => void
    defaultValues: RoleFormValues
    isSubmitting: boolean
    submissionError?: ApiError
}

const CreateRoleFormStepSummary = ({
    onSaveAndPost,
    onSaveAsDraft,
    defaultValues,
    isSubmitting,
    submissionError,
}: CreateRoleFormStepSummaryProps) => {
    const appPaths = useAppPaths();
    const confirmCancelDialogState = useDialogBoxState();
    const navigate = useNavigate();

    const methods = useRoleForm(defaultValues);

    const handleSaveAsDraft = methods.handleSubmit(onSaveAsDraft);

    const handleDiscardChanges = () => {
        navigate(appPaths.dashboard);
    };

    return (
        <>
            <form
                className={clsx(formClassNames, "p-8")}
                onSubmit={methods.handleSubmit(onSaveAndPost)}
                noValidate
            >
                <Typography variant="display-medium" component="h1" gutterBottom>Review the role</Typography>
                <RoleFormFields
                    methods={methods}
                    initialValues={defaultValues}
                />
                <GeneralFormError error={submissionError} className="ml-8" />
                <div className={clsx(
                    "mb-0 mx-0 mt-8 space-y-4",
                    "md:flex md:flex-row-reverse md:justify-end md:space-y-0"
                )}>
                    <Button type="submit" loading={isSubmitting} className="w-full md:w-auto">Post role</Button>
                    <Button type="button" loading={isSubmitting} className="w-full md:w-auto md:mr-4" variant="secondary" onClick={handleSaveAsDraft}>Save as draft</Button>
                    <Button type="button" disabled={isSubmitting} className="w-full md:w-auto md:mr-auto" variant="tertiary" onClick={confirmCancelDialogState.open}>Cancel</Button>
                </div>
            </form>
            <DialogBox
                {...confirmCancelDialogState}
                title={`Discard changes to "${methods.watch("title")}"?`}
                maxWidth="400px"
            >
                <div className="space-y-4">
                    <Button className="block mx-auto w-full" onClick={handleDiscardChanges}>Discard changes</Button>
                    <Button className="block mx-auto w-full" variant="tertiary" onClick={confirmCancelDialogState.close}>Back</Button>
                </div>
            </DialogBox>
        </>
    );
};

export default CreateRoleFormStepSummary;