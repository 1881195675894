import { PencilSimple, Lightbulb, Envelope, MagnifyingGlass, Note } from "phosphor-react";
import { Link } from "react-router-dom";
import { useTalentDashboard } from "../../api/talentDashboard";
import Card from "../../components/Card";
import SmallDashboardButton from "../../components/Dashboard/SmallDashboardButton";
import PageContent from "../../components/PageContent";
import Typography from "../../components/Typography";
import { useAppPaths } from "../../Routes";
import Loader from "../../components/Loader";
import ProgressIndicator from "../../components/ProgressIndictator";

const TalentDashboardPage = () => {
    const appPaths = useAppPaths();
    const {
        dashboardData,
        isLoading,
    } = useTalentDashboard();

    if (isLoading || !dashboardData) {
        return <Loader />;
    }

    const isProfileComplete = dashboardData.profileHealthScore === 100;

    return (
        <PageContent>
            <Card className="space-y-6 min-h-[70vh]">
                <div className="space-y-6 md:space-y-0 md:flex md:space-x-6 md:child:grow">
                    <Card className="bg-white flex flex-row justify-between items-center md:max-w-[33%]">
                        <div className="space-y-4 w-64">
                            <Typography component="h2" variant="title-large">Hi, {dashboardData.firstName}!</Typography>
                            <div className="flex flex-col space-y-2">
                                <div>
                                    <Link to={appPaths.profile.edit} className="flex flex-row space-x-2 items-center>">
                                        {isProfileComplete && <PencilSimple size={20} />}
                                        <div className="underline font-bold">{isProfileComplete ? "Edit my profile" : "Complete your profile"}</div>
                                    </Link>
                                </div>
                                {!isProfileComplete && <ProgressIndicator value={dashboardData.profileHealthScore} />}
                            </div>
                        </div>
                    </Card>
                </div>

                <div className=" space-y-6 md:space-y-0 md:flex md:space-x-6 md:child:grow">
                    <SmallDashboardButton
                        Icon={Lightbulb}
                        name={"View Internal Opportunities"}
                        href={appPaths.roles.findARole}
                    />
                    <SmallDashboardButton
                        Icon={Envelope}
                        name={"View Messages"}
                        href={appPaths.inbox.index}
                    />
                    <SmallDashboardButton
                        Icon={Note}
                        name={"View Applications"}
                        href={appPaths.applications.index}
                    />
                    <SmallDashboardButton
                        Icon={MagnifyingGlass}
                        name={"View My Profile"}
                        href={appPaths.talents.findTalentDetails(dashboardData.talentId)}
                    />
                </div>
            </Card >
        </PageContent >
    );
};

export default TalentDashboardPage;