import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, useFieldArray } from "react-hook-form";

import Button from "../Button";
import CreateFormPageLayout from "./CreateGigFormPageLayout";
import { GigFormValues, buildGigFormValidationSchemaFields } from "../GigForm";
import { formClassNames } from "./classNames";
import SkillsInput from "../SkillsInput";
import { useOrganizationContext } from "../../api/current-organization/organizationContext";

export type CreateGigFormStep7SkillsFields = Pick<GigFormValues, "skills">;

export type CreateGigFormStep7SkillsProps = {
    onSubmit: (value: CreateGigFormStep7SkillsFields) => void
    onBackClick: (value: CreateGigFormStep7SkillsFields) => void
    defaultValues: CreateGigFormStep7SkillsFields
}

const CreateGigFormStep7Skills = ({
    onSubmit,
    onBackClick,
    defaultValues,
}: CreateGigFormStep7SkillsProps) => {
    const { gigTerminology, talentTerminology } = useOrganizationContext();

    const validationSchema = yup.object({
        skills: buildGigFormValidationSchemaFields.skills,
    });

    const methods = useForm<CreateGigFormStep7SkillsFields>({
        mode: "onSubmit",
        reValidateMode: "onSubmit",
        resolver: yupResolver(validationSchema),
        defaultValues,
    });    

    const { append, remove } = useFieldArray<CreateGigFormStep7SkillsFields, "skills">({
        control: methods.control,
        name: "skills",
        keyName: "id",
    });

    const { skills } = methods.watch();

    const handleBackClick = () => {
        onBackClick(methods.getValues());
    };

    return (
        <form
            className={formClassNames}
            onSubmit={methods.handleSubmit(onSubmit)}
            noValidate
        >
            <CreateFormPageLayout
                title="Make sure to add the top three skills you need."
                description={`This will ensure our matching algorithm will match you with the best ${talentTerminology.toLowerCase()} for your ${gigTerminology.toLowerCase()}.`}
                content={
                    <SkillsInput
                        addSkill={append}
                        removeSkill={remove}
                        skills={skills}
                        error={methods.formState.errors.skills}
                        showError={methods.formState.submitCount > 0}
                    />
                }
                iconName="Path"
                buttons={
                    <>
                        <Button variant="secondary" onClick={handleBackClick} type="button">Back</Button>
                        <Button type="submit">Next</Button>
                    </>
                }
            />
        </form>
    );
};

export default CreateGigFormStep7Skills;