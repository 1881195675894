import { useOrganizationContext } from "../api/current-organization/organizationContext";
import { useProposalFiles } from "../api/proposalFiles";
import { ProposalSummary } from "../models/app/proposal";
import Button from "./Button";
import DialogBox, { useDialogBoxState } from "./DialogBox";
import FilePreview from "./FilePreview";

export type ProposalFilesButtonProps = {
    proposal: ProposalSummary
    className?: string
}

const ProposalFilesButton = ({
    proposal,
    className,
}: ProposalFilesButtonProps) => {
    const dialogState = useDialogBoxState();
    const { talentTerminology, gigTerminology } = useOrganizationContext();
    const { proposalFiles, isLoading } = useProposalFiles(proposal.id);

    return (
        <span className={className}>
            <Button variant="secondary" onClick={dialogState.open}>Files</Button>
            <DialogBox {...dialogState} title="Relevant proposal files">
                <div className="space-y-6 mt-6 text-left">                    
                    <div className="flex flex-col gap-2">
                        {isLoading && (<p>Loading...</p>)}
                        {!isLoading && proposalFiles.length === 0 ? (<p>The {talentTerminology.toLowerCase()} has not provided any files.</p>) : (
                            <>
                                <p>Here are some files that the {talentTerminology.toLowerCase()} feels is relevant to your {gigTerminology.toLowerCase()}.</p>
                                {
                                    proposalFiles.map(proposalFile => (
                                        <FilePreview 
                                            url={proposalFile.url} 
                                            name={proposalFile.name}
                                            totalBytes={proposalFile.totalBytes}
                                        />
                                    ))
                                }
                            </>
                        )}
                    </div>
                </div>
            </DialogBox>
        </span>
    );
};

export default ProposalFilesButton;
