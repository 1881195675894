import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

export type InviteFormDialogValues = {
    message: string
};

export const inviteFormValidationSchemaFields = {
    message: yup.string().required("Message is required."),
};

const schema = yup.object(inviteFormValidationSchemaFields);

export const useInviteForm = () => {
    return useForm<InviteFormDialogValues>({
        resolver: yupResolver(schema)
    });
};