import clsx from "clsx";
import { GithubLogo } from "phosphor-react";
import Card from "../../components/Card";
import Typography from "../../components/Typography";
import { ApiClientProfileDto } from "../../models/api/clientProfile";
import { getClickableLink } from "../../utils/getClickableLink";

export type ClientProfileDetailsCardProps = {
    clientProfile: ApiClientProfileDto
    className?: string
}

const ClientProfileDetailsCard = ({
    clientProfile,
    className,
}: ClientProfileDetailsCardProps) => {

    return (
        <Card className={clsx("space-y-8 max-w-full md:max-w-[25%]", className)}>

            <div>
                <Typography variant="title-large" component="h2" underline className="mb-6">Links</Typography>
                {!clientProfile.addedExtras.gitHubUrl ? (
                    <p>None added yet.</p>
                ) : (
                    <div className="flex flex-row gap-6">
                        {clientProfile.addedExtras.gitHubUrl && <a target="_blank" href={getClickableLink(clientProfile.addedExtras.gitHubUrl)}><GithubLogo size={32} className="text-primary-text" weight="fill" /></a>}
                    </div>
                )}
            </div>
        </Card>
    );
};

export default ClientProfileDetailsCard;
