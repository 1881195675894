import { useEffect } from "react";
import { useFieldArray, UseFormReturn } from "react-hook-form";
import { CreateProposalFormValues } from ".";
import { useOrganizationContext } from "../../api/current-organization/organizationContext";
import { talentProposalTooltips } from "../../tooltipsContent";
import Button from "../Button";
import FormTextInput from "../FormTextInput";

type CreateProposalFormMilestonesProps = {
    methods: UseFormReturn<CreateProposalFormValues, object>
}

const CreateProposalFormMilestones: React.FC<CreateProposalFormMilestonesProps> = ({
    methods
}) => {
    const { gigTerminology } = useOrganizationContext();

    const { fields, append, remove } = useFieldArray({
        control: methods.control,
        name: "milestones"
    });

    const handleAddMilestone = () => {
        append({
            description: "",
        });
    };

    const handleRemoveMilestone = (index: number) => {
        remove(index);
    };

    useEffect(() => {
        const existingMilestones = methods.getValues("milestones");
        if(!existingMilestones || existingMilestones.length === 0) {
            append({
                description: "",
            });
        }
    }, []);
    
    return (
        <>
            {fields.map((field, index) =>
                <div className="flex flex-col sm:flex-row gap-4 sm:mb-4" key={`${field.id}-proposal-milestone`}>
                    <div className="flex-1">
                        <FormTextInput
                            required
                            tooltip={talentProposalTooltips.milestoneDescription(gigTerminology)}
                            label="Description"
                            placeholder="Enter milestone description"
                            type="text"
                            error={methods.formState.errors.milestones && methods.formState.errors.milestones[index]?.description}
                            {...methods.register(`milestones.${index}.description`)}
                        />
                    </div>
                    <div className="flex-none flex justify-center items-end">
                        <Button
                            type="button"
                            variant="tertiary"
                            className="-translate-y-1"
                            onClick={() => handleRemoveMilestone(index)}
                        >
                                Remove
                        </Button>
                    </div>
                </div>
            )}
            <div className="flex justify-center sm:justify-start">
                <Button
                    type="button"
                    variant="secondary"
                    onClick={handleAddMilestone}
                >
                    Add milestone
                </Button>
            </div>
        </>
    );
};

export default CreateProposalFormMilestones;