import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import Button from "../Button";
import FormTextInput from "../FormTextInput";
import LinkButton from "../LinkButton";
import { useAppPaths } from "../../Routes";
import { RoleFormValues, buildRoleFormValidationSchemaFields } from "../RoleForm";
import CreateFormPageLayout from "../CreateGigForm/CreateGigFormPageLayout";
import { formClassNames } from "../CreateGigForm/classNames";

export type CreateRoleFormStepTitleFields = Pick<RoleFormValues, "title">;

export type CreateRoleFormStepTitleProps = {
    onSubmit: (value: CreateRoleFormStepTitleFields) => void
    defaultValues: CreateRoleFormStepTitleFields
}

const CreateRoleFormStepTitle = ({
    onSubmit,
    defaultValues,
}: CreateRoleFormStepTitleProps) => {
    const appPaths = useAppPaths();
    const schema = buildRoleFormValidationSchemaFields();

    const validationSchema: yup.SchemaOf<CreateRoleFormStepTitleFields> = yup.object({
        title: schema.fields.title,
    });

    const methods = useForm<CreateRoleFormStepTitleFields>({
        mode: "onSubmit",
        resolver: yupResolver(validationSchema),
        defaultValues
    });    

    return (
        <form
            className={formClassNames}
            onSubmit={methods.handleSubmit(onSubmit)}
            noValidate
        >
            <CreateFormPageLayout
                title="Role title"
                description="Please enter the title of the role you would like to fill."
                content={
                    <FormTextInput
                        required
                        id="create-role-form-title"
                        label={"Role title"}
                        placeholder={"Enter title of the role"}
                        error={methods.formState.errors.title}
                        defaultValue={defaultValues.title}
                        {...methods.register("title")}
                    />
                }
                iconName="TextAa"
                buttons={
                    <>
                        <LinkButton to={appPaths.roles.createIntro} variant="secondary">Back</LinkButton>
                        <Button type="submit">Next</Button>
                    </>
                }
                helpText={
                    <span>
                        <p className="font-bold mb-2">Example titles:</p>
                        <ul className="list-disc ml-[1rem]">
                            <li>Front-end Developer</li>
                            <li>UI/UX Designer</li>
                            <li>Marketing Lead</li>
                        </ul>
                    </span>
                }
            />
        </form>
    );
};

export default CreateRoleFormStepTitle;