import FindPage from "../../components/FindPage";
import { fetchSkillOptions } from "../../api/skills";
import { FindPagePanelSectionProps } from "../../components/FindPage/FindPagePanelSection";
import { useFindARoleContext } from "./findARoleContext";
import SortOrderDropdown from "./SortOrderDropdown";
import RolesList from "./RolesList";
import SearchableSkillsCheckBoxGroup from "../../components/SearchableSkillsCheckBoxGroup";

const FindARoleComponent = () => {
    const {
        searchTerm,
        skillIds,
        anyActiveFilters,
        setSearchTerm,
        clearAllFilters,
        setSkillIds,
        searchNow,
        isLoading,
        roles,
    } = useFindARoleContext();

    const filterPanelSections: FindPagePanelSectionProps[] = [
        {
            title: "Skills",
            subtitle: `(${skillIds.length} selected)`,
            content: (
                <SearchableSkillsCheckBoxGroup
                    selectedSkillIds={skillIds}
                    searchPlaceholder="Search by skill"
                    onChange={setSkillIds}
                    fetchSkills={fetchSkillOptions}
                    accessTokenNotRequired
                />
            )
        },
    ];

    return (
        <FindPage
            title="Filter role by:"
            searchTerm={searchTerm}
            anyActiveFilters={anyActiveFilters}
            setSearchTerm={setSearchTerm}
            clearAllFilters={clearAllFilters}
            searchNow={searchNow}
            searchPlaceholder={"Search by role title"}
            sortOrderDropdown={(
                <SortOrderDropdown />
            )}
            filterPanelSections={filterPanelSections}
        >
            <RolesList roles={roles} isLoading={isLoading} />
        </FindPage>
    );
};

export default FindARoleComponent;
