import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, useFieldArray } from "react-hook-form";

import Button from "../Button";
import { RoleFormValues, buildRoleFormValidationSchemaFields } from "../RoleForm/RoleFormValues";
import CreateFormPageLayout from "../CreateGigForm/CreateGigFormPageLayout";
import SkillsInput from "../SkillsInput";
import { formClassNames } from "../CreateGigForm/classNames";

export type CreateRoleFormStepSkillsFields = Pick<RoleFormValues, "skills">;

export type CreateRoleFormStepSkillsProps = {
    onSubmit: (value: CreateRoleFormStepSkillsFields) => void
    onBackClick: (value: CreateRoleFormStepSkillsFields) => void
    defaultValues: CreateRoleFormStepSkillsFields
}

const CreateRoleFormStepSkills = ({
    onSubmit,
    onBackClick,
    defaultValues,
}: CreateRoleFormStepSkillsProps) => {
    const schema = buildRoleFormValidationSchemaFields();

    const validationSchema = yup.object({
        skills: schema.fields.skills,
    });

    const methods = useForm<CreateRoleFormStepSkillsFields>({
        mode: "onSubmit",
        reValidateMode: "onSubmit",
        resolver: yupResolver(validationSchema),
        defaultValues,
    });    

    const { append, remove } = useFieldArray<CreateRoleFormStepSkillsFields, "skills">({
        control: methods.control,
        name: "skills",
        keyName: "id",
    });

    const { skills } = methods.watch();

    const handleBackClick = () => {
        onBackClick(methods.getValues());
    };

    return (
        <form
            className={formClassNames}
            onSubmit={methods.handleSubmit(onSubmit)}
            noValidate
        >
            <CreateFormPageLayout
                title="What skills are required?"
                description="Add up to 8 skills that are needed for this role."
                content={
                    <SkillsInput
                        addSkill={append}
                        removeSkill={remove}
                        skills={skills}
                        error={methods.formState.errors.skills}
                        showError={methods.formState.submitCount > 0}
                        maxSkills={8}
                        hideDropdown={skills.length === 8}
                    />
                }
                iconName="Path"
                buttons={
                    <>
                        <Button variant="secondary" onClick={handleBackClick} type="button">Back</Button>
                        <Button type="submit">Next</Button>
                    </>
                }
            />
        </form>
    );
};

export default CreateRoleFormStepSkills;