import { useGigs } from "../api/gigs";
import { useOrganizationContext } from "../api/current-organization/organizationContext";
import Card from "../components/Card";
import LinkButton from "../components/LinkButton";
import PageContent from "../components/PageContent";
import Typography from "../components/Typography";
import UserAvatar from "../components/UserAvatar";
import { useAppPaths } from "../Routes";
import { gigStatuses } from "../models/app/gig";
import { useIsLargeScreen } from "../hooks/screenSizes";
import Loader from "../components/Loader";
import { Link } from "react-router-dom";

const GigsCompletedClientPage = () => {
    const appPaths = useAppPaths();
    const { gigs, isLoading } = useGigs({
        gigStatusIds: [gigStatuses.completed],
        initialOrderBy: "CompletedDate",
    });
    const isLargeScreen = useIsLargeScreen();

    const { gigTerminologyPlural } = useOrganizationContext();

    if (isLoading) return <Loader />;

    return (
        <PageContent>
            <Typography variant={isLargeScreen ? "display-large" : "display-small"} component="h1" gutterBottom>Completed {gigTerminologyPlural}</Typography>
            {gigs.length === 0 ? (
                <Card>
                    <Typography variant="title-large" component="p">None yet...</Typography>
                </Card>
            ) : (
                <div className="space-y-8">
                    {gigs.map(gig => (
                        <div key={gig.id} className="md:flex md:items-stretch break-words">
                            <Card className="flex flex-col md:flex-row text-center md:text-left items-center grow md:mr-4 rounded-b-none md:rounded-b-lg pb-2 md:pb-6">
                                <div className="space-y-4 md:mr-8">
                                    <Typography variant="title-large" component="p" className="max-w-md">{gig.title}</Typography>
                                    <p className="italic line-clamp-3 md:mr-8">{gig.description}</p>
                                </div>
                                <LinkButton className="block md:ml-auto" to={appPaths.gigs.details(gig.id)} variant="tertiary">View details</LinkButton>
                            </Card>
                            {gig.hiredTalentId && (
                                <Card className="shrink-0 md:w-[230px] flex items-center justify-center rounded-t-none md:rounded-t-lg pt-0 md:pt-6">
                                    <Link to={appPaths.talents.findTalentDetails(gig.hiredTalentId)} target="_blank"><UserAvatar name={`${gig.hiredTalentFirstName} ${gig.hiredTalentLastName}`} userImageUrl={gig.hiredTalentProfileImageThumbnailUrl} /></Link>
                                </Card>
                            )}
                        </div>
                    ))}
                </div>
            )}
        </PageContent>
    );
};

export default GigsCompletedClientPage;