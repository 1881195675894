import { useOrganizationContext } from "../api/current-organization/organizationContext";
import Card from "./Card";
import ExternalLinkButton from "./ExternalLinkButton";
import TalentMatchCard from "./TalentMatchCard";
import TitleUnderline from "./TitleUnderline";
import Typography from "./Typography";
import { ApiExternalMatch } from "../models/api/match";
import TalentExternalMatchCard from "./TalentExternalMatchCard";
import { Match } from "../models/app/match";
import { GigApiFetcherResponse } from "../api/common/fetching";
import { InviteFormDialogValues } from "./InviteButton/InviteFormDialogValues";

export type MatchesListProps = {
    id: string
    matches: Match[]
    externalMatches?: ApiExternalMatch[]
    onSubmit: (values: InviteFormDialogValues, talentId: string) => Promise<GigApiFetcherResponse<void>>
    isSubmitting: boolean
    canInvite?: boolean
    showCurrentRole?: boolean
    roleAssignmentTypeId?: number
}

const MatchesList = ({
    id,
    matches,
    externalMatches = [],
    onSubmit,
    isSubmitting,
    canInvite = true,
    showCurrentRole,
    roleAssignmentTypeId
}: MatchesListProps) => {
    const { organizationConfig: { isExternalMatchingEnabled } } = useOrganizationContext();

    return (
        <>
            {matches.length === 0 ? (
                <Card>
                    <Typography variant="body" component="p">Looks like we couldn't find any matches at this time. Please try again later.</Typography>
                </Card>
            ) : (
                <div className="space-y-8">
                    {matches.map(match => (
                        <TalentMatchCard 
                            key={match.talentId} 
                            id={id} 
                            match={match} 
                            onSubmit={onSubmit} 
                            isSubmitting={isSubmitting}
                            canInvite={canInvite} 
                            showCurrentRole={showCurrentRole}
                            roleAssignmentTypeId={roleAssignmentTypeId}
                        />
                    ))}
                </div>
            )}

            {(isExternalMatchingEnabled && externalMatches && externalMatches.length !==0) && (
                <>
                    <div className="flex flex-col space-y-4 text-center items-center">
                        <TitleUnderline />
                        <Typography variant="title-large" component="h3">Not found what you're looking for?</Typography>
                        <Typography variant="title-small" component="h3">Check out these matches from Gigged.AI:</Typography>
                    </div>
                    <div className="flex flex-col md:flex-row justify-center space-y-8 md:space-y-0 md:space-x-8">
                        {externalMatches.map(match => (
                            <TalentExternalMatchCard
                                key={match.talentId}
                                match={match}
                            />
                        ))}
                    </div>
                    <div className="mx-auto">
                        <ExternalLinkButton href="https://gigged.ai" target="_blank">
                            Visit Gigged.AI
                        </ExternalLinkButton>
                    </div>
                </>
            )}
        </>
    );
};

export default MatchesList;
