import Card from "../../components/Card";
import Chip from "../../components/Chip";
import Typography from "../../components/Typography";
import { ProposalMilestoneSummary } from "../../models/app/proposal";

type TalentMilestonesProps = {
    milestones: ProposalMilestoneSummary[]
}

const TalentMilestones = ({
    milestones,
}: TalentMilestonesProps) => {
    return (
        <div>
            <Typography variant="title-large" component="h2" className="mb-4">Milestones</Typography>
            <div className="space-y-2">
                {milestones.map(milestone => (
                    <Card className="sm:flex items-center space-y-4">
                        <div className="grow">
                            <Typography variant="title-small" component="h3">{milestone.description}</Typography>
                        </div>
                        <div className="space-x-2 shrink-0 ml-4">
                            {milestone.dateCompleted && (
                                <Chip size="large" label="Completed" className="!bg-primary !text-primary-text" />
                            )}
                            {!milestone.dateCompleted && (
                                <Chip size="large" label="Hired" />
                            )}
                        </div>
                    </Card>
                ))}
            </div>
        </div>
    );
};

export default TalentMilestones;
