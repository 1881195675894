import { useNavigate } from "react-router-dom";
import Card from "../components/Card";
import PageContent from "../components/PageContent";
import Typography from "../components/Typography";
import { useAppPaths, useRoleId } from "../Routes";
import { useApplications } from "../api/applications";
import { useRole } from "../api/role";
import { CreateApplicationDto, applicationStatuses } from "../models/app/application";
import CreateApplicationForm from "../components/CreateApplicationForm/CreateApplicationForm";
import { successToast } from "../toast";

const ApplicationCreatePage = () => {
    const appPaths = useAppPaths();
    const navigate = useNavigate();
    const { createApplication, isCreatingApplication } = useApplications();
    const roleId = useRoleId();
    const { role } = useRole(roleId as string);

    if (!roleId || !role) return null;

    const handleSaveAsDraft = async (values: CreateApplicationDto) => {
        const response = await createApplication({
            ...values,
            applicationStatusId: applicationStatuses.draft,
        });

        if (response.success) {
            successToast("Application saved to drafts.");
            navigate(appPaths.applications.index);
        }

        return response;
    };

    const handleSaveAndSubmit = async (values: CreateApplicationDto) => {
        const response = await createApplication({
            ...values,
            applicationStatusId: applicationStatuses.submitted,
        });

        if (response.success) {
            successToast("Application submitted successfully!");
            navigate(appPaths.roles.findARoleDetails(roleId));
        }

        return response;
    };

    return (
        <PageContent>
            <Card className="mb-4 space-y-4 break-words">
                <Typography variant="title-large" component="h2">{role?.title}</Typography>
                <p className="italic whitespace-pre-wrap">{role?.description}</p>
            </Card>
            <Typography variant="display-medium" component="h1" gutterBottom>Role Application</Typography>
            <CreateApplicationForm
                isSubmitting={isCreatingApplication}
                onSaveAsDraft={handleSaveAsDraft}
                onSaveAndSubmit={handleSaveAndSubmit}
                submitButtonText="Submit application"
                roleId={roleId}
            />
        </PageContent>
    );
};

export default ApplicationCreatePage;