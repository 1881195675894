import { useStateParams } from "../hooks/useStateParams";
import { TalentOrderByFieldKeys, TalentSummary } from "../models/app/talent";
import { GigApiPaginatedData } from "./common/apiResponse";
import { useGiggedApiSWR } from "./common/giggedApiSWR";
import { SortOrder } from "./common/sortOrder";

export type UseTalentsParams = {
    pageSize?: number
}

export type UseTalentsReturn = {
    talents: TalentSummary[]
    isLoading: boolean
    sortOrder: SortOrder
    orderBy: TalentOrderByFieldKeys
    searchTerm: string
    skillIds: string[]
    badgeIds: string[]
    locationIds: string[]
    specializationIds: string[]
    industrialExperienceIds: string[]
    setSearchTerm: (value: string) => void
    setSortOrder: (value: SortOrder) => void
    setOrderBy: (value: TalentOrderByFieldKeys) => void
    setSkillIds: (skillIds: string[]) => void
    setBadgeIds: (badgeIds: string[]) => void
    setSpecializationIds: (skillIds: string[]) => void
    setLocationIds: (locationIds: string[]) => void
    setIndustrialExperienceIds: (skillIds: string[]) => void
}

export type UseTalents = (params?: UseTalentsParams) => UseTalentsReturn;

export const useTalents: UseTalents = ({
    pageSize,
} = {}) => {
    const [searchTerm, setSearchTerm] = useStateParams("", "searchTerm", "string");
    const [skillIds, setSkillIds] = useStateParams<string[]>([], "skillIds", "stringArray");
    const [badgeIds, setBadgeIds] = useStateParams<string[]>([], "badgeIds", "stringArray");
    const [specializationIds, setSpecializationIds] = useStateParams<string[]>([], "specializationIds", "stringArray");
    const [industrialExperienceIds, setIndustrialExperienceIds] = useStateParams<string[]>([], "industrialExperienceIds", "stringArray");
    const [locationIds, setLocationIds] = useStateParams<string[]>([], "locationIds", "stringArray");
    const [sortOrder, setSortOrder] = useStateParams<SortOrder>("desc", "sortOrder", "string");
    const [orderBy, setOrderBy] = useStateParams<TalentOrderByFieldKeys>("Rating", "orderBy", "string");

    const searchTermQuery = searchTerm && searchTerm !== "" ? `&searchTerm=${searchTerm}` : "";
    const skillsQuery = skillIds.length > 0 ? `&skillIds=${skillIds.join(",")}` : "";
    const badgesQuery = badgeIds.length > 0 ? `&badgeIds=${badgeIds.join(",")}` : "";
    const specializationsQuery = specializationIds.length > 0 ? `&specializationIds=${specializationIds.join(",")}` : "";
    const industrialExperienceQuery = industrialExperienceIds.length > 0 ? `&industrialExperienceIds=${industrialExperienceIds.join(",")}` : "";
    const locationsQuery = locationIds.length > 0 ? `&locationIds=${locationIds.join(",")}` : "";

    const url = "api/talents?pageIndex=0" +
        `&pageSize=${pageSize}` +
        `&sortOrder=${sortOrder}` +
        `&orderBy=${orderBy}` +
        searchTermQuery +
        skillsQuery + 
        badgesQuery +
        specializationsQuery +
        industrialExperienceQuery +
        locationsQuery;

    const { data, isLoading } = useGiggedApiSWR<GigApiPaginatedData<TalentSummary>>(url);

    return {
        talents: data?.items || [],
        isLoading,
        orderBy,
        sortOrder,
        searchTerm,
        skillIds,
        badgeIds,
        specializationIds,
        industrialExperienceIds,
        locationIds,
        setLocationIds,
        setSearchTerm,
        setOrderBy,
        setSortOrder,
        setSkillIds,
        setBadgeIds,
        setSpecializationIds,
        setIndustrialExperienceIds,
    };
};
