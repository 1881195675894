import { useOrganizationContext } from "../api/current-organization/organizationContext";
import { useProposals } from "../api/proposals";
import Card from "../components/Card";
import Loader from "../components/Loader";
import PageContent from "../components/PageContent";
import ProposalsSummaryCard from "../components/ProposalSummaryCard";
import Typography from "../components/Typography";
import { useIsLargeScreen } from "../hooks/screenSizes";

const ProposalsListPage = () => {
    const { proposals, isLoading } = useProposals();
    const isLargeScreen = useIsLargeScreen();
    const { gigTerminology } = useOrganizationContext();

    if (isLoading) return <Loader />;
    return (
        <PageContent>
            <Typography variant={isLargeScreen ? "display-large" : "display-small"} component="h1" gutterBottom>{gigTerminology} Proposals</Typography>
            {proposals.length === 0 ? (
                <Card>
                    <Typography variant="title-large" component="p">None yet...</Typography>
                </Card>
            ) : (
                <div className="space-y-8">
                    {proposals.map(proposal => (
                        <ProposalsSummaryCard key={proposal.id} proposal={proposal} />
                    ))}
                </div>
            )}
        </PageContent>
    );
};

export default ProposalsListPage;