import PageContent from "../components/PageContent";
import { useRoles } from "../api/roles";
import { successToast } from "../toast";
import { ApiCreateRoleDto } from "../models/api/role";
import CreateRoleForm from "../components/CreateRoleForm/CreateRoleForm";
import { useNavigate } from "react-router-dom";
import { useAppPaths } from "../Routes";
import { roleStatuses } from "../models/app/role";

const RoleCreatePage = () => {
    const { createRole } = useRoles();
    const navigate = useNavigate();
    const appPaths = useAppPaths();
    
    const handlePost = async (values: ApiCreateRoleDto) => {
        const response = await createRole({
            ...values,
            roleStatusId: roleStatuses.open
        });
        if (response.success) {
            successToast("Role posted successfully!");
            navigate(appPaths.roles.matches(response.content));
        }

        return response;
    };

    const handleSaveAsDraft = async (values: ApiCreateRoleDto) => {
        const response = await createRole({
            ...values,
            roleStatusId: roleStatuses.draft
        });

        if (response.success) {
            successToast("Role saved as draft.");
            navigate(appPaths.roles.draft);
        }

        return response;
    };

    return (
        <PageContent className="pt-8">
            <CreateRoleForm onPost={handlePost} onSaveAsDraft={handleSaveAsDraft} />
        </PageContent>
    );
};

export default RoleCreatePage;