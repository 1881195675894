import { Briefcase } from "phosphor-react";
import { useOrganizationContext } from "../api/current-organization/organizationContext";
import useCurrentUser from "../auth/useCurrentUser";
import Card from "../components/Card";

import LinkButton from "../components/LinkButton";
import PageContent from "../components/PageContent";
import Typography from "../components/Typography";
import { useAppPaths } from "../Routes";

const RoleCreateIntroPage = () => {
    const appPaths = useAppPaths();
    const { userGivenName } = useCurrentUser();
    const { giggedClientTerminology } = useOrganizationContext();

    return (
        <PageContent>
            <Card className="md:mx-auto max-w-6xl text-center">
                <Briefcase size={64} className="text-primary-text mx-auto mt-2 mb-6" weight="duotone" />
                <Typography variant="display-small" component="h1" gutterBottom>{`Ready to create your role, ${userGivenName}`}?</Typography>
                <p className="mb-6">We'll ask you some key questions to create a new role within your organisation.</p>
                <span className="flex flex-col items-center mx-auto mb-6">
                    <p className="font-bold mb-2 text-center">Information you'll need:</p>
                    <ul className="list-disc text-left list-inside columns-2">
                        <li>Role title</li>
                        <li>Description</li>
                        <li>Role category</li>
                        <li>Role {giggedClientTerminology.toLowerCase()}</li>
                        <li>Skills required</li>
                        <li>Role assignment type</li>
                        <li>Role visibility</li>
                    </ul>
                </span>
                <div className="md:flex md:flex-row-reverse md:items-center md:justify-start space-y-4 md:space-y-0">
                    <LinkButton
                        to={appPaths.roles.create}
                        className="max-w-full md:max-w-md block"
                    >
                        Create role
                    </LinkButton>
                    <LinkButton
                        to={appPaths.dashboard}
                        variant="tertiary"
                        className="md:mr-2 max-w-full md:max-w-md block"
                    >
                        Cancel
                    </LinkButton>
                </div>
            </Card>
        </PageContent>
    );
};

export default RoleCreateIntroPage;
