import { Menu, Transition } from "@headlessui/react";
import { List } from "phosphor-react";
import { Link } from "react-router-dom";
import { useOrganizationContext } from "../../api/current-organization/organizationContext";
import useCurrentUser from "../../auth/useCurrentUser";
import { useAppPaths } from "../../Routes";

const MobileMenuDropdown = () => {
    const appPaths = useAppPaths();
    const { organizationConfig, gigTerminology, talentTerminologyPlural } = useOrganizationContext();
    const { userRole } = useCurrentUser();

    const navBarItems = [
        { name: `Find a ${gigTerminology}`, href: appPaths.gigs.findAGig },
    ];

    if (!organizationConfig?.isTalentProfilesPrivate || userRole === "client") {
        navBarItems.push({ name: `Find ${talentTerminologyPlural}`, href: appPaths.talents.findTalent });
    }

    return (
        <Menu as="div" className="relative">
            <Menu.Button className={"bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"}>
                <span className="sr-only">Open menu</span>
                <List
                    className="block h-6 w-6"
                    aria-hidden="true"
                />
            </Menu.Button>
            <Transition
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="origin-top absolute left-[-16px] mt-4 w-[100vw] rounded-none shadow-lg bg-white focus:outline-none z-50">
                    <div className="flex flex-col">
                        <Menu.Item>
                            {({ active }) => (
                                <Link
                                    to={appPaths.gigs.findAGig}
                                    className={`px-4 py-4 font-bold text-primary-text border-t border-surface ${active && "bg-surface"}`}
                                >
                                    Find a {gigTerminology}
                                </Link>
                            )}
                        </Menu.Item>
                        {(!organizationConfig?.isTalentProfilesPrivate || userRole === "client") && (
                            <Menu.Item>
                                {({ active }) => (
                                    <Link
                                        to={appPaths.talents.findTalent}
                                        className={`px-4 py-4 font-bold text-primary-text border-t border-surface ${active && "bg-surface"}`}
                                    >
                                        Find {talentTerminologyPlural}
                                    </Link>
                                )}
                            </Menu.Item>
                        )}
                    </div>
                </Menu.Items>
            </Transition>
        </Menu >
    );
};

export default MobileMenuDropdown;