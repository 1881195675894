import { useEffect, useState } from "react";
import { useOrganizationContext } from "../../api/current-organization/organizationContext";
import { fetchSpecializationOptions } from "../../api/specializations";
import { useTalentPersonalInformationContext } from "../../api/talentPersonalInformation";
import { useTalentSkills } from "../../api/talentSkills";
import useAccessToken from "../../auth/useAccessToken";
import ErrorList from "../../components/ErrorList";
import FormDropdown, { FormDropdownOption } from "../../components/FormDropdown";
import { successToast } from "../../toast";
import { talentProfileEditTooltips } from "../../tooltipsContent";

const TalentSpecializationForm = () => {
    const accessToken = useAccessToken();
    const [allSpecializationOptions, setAllSpecializationOptions] = useState<FormDropdownOption<string>[]>([]);
    const [isLoadingOptions, setIsLoadingOptions] = useState(false);
    const [errors, setErrors] = useState<string[]>([]);
    const { updateTalentSpecialization, isUpdatingTalentSpecialization, isValidating, isLoading, specialization } = useTalentSkills();
    const { giggedClientTerminologyPlural } = useOrganizationContext();
    const { reload: reloadPersonalInformation } = useTalentPersonalInformationContext();
    const [specializationId, setSpecializationId] = useState("");

    useEffect(() => {
        if (!accessToken) return;

        const loadAllSpecializations = async () => {
            setIsLoadingOptions(true);
            try {
                const fetchedSpecializationOptions = await fetchSpecializationOptions("", accessToken);
                setAllSpecializationOptions(fetchedSpecializationOptions);
            }
            finally {
                setIsLoadingOptions(false);
            }
        };

        loadAllSpecializations();
    }, [accessToken]);

    const handleSubmit = async (value: string) => {
        setErrors([]);

        const response = await updateTalentSpecialization({ specializationId: value });

        if (response.success) {
            successToast("Specialisation successfully updated.");
            reloadPersonalInformation();
        }
        else {
            setErrors(response.error.userMessages);
        }

        return response;
    };

    useEffect(() => {
        setSpecializationId(specialization?.id || "");
    }, [specialization]);

    if (allSpecializationOptions.length === 0) {
        return <p>Loading...</p>;
    }

    return (
        <>
            <div className="flex gap-6 flex-wrap w-full md:flex-nowrap">
                <div className="w-full">
                    <FormDropdown
                        required
                        tooltip={talentProfileEditTooltips.addSpecialization(giggedClientTerminologyPlural)}
                        label="Select specialisation"
                        value={specializationId}
                        options={allSpecializationOptions}
                        disabled={isUpdatingTalentSpecialization || isValidating || isLoading}
                        onChange={handleSubmit}
                        error={errors[0]}
                        isLoadingOptions={isLoadingOptions}
                    />
                </div>
            </div>
            <ErrorList errors={errors} />
        </>
    );
};

export default TalentSpecializationForm;