import { useAppPaths } from "../Routes";

export const getButtonText = (gigTerminology: string) => {
    return ({
        GigProposal: "View proposal",
        GigProposalAccepted: `View ${gigTerminology.toLowerCase()}`,
        GigPostedNotification: `View ${gigTerminology.toLowerCase()}`,
        GigInvite: `View ${gigTerminology.toLowerCase()}`,
        GigCompleted: `View ${gigTerminology.toLowerCase()}`,
        GigCancelled: `View ${gigTerminology.toLowerCase()}`,
        GigOnHold: `View ${gigTerminology.toLowerCase()}`,
        RoleOpenNotification: "View role",
        RoleInvite: "View role",
        RoleApplication: "View application",
        RoleApplicationAccepted: "View role",
        RoleAssign: "View role",
    });
};

export const getButtonHref = (userRole?: string, messageGigId?: string, messageRoleId?: string, messageProposalId?: string, messageApplicationId?: string) => {
    const appPaths = useAppPaths();

    return ({
        GigProposal: userRole === "client" ? appPaths.gigs.proposals(messageGigId ?? "") : userRole === "talent" ? appPaths.proposals.edit(messageProposalId ?? "") : appPaths.gigs.findAGigDetails(messageGigId ?? ""),
        GigProposalAccepted: appPaths.gigs.findAGigDetails(messageGigId ?? ""),
        GigPostedNotification: appPaths.gigs.findAGigDetails(messageGigId ?? ""),
        GigInvite: appPaths.gigs.findAGigDetails(messageGigId ?? ""),
        GigCompleted: appPaths.gigs.findAGigDetails(messageGigId ?? ""),
        GigCancelled: appPaths.gigs.findAGigDetails(messageGigId ?? ""),
        GigOnHold: appPaths.gigs.findAGigDetails(messageGigId ?? ""),
        RoleInvite: appPaths.roles.details(messageRoleId ?? ""),
        RoleOpenNotification: appPaths.roles.details(messageRoleId ?? ""),
        RoleApplication: userRole === "client" ? appPaths.roles.applications(messageRoleId ?? "") : userRole === "talent" ? appPaths.applications.edit(messageApplicationId ?? "") : appPaths.roles.details(messageRoleId ?? ""),
        RoleApplicationAccepted: appPaths.roles.details(messageRoleId ?? ""),
        RoleAssign: appPaths.roles.details(messageRoleId ?? ""),
    });
};