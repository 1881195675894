import { useAppPaths } from "../../Routes";
import { useRoles } from "../../api/roles";
import LinkButton from "../../components/LinkButton";
import Loader from "../../components/Loader";
import PageContent from "../../components/PageContent";
import { useIsLargeScreen } from "../../hooks/screenSizes";
import Typography from "../../components/Typography";
import Card from "../../components/Card";
import { roleStatuses } from "../../models/app/role";


const RolesListArchivedPage = () => {
    const appPaths = useAppPaths();
    const { roles, isLoading } = useRoles({
        roleStatusIds: [roleStatuses.archived],
        initialOrderBy: "CreatedAt",
        initialSortOrder: "desc"
    });
    const isLargeScreen = useIsLargeScreen();

    if (isLoading) return <Loader />;

    return (
        <PageContent>
            <Typography variant={isLargeScreen ? "display-large" : "display-small"} component="h1" gutterBottom>Archived Roles</Typography>
            {roles.length === 0 ? (
                <Card>
                    <Typography variant="title-large" component="p">None yet...</Typography>
                </Card>
            ) : (
                <div className="space-y-8">
                    {roles.map(role => (
                        <div key={role.id} className="md:flex md:items-stretch break-words">
                            <Card className="flex flex-col md:flex-row text-center md:text-left items-center grow md:mr-4 rounded-b-none md:rounded-b-lg pb-2 md:pb-6">
                                <div className="space-y-4 md:mr-8">
                                    <Typography variant="title-large" component="p" className="max-w-md">{role.title}</Typography>
                                    <p className="italic line-clamp-3 md:mr-8">{role.description}</p>
                                </div>
                                <LinkButton className="block md:ml-auto" to={appPaths.roles.details(role.id)} variant="tertiary">View details</LinkButton>
                            </Card>
                        </div>
                    ))}
                </div>
            )}
        </PageContent>
    );
};

export default RolesListArchivedPage;