import clsx from "clsx";
import { useState } from "react";
import ApiError from "../../api/common/apiError";
import { GigApiFetcherResponse } from "../../api/common/fetching";

import { formClassNames } from "../CreateGigForm/classNames";

import GeneralFormError from "../GeneralFormError";
import { ApplicationDetails, applicationStatuses } from "../../models/app/application";
import { ApplicationFormFields, ApplicationFormValues } from "../ApplicationForm";
import { useApplicationForm } from "../ApplicationForm/ApplicationFormValues";
import Button from "../Button";


export type EditApplicationFormProps = {
    application: ApplicationDetails
    isSubmitting: boolean
    onSubmit: (values: ApplicationFormValues) => Promise<GigApiFetcherResponse<void>>
    submitButtonText: string
}

const EditApplicationForm = ({
    onSubmit,
    submitButtonText,
    isSubmitting,
    application
}: EditApplicationFormProps) => {
    const [submissionError, setSubmissionError] = useState<ApiError | undefined>(undefined);

    const methods = useApplicationForm(application);

    const handleSubmit = async (values: ApplicationFormValues, applicationStatus: number) => {
        const response = await onSubmit({
            ...values,
            applicationStatusId: applicationStatus
        });

        if (!response.success) {
            setSubmissionError(response.error);
        }

        return response;
    };

    return (
        <form
            className={clsx(formClassNames, "p-8 space-y-8")}
            noValidate
            onSubmit={methods.handleSubmit(data =>
                handleSubmit(data, applicationStatuses.submitted)
            )}
        >
            <ApplicationFormFields
                methods={methods}
            />

            <GeneralFormError error={submissionError} className="ml-8" />

            <div className="flex flex-col space-y-4 justify-end md:flex-row-reverse md:w-fit md:space-y-0 md:ml-auto">
                <Button loading={isSubmitting} type="submit">{submitButtonText}</Button>
                <Button
                    type="button"
                    loading={isSubmitting}
                    className="w-full md:w-auto md:mr-4"
                    variant="secondary"
                    onClick={methods.handleSubmit(data =>
                        handleSubmit(data, applicationStatuses.draft)
                    )}
                >
                    Save draft
                </Button>
            </div>
        </form>
    );
};

export default EditApplicationForm;