import clsx from "clsx";
import { applicationStatuses } from "../models/app/application";
import { capitalizeStrings } from "../utils/capitalizeStrings";

export type ApplicationStatusChipProps = {
    applicationStatusId: number
}

const ApplicationStatusChip = ({
    applicationStatusId,
}: ApplicationStatusChipProps) => {
    const label = capitalizeStrings(Object.keys(applicationStatuses)[applicationStatusId]);
    return (
        <div className="md:min-w-[110px] text-center md:text-left">
            <span className={clsx(
                "rounded-full px-4 py-1.5 font-bold text-white",
                applicationStatusId === applicationStatuses.draft && "bg-none !text-black border-2",
                applicationStatusId === applicationStatuses.submitted && "bg-[#9e9e9e]",
                applicationStatusId === applicationStatuses.hired && "bg-primary",
                applicationStatusId === applicationStatuses.cancelled && "!text-black !p-0",
                applicationStatusId === applicationStatuses.unsuccessful && "bg-[#d82020]",
                applicationStatusId === applicationStatuses.completed && "bg-none !text-black border-2",
                applicationStatusId === applicationStatuses.pending && "bg-[#db7c29]",
            )}>
                {label}
            </span>
        </div>
    );
};

export default ApplicationStatusChip;