import { useEffect, useState } from "react";
import { GigApiFetcherResponse, useAuthenticatedGigApiFetcher } from "./common/fetching";
import { useGiggedApiSWR } from "./common/giggedApiSWR";
import ApiError from "./common/apiError";
import { ApiAssignRoleDto, ApiRoleDetailsDto, ApiUpdateRoleDto, InviteToRoleDto } from "../models/api/role";

export const useInviteToRole = () => useAuthenticatedGigApiFetcher<InviteToRoleDto, void, { roleId: string, dto: InviteToRoleDto }>("POST",
    ({ roleId, dto }) => ({
        url: `api/roles/${roleId}/invites`,
        body: dto
    }));

export const useUpdateRole = () => useAuthenticatedGigApiFetcher<ApiUpdateRoleDto, void, { roleId: string, dto: ApiUpdateRoleDto }>("PUT",
    ({ roleId, dto }) => ({
        url: `api/me/roles/${roleId}`,
        body: dto,
    }));

export const useArchiveRole = () => useAuthenticatedGigApiFetcher<never, void, string>("PUT",
    (roleId) => ({
        url: `api/roles/${roleId}/actions/archive`
    }));

export const useRegenerateMatches = () => useAuthenticatedGigApiFetcher<never, void, string>("PUT",
    (roleId) => ({
        url: `api/roles/${roleId}/actions/regenerate-matches`
    }));

export const useAssignRole = () => useAuthenticatedGigApiFetcher<ApiAssignRoleDto, void, { roleId: string, dto: ApiAssignRoleDto }>("POST",
    ({ roleId, dto }) => ({
        url: `api/me/roles/${roleId}/assign`,
        body: dto
    }));


export type UseRoleReturn<TRoleId extends string | undefined> = TRoleId extends undefined ?
    { role: undefined } :
    {
        role?: ApiRoleDetailsDto
        isLoading: boolean
        error?: ApiError
        reload: () => Promise<void>
        inviteToRole: (dto: InviteToRoleDto) => Promise<GigApiFetcherResponse<void>>
        updateRole: (dto: ApiUpdateRoleDto) => Promise<GigApiFetcherResponse<void>>
        archiveRole: () => Promise<GigApiFetcherResponse<void>>
        regenerateMatches: () => Promise<GigApiFetcherResponse<void>>
        assignRole: (dto: ApiAssignRoleDto) => Promise<GigApiFetcherResponse<void>>
        isInvitingToRole: boolean
        isUpdatingRole: boolean
        isArchivingRole: boolean
        isAssigningRole: boolean
        isRegeneratingMatches: boolean
    }

export const useRole = <TRoleId extends string | undefined>(roleId: TRoleId): UseRoleReturn<TRoleId> => {
    const [role, setRole] = useState<ApiRoleDetailsDto | undefined>();

    const url = `api/roles/${roleId}`;

    const apiCall = useGiggedApiSWR<ApiRoleDetailsDto>(url);

    useEffect(() => {
        if (apiCall.data === undefined) {
            setRole(undefined);
            return;
        }

        setRole(apiCall.data);
    }, [apiCall.data]);

    const [updateRole, isUpdatingRole] = useUpdateRole();
    const [archiveRole, isArchivingRole] = useArchiveRole();
    const [regenerateMatches, isRegeneratingMatches] = useRegenerateMatches();
    const [inviteToRole, isInvitingToRole] = useInviteToRole();
    const [assignRole, isAssigningRole] = useAssignRole();

    if (roleId === undefined) return { role: undefined } as UseRoleReturn<TRoleId>;

    return {
        role,
        isLoading: apiCall.isLoading,
        error: apiCall.error,
        updateRole: async (dto: ApiUpdateRoleDto) => {
            const response = await updateRole({ roleId, dto });

            if (response.success) {
                apiCall.mutate();
            }

            return response;
        },
        archiveRole: async () => {
            const response = await archiveRole(roleId);

            if (response.success) {
                apiCall.mutate();
            }

            return response;
        },
        regenerateMatches: async () => {
            const response = await regenerateMatches(roleId);

            if (response.success) apiCall.mutate();

            return response;
        },
        inviteToRole: async (dto: InviteToRoleDto) => {
            const response = await inviteToRole({ roleId, dto });
            if (response.success) apiCall.mutate();

            return response;
        },
        assignRole: async (dto: ApiAssignRoleDto) => {
            const response = await assignRole({ roleId, dto });

            if (response.success) {
                apiCall.mutate();
            }

            return response;
        },
        isInvitingToRole,
        isUpdatingRole,
        isArchivingRole,
        isRegeneratingMatches,
        isAssigningRole,
        reload: apiCall.mutate,
    } as UseRoleReturn<TRoleId>;
};