import { Rating } from "@mui/material";
import { useState } from "react";
import { useGig } from "../../api/gig";
import Button from "../../components/Button";
import DialogBox, { useDialogBoxState } from "../../components/DialogBox";
import FormTextAreaInput from "../../components/FormTextAreaInput";
import { gigStatuses } from "../../models/app/gig";
import { CompleteGigFormDialogValues, useCompleteGigForm } from "./CompleteGigFormDialogValues";
import { Controller } from "react-hook-form";
import { Star } from "phosphor-react";
import { useOrganizationContext } from "../../api/current-organization/organizationContext";
import ApiError from "../../api/common/apiError";
import GeneralFormError from "../../components/GeneralFormError";

export type CompleteGigButtonProps = {
    gigId: string
    className?: string
    disabled?: boolean
}
const CompleteGigButton = ({
    gigId,
    className,
    disabled,
}: CompleteGigButtonProps) => {
    const { completeGig, gig } = useGig(gigId);
    const methods = useCompleteGigForm();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const dialogState = useDialogBoxState();
    const { gigTerminology } = useOrganizationContext();
    const [submissionError, setSubmissionError] = useState<ApiError | undefined>(undefined);

    const handleConfirm = async (values: CompleteGigFormDialogValues) => {
        setIsSubmitting(true);

        const response = await completeGig(values);
        
        setIsSubmitting(false);

        if (response.success) {
            dialogState.close();
            return;
        }

        setSubmissionError(response.error);
    };

    if (gig?.gigStatusId === gigStatuses.cancelled) return null;

    return (
        <span className={className}>
            <div className="m-auto">
                <Button
                    onClick={dialogState.onOpen}
                    className="w-full"
                    disabled={gig?.gigStatusId !== gigStatuses.hired || disabled}
                >
                    {gig?.gigStatusId === gigStatuses.hired ? `Complete ${gigTerminology.toLowerCase()}` : "Completed"}
                </Button>
            </div>
            <form onSubmit={methods.handleSubmit(handleConfirm)}>
                <DialogBox
                    {...dialogState}
                    title={`Complete ${gigTerminology.toLowerCase()}`}
                >
                    <FormTextAreaInput
                        id="complete-gig-feedback"
                        placeholder="Type feedback here"
                        register={methods.register("feedback")}
                        error={methods.formState.errors.feedback}
                        disabled={methods.formState.isSubmitting}
                    />
                    <div className="flex flex-row space-x-4 justify-around">
                        <Controller 
                            name="rating"
                            control={methods.control}
                            defaultValue={1}
                            render={({ field: { onChange, value } }) => 
                                <Rating 
                                    name="rating" 
                                    onChange={onChange} 
                                    value={+value}
                                    icon={<Star className="text-primary-text" weight="fill" />}
                                    emptyIcon={<Star className="text-primary-text" />}
                                />                        
                            }
                        />  
                        <p>Overall performance of work</p>                    
                    </div>
                    <GeneralFormError error={submissionError} className="ml-8" />
                    <div className="mt-4">
                        <Button className="w-full" type="submit" loading={isSubmitting}>Complete {gigTerminology.toLowerCase()}</Button>
                    </div>
                </DialogBox>
            </form>
        </span>
    );
};

export default CompleteGigButton;