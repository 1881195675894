import { ApiRoleCategoryDropdown } from "../models/api/roleCategory";
import { GigApiPaginatedData } from "./common/apiResponse";
import gigApiFetcher from "./common/fetching";

export const fetchRoleCategories = async (
    query: string,
    accessToken: string | null,
    pageIndex = 0,
    pageSize = 100,
): Promise<ApiRoleCategoryDropdown[]> => {
    const path = `api/roles/categories?pageIndex=${pageIndex}&pageSize=${pageSize}&searchTerm=${query}`;
    const response = await gigApiFetcher<never, GigApiPaginatedData<ApiRoleCategoryDropdown>>({
        url: path,
        token: accessToken || undefined
    });

    return response.items;
};