import { addDays } from "date-fns";
import { Controller, useFieldArray, UseFormReturn } from "react-hook-form";
import FormTextInput from "../FormTextInput";
import { GigFormValues } from "./GigFormValues";
import { initialStageOptions, expectedDurationOptions, remoteOptions } from "../../models/app/gig";
import FormDateInput from "../FormDateInput";
import FormTextAreaInput from "../FormTextAreaInput";
import FormDropdown from "../FormDropdown";
import SkillsInput from "../SkillsInput";
import { clientEditGigTooltips } from "../../tooltipsContent";
import { useOrganizationContext } from "../../api/current-organization/organizationContext";
import pluralizeString from "../../utils/pluralizeString";
import FormToggle from "../FormToggle";

export type CreateGigFormStep8SummaryProps = {
    methods: UseFormReturn<GigFormValues>
    initialValues: GigFormValues
    hideSkillsDropdown?: boolean
}

const GigFormFields = ({
    methods,
    initialValues,
    hideSkillsDropdown,
}: CreateGigFormStep8SummaryProps) => {
    const { gigTerminology, talentTerminology } = useOrganizationContext();
    const { append: appendSkill, remove: removeSkill } = useFieldArray<GigFormValues, "skills">({
        control: methods.control,
        name: "skills",
        keyName: "id",
    });

    const { skills } = methods.watch();

    return (
        <>
            <div className="md:flex">
                <div className="space-y-8 md:pr-8 md:w-1/2">
                    <FormTextInput
                        required
                        id="create-gig-form-summary-title"
                        label={`Name of the ${gigTerminology.toLowerCase()}`}
                        placeholder={`Enter name of the ${gigTerminology.toLowerCase()}`}
                        error={methods.formState.errors.title}
                        defaultValue={initialValues.title}
                        tooltip={clientEditGigTooltips.gigName(gigTerminology)}
                        {...methods.register("title")}
                    />
                    <Controller
                        name="expectedDurationId"
                        control={methods.control}
                        render={({ field: { onChange, value } }) => (
                            <FormDropdown
                                required
                                label="Duration"
                                options={expectedDurationOptions}
                                error={methods.formState.errors.expectedDurationId}
                                onChange={onChange}
                                value={value}
                                tooltip={clientEditGigTooltips.gigDuration(gigTerminology)}
                            />
                        )}
                    />
                    <div className="md:flex space-y-8 md:space-y-0">
                        <Controller
                            name="startDate"
                            control={methods.control}
                            render={({ field: { onChange, value } }) => (
                                <FormDateInput
                                    required
                                    id="create-gig-form-summary-start-date"
                                    label="Start date"
                                    value={value}
                                    onChange={onChange}
                                    minDate={new Date()}
                                    error={methods.formState.errors.startDate}
                                    hideHelpText
                                    tooltip={clientEditGigTooltips.gigStartDate(gigTerminology)}
                                />
                            )}
                        />
                        <div className="w-16 hidden md:block" />
                        <Controller
                            name="deadlineDate"
                            control={methods.control}
                            render={({ field: { onChange, value } }) => (
                                <FormDateInput
                                    id="create-gig-form-summary-deadline"
                                    label="Deadline (optional)"
                                    value={value}
                                    onChange={onChange}
                                    error={methods.formState.errors.deadlineDate}
                                    minDate={addDays(methods.watch().startDate as Date, 1)}
                                    hideHelpText
                                    tooltip={clientEditGigTooltips.gigDeadline(gigTerminology)}
                                />
                            )}
                        />
                    </div>
                    <FormTextAreaInput
                        required
                        id="create-gig-form-summary-description"
                        label={`${gigTerminology} description`}
                        placeholder={`Enter ${gigTerminology.toLowerCase()} description`}
                        register={methods.register("description")}
                        error={methods.formState.errors.description}
                        defaultValue={initialValues.description}
                        tooltip={clientEditGigTooltips.gigDescription(gigTerminology)}
                    />
                </div>
                <div className="space-y-8 md:pl-8 md:w-1/2 mt-8 md:mt-0">
                    <Controller
                        name="initialStageId"
                        control={methods.control}
                        render={({ field: { onChange, value } }) => (
                            <FormDropdown
                                required
                                label={`Stage of the ${gigTerminology.toLowerCase()}`}
                                options={initialStageOptions}
                                error={methods.formState.errors.initialStageId}
                                onChange={onChange}
                                value={value}
                                tooltip={clientEditGigTooltips.gigStage(gigTerminology)}
                            />
                        )}
                    />

                    <SkillsInput
                        addSkill={appendSkill}
                        removeSkill={removeSkill}
                        skills={skills}
                        error={methods.formState.errors.skills}
                        showError={methods.formState.submitCount > 0}
                        hideDropdown={hideSkillsDropdown}
                        tooltip={clientEditGigTooltips.gigSkills(gigTerminology, pluralizeString(talentTerminology))}
                    />

                    <Controller
                        name="isCompletedRemotely"
                        control={methods.control}
                        render={({ field: { onChange, value } }) => (
                            <FormDropdown
                                required
                                label="Can be completed remotely?"
                                options={remoteOptions}
                                error={methods.formState.errors.isCompletedRemotely}
                                onChange={onChange}
                                value={value}
                                tooltip={clientEditGigTooltips.remoteWork(gigTerminology)}
                            />
                        )}
                    />
                    <Controller
                        name="isComplianceCheckRequired"
                        control={methods.control}
                        render={({ field: { onChange, value } }) => (
                            <FormToggle
                                label="Compliance check"
                                description="Compliance check required"
                                onChange={onChange}
                                checked={value}
                                tooltip={clientEditGigTooltips.complianceCheck(talentTerminology)}
                            />
                        )}
                    />
                </div>
            </div>
        </>
    );
};

export default GigFormFields;
