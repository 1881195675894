import { ApiRoleAssignmentSummaryDto, ApiRoleAssignmentDetailsDto } from "../api/roleAssignment";
import { RoleAssignmentSummaryDto, RoleAssignmentDetailsDto } from "../app/roleAssignment";

export const mapFromApiRoleAssignmentSummaryDto = (apiDto: ApiRoleAssignmentSummaryDto): RoleAssignmentSummaryDto => ({
    ...apiDto,
    id: apiDto.roleAssignmentId
});

export const mapFromApiRoleAssignmentDetailsDto = (apiDto: ApiRoleAssignmentDetailsDto): RoleAssignmentDetailsDto => ({
    ...apiDto,
    id: apiDto.roleAssignmentId
});