import useCurrentUser from "../../auth/useCurrentUser";
import DialogBox, { useDialogBoxState } from "../../components/DialogBox";
import Typography from "../../components/Typography";
import { useAppPaths, useRoleId } from "../../Routes";
import Button from "../../components/Button";
import PageContent from "../../components/PageContent";
import { useOrganizationContext } from "../../api/current-organization/organizationContext";
import Loader from "../../components/Loader";
import { useRole } from "../../api/role";
import { roleStatuses } from "../../models/app/role";
import Card from "../../components/Card";
import UserAvatar from "../../components/UserAvatar";
import RoleDetailsCard from "../../components/RoleDetailsCard";
import ApplicationCoverLetterButton from "../../components/ApplicationCoverLetterButton";
import formatDateString from "../../utils/formatDateString";
import { Link, useNavigate } from "react-router-dom";
import { successToast } from "../../toast";
import { useEffect } from "react";

const RoleDetailsPage = () => {
    const roleId = useRoleId();
    const { role, isLoading, archiveRole, isArchivingRole, reload } = useRole(roleId);
    const { userRole } = useCurrentUser();
    const archiveRoleDialogState = useDialogBoxState();
    const { talentTerminologyPlural } = useOrganizationContext();
    const appPaths = useAppPaths();
    const navigate = useNavigate();

    const handleArchive = async () => {
        const response = await archiveRole();

        if (response.success) {
            successToast("Role successfully archived.");
            navigate(appPaths.roles.archived);
        }

        return response;
    };

    useEffect(() => {
        reload();
    }, [role?.hiredTalents]);

    if (isLoading || !role) return <Loader />;

    const hiredApplications = role.hiredApplications && role.hiredApplications.map(application => application);

    return (
        <>
            <PageContent className="space-y-8">
                <div>
                    <Typography variant="display-small" component="h1" gutterBottom>Role details</Typography>
                    <RoleDetailsCard role={role} />
                </div>
                {userRole === "client" && (
                    <>
                        <div className="space-y-8">
                            <div>
                                <Typography variant="title-large" component="h2" className="mb-4">Hired {talentTerminologyPlural.toLowerCase()}</Typography>
                                <div className="space-y-8">
                                    {hiredApplications?.length === 0 && (
                                        <Card>
                                            <Typography variant="title-large" component="p">None yet...</Typography>
                                        </Card>
                                    )}
                                    {(hiredApplications && hiredApplications?.length > 0) && (
                                        hiredApplications.map(application => (
                                            <div key={application.id} className="md:flex md:space-x-4">
                                                <Card className="pb-0 md:max-w-[230px] md:pb-6 flex justify-center items-center rounded-b-none md:rounded-b-lg">
                                                    <UserAvatar name={`${application.talentFirstName} ${application.talentLastName}`} userImageUrl={application.talentProfileImageThumbnailUrl} />
                                                </Card>
                                                <Card key={application.id} className="md:flex md:grow items-center space-y-4 md:space-y-0 rounded-t-none md:rounded-t-lg">
                                                    <div className="flex justify-center md:ml-auto">
                                                        <ApplicationCoverLetterButton application={application} />
                                                    </div>
                                                </Card>
                                            </div>
                                        ))
                                    )}
                                </div>
                            </div>
                            <div>
                                <Typography variant="title-large" component="h2" className="mb-4">Role history</Typography>
                                <div className="space-y-4">
                                    {(!role.roleTalentHistories?.length || role.roleTalentHistories && role.roleTalentHistories?.every(talent => !talent.endDate)) && (
                                        <Card>
                                            <Typography variant="title-large" component="p">None yet...</Typography>
                                        </Card>
                                    )}
                                    {role.roleTalentHistories && role.roleTalentHistories?.filter(talent => talent.endDate).map(talent => (
                                        <Card className="space-x-4 flex flex-row items-center justify-between">
                                            <Link to={appPaths.talents.findTalentDetails(talent.talentId)}><Typography variant="title-small" component="p">{talent.talentFirstName} {talent.talentLastName}</Typography></Link>
                                            <Typography variant="body" component="p" className="italic">(Start: {formatDateString(talent.startDate)} - End: {formatDateString(talent.endDate)})</Typography>
                                        </Card>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-end">
                            {role.roleStatusId !== (roleStatuses.closed || roleStatuses.archived) && (
                                <Button
                                    type="button"
                                    className="w-full md:w-auto"
                                    variant="secondary"
                                    disabled={role?.roleStatusId === roleStatuses.archived}
                                    onClick={archiveRoleDialogState.open}
                                >
                                    {role.roleStatusId === roleStatuses.archived ? "Archived" : "Archive role"}
                                </Button>
                            )}
                        </div>
                    </>
                )}
            </PageContent >
            <DialogBox
                {...archiveRoleDialogState}
                title={"Are you sure you want to archive this role? This action cannot be undone."}
            >
                <div className="mt-4 flex flex-col sm:flex-row-reverse space-y-4 sm:space-y-0">
                    <Button type="button" className="w-full sm:w-28" onClick={handleArchive} loading={isArchivingRole}>Confirm</Button>
                    <Button type="button" className="w-full sm:w-28 mr-auto" variant="secondary" onClick={archiveRoleDialogState.close} loading={isArchivingRole}>Back</Button>
                </div>
            </DialogBox>
        </>
    );
};

export default RoleDetailsPage;
