import { applicationStatuses } from "../models/app/application";

const statusTextMap = {
    [applicationStatuses.draft]: "Application in drafts",
    [applicationStatuses.submitted]: "Application submitted",
    [applicationStatuses.hired]: "Hired",
    [applicationStatuses.pending]: "Application pending",
};

export const getApplicationStatusLabelText = (applicationStatusId?: number): string | null => {
    return (typeof applicationStatusId === "number" && applicationStatusId in statusTextMap) ? statusTextMap[applicationStatusId] : null;
};

export const getApplicationStatusButtonText = (applicationStatusId?: number): string => {
    return (typeof applicationStatusId === "number" && applicationStatusId in statusTextMap) ? statusTextMap[applicationStatusId] : "Send application";
};