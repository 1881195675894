import { Link } from "react-router-dom";
import { useGigs } from "../api/gigs";
import { useOrganizationContext } from "../api/current-organization/organizationContext";
import Card from "../components/Card";
import LinkButton from "../components/LinkButton";
import PageContent from "../components/PageContent";
import Typography from "../components/Typography";
import { useAppPaths } from "../Routes";
import { gigStatuses } from "../models/app/gig";
import { useIsLargeScreen } from "../hooks/screenSizes";
import Loader from "../components/Loader";

const GigsListPostedPage = () => {
    const appPaths = useAppPaths();
    const { gigs, isLoading } = useGigs({
        gigStatusIds: [gigStatuses.posted],
        initialOrderBy: "PostedDate",
    });
    const { gigTerminologyPlural } = useOrganizationContext();
    const isLargeScreen = useIsLargeScreen();

    if (isLoading) return <Loader />;

    return (
        <PageContent>
            <Typography variant={isLargeScreen ? "display-large" : "display-small"} component="h1" gutterBottom>Posted {gigTerminologyPlural}</Typography>
            {gigs.length === 0 ? (
                <Card>
                    <Typography variant="title-large" component="p">None yet...</Typography>
                </Card>
            ) : (
                <div className="space-y-8">
                    {gigs.map(gig => (
                        <div key={gig.id} className="flex flex-col md:flex-row items-stretch break-words">
                            <Card key={gig.id} className="flex flex-col text-center md:text-left md:flex-row grow items-center rounded-b-none md:rounded-b-lg pb-2 md:pb-6">
                                <div className="space-y-4 md:mr-8">
                                    <Typography variant="title-large" component="h3" className="max-w-md">{gig.title}</Typography>
                                    <p className="italic line-clamp-3 md:mr-8 w-full">{gig.description}</p>
                                </div>
                                <LinkButton className="md:ml-auto" variant="tertiary" to={appPaths.gigs.edit(gig.id)}>Edit</LinkButton>
                                <LinkButton className="md:ml-4" variant="secondary" to={appPaths.gigs.matches(gig.id)}>View matches</LinkButton>
                            </Card>
                            <Link to={appPaths.gigs.proposals(gig.id)} className="flex flex-col justify-center md:ml-4 md:min-w-[15%]">
                                <Card className="flex items-center h-full justify-center rounded-t-none md:rounded-t-lg pt-2 md:pt-6">
                                    <div>
                                        <p className="text-primary-hover text-center font-extrabold text-title-large">{gig.submittedProposalsCount}</p>
                                        <p>Proposals</p>
                                    </div>
                                </Card>
                            </Link>
                        </div>
                    ))}
                </div>
            )}
        </PageContent>
    );
};

export default GigsListPostedPage;