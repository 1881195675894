import React from "react";
import { SortOrder } from "../../api/common/sortOrder";
import { TalentOrderByFieldKeys, TalentSummary } from "../../models/app/talent";
import contextPlaceholderFunc from "../../utils/contextPlaceholderFun";

export type FindTalentContextType = {
    talents: TalentSummary[]
    isLoading: boolean
    searchTerm: string
    skillIds: string[]
    badgeIds: string[]
    specializationIds: string[]
    industrialExperienceIds: string[]
    locationIds: string[]
    sortOrder: SortOrder
    orderBy: TalentOrderByFieldKeys
    anyActiveFilters: boolean
    setSearchTerm: (value: string) => void
    setSkillIds: React.Dispatch<React.SetStateAction<string[]>>
    setBadgeIds: React.Dispatch<React.SetStateAction<string[]>>
    setSpecializationIds: React.Dispatch<React.SetStateAction<string[]>>
    setIndustrialExperienceIds: React.Dispatch<React.SetStateAction<string[]>>
    setLocationIds: React.Dispatch<React.SetStateAction<string[]>>
    setSortOrder: (value: SortOrder) => void
    setOrderBy: (value: TalentOrderByFieldKeys) => void
    clearAllFilters: () => void
    searchNow: () => void
}

export const FindTalentContext = React.createContext<FindTalentContextType>({
    talents: [],
    isLoading: false,
    searchTerm: "",
    skillIds: [],
    badgeIds: [],
    specializationIds: [],
    industrialExperienceIds: [],
    locationIds: [],
    sortOrder: "asc",
    orderBy: "LastName",
    anyActiveFilters: false,
    setSearchTerm: contextPlaceholderFunc,
    setSkillIds: contextPlaceholderFunc,
    setBadgeIds: contextPlaceholderFunc,
    setSpecializationIds: contextPlaceholderFunc,
    setIndustrialExperienceIds: contextPlaceholderFunc,
    setLocationIds: contextPlaceholderFunc,
    setSortOrder: contextPlaceholderFunc,
    setOrderBy: contextPlaceholderFunc,
    clearAllFilters: contextPlaceholderFunc,
    searchNow: contextPlaceholderFunc,
});

export const useFindTalentContext: () => FindTalentContextType = () => React.useContext(FindTalentContext);
