import { yupResolver } from "@hookform/resolvers/yup";
import clsx from "clsx";
import { useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import ApiError from "../../api/common/apiError";
import { GigApiFetcherResponse } from "../../api/common/fetching";
import { useOrganizationContext } from "../../api/current-organization/organizationContext";
import { useProposal } from "../../api/proposal";
import { useUpdateProposalMilestone } from "../../api/proposal-milestone";
import { ApiFile } from "../../models/api/file";
import { talentProposalTooltips } from "../../tooltipsContent";
import { DEFAULT_PROPOSAL_MILESTONE } from "../../utils/constants";
import { convertFileToDataUrl } from "../../utils/convertFileToDataUrl";
import Button from "../Button";
import { formClassNames } from "../CreateGigForm/classNames";
import FileManager from "../FileManager";
import FormTextAreaInput from "../FormTextAreaInput";
import FormTextInput from "../FormTextInput";
import FormToggle from "../FormToggle";
import GeneralFormError from "../GeneralFormError";
import { EditProposalFormValues, validationSchemaFields } from "./EditProposalFormValues";
import ProposalMilestones from "./ProposalMilestones";

const validationSchema = yup.object(validationSchemaFields);

export type EditProposalFormProps = {
    proposalId: string
    initialValues: EditProposalFormValues
    onSubmit: (value: EditProposalFormValues, proposalFiles: ApiFile[]) => Promise<GigApiFetcherResponse<unknown>>
    submitButtonText: string
    isSubmitting: boolean
    proposalFiles?: ApiFile[]
}

const EditProposalForm = ({
    proposalId,
    initialValues,
    onSubmit,
    submitButtonText,
    isSubmitting,
    proposalFiles: initialProposalFiles = [],
}: EditProposalFormProps) => {
    const { gigTerminology, gigTerminologyPlural, giggedClientTerminology } = useOrganizationContext();
    const { refetchProposal } = useProposal(proposalId);

    const [updateProposalMilestone] = useUpdateProposalMilestone();

    const [talentDefinedMilestones, setTalentDefinedMilestones] = useState(initialValues.milestones.length > 0 ? initialValues.milestones[0].description !== DEFAULT_PROPOSAL_MILESTONE : false);
    const [proposalFiles, setProposalFiles] = useState<ApiFile[]>(initialProposalFiles);
    const [submissionError, setSubmissionError] = useState<ApiError | undefined>(undefined);

    const handleSubmit = async (values: EditProposalFormValues) => {
        if (!talentDefinedMilestones) {
            const response = await updateProposalMilestone({
                milestoneId: initialValues.milestones[0].id,
                dto: {
                    ...initialValues.milestones[0],
                    description: DEFAULT_PROPOSAL_MILESTONE,
                }
            });

            if (!response.success) {
                setSubmissionError(response.error);
                // We return here as we do not want to continue and submit the proposal
                // update if there was an issue updating the proposal milestone
                return;
            }
        }

        const response = await onSubmit(values, proposalFiles);

        if (!response.success) setSubmissionError(response.error);
    };

    const methods = useForm<EditProposalFormValues>({
        defaultValues: initialValues,
        resolver: yupResolver(validationSchema)
    });

    const { formState: { isDirty }} = methods;

    const handleFileDrop = async (file: File) => {
        const dataUrl = await convertFileToDataUrl(file);

        const proposalFile: ApiFile = {
            id: Math.random().toString(),
            url: dataUrl,
            name: file.name,
            totalBytes: file.size
        };

        setProposalFiles([...proposalFiles, proposalFile]);
    };

    const handleFileDelete = (fileId: string) => {
        const updatedProposalFiles = proposalFiles.filter(proposalFile => proposalFile.id !== fileId);
        setProposalFiles(updatedProposalFiles);
    };

    const handleTalentDefinedMilestoneChange = async (value: boolean) => {
        if (value) {
            await updateProposalMilestone({
                milestoneId: initialValues.milestones[0].id, dto: {
                    ...initialValues.milestones[0],
                    description: "First",
                }
            });
        }

        if (!value) {
            await updateProposalMilestone({ milestoneId: initialValues.milestones[0].id, dto: { ...initialValues.milestones[0], description: DEFAULT_PROPOSAL_MILESTONE } });
        }

        refetchProposal();
        setTalentDefinedMilestones(value);
    };

    return (
        <form className={clsx(formClassNames, "p-8 space-y-8")} noValidate onSubmit={methods.handleSubmit(handleSubmit)}>
            
            <>
                <div>
                    <FormToggle
                        label={`${gigTerminology} milestones`}
                        tooltip={talentProposalTooltips.milestones(gigTerminology, giggedClientTerminology)}
                        description={`Split this ${gigTerminology.toLowerCase()} into milestones`}
                        disabled={initialValues.milestones.length > 1}
                        onChange={handleTalentDefinedMilestoneChange}
                        checked={talentDefinedMilestones}
                    />
                    {initialValues.milestones.length > 1 && <span className="text-[0.75rem] italic">Note: This toggle is disabled if more than one milestone is added. If you wish to turn off milestones, remove some below first.</span>}
                </div>
                {talentDefinedMilestones &&
                    <ProposalMilestones
                        milestones={initialValues.milestones}
                        proposalId={proposalId}
                    />
                }
            </>
            
            <FormTextInput
                required
                tooltip={talentProposalTooltips.duration(gigTerminology)}
                id="edit-proposal-form-estimated-duration-days"
                label="Estimated duration (days)"
                placeholder="Enter the estimated duration in days"
                error={methods.formState.errors.estimatedDurationDays}
                defaultValue={0}
                type="number"
                min={1}
                step={1}
                {...methods.register("estimatedDurationDays")}
            />
            <FormTextAreaInput
                required
                tooltip={talentProposalTooltips.introduction(giggedClientTerminology)}
                id="edit-proposal-form-introduction"
                label="Introduction"
                placeholder="Enter an introduction"
                register={methods.register("introduction")}
                error={methods.formState.errors.introduction}
                defaultValue={""}
            />
            <FormTextAreaInput
                required
                tooltip={talentProposalTooltips.experience(giggedClientTerminology, gigTerminology, gigTerminologyPlural)}
                id="edit-proposal-form-relevant-experience"
                label="Relevant experience"
                placeholder="Enter your relevant experience"
                register={methods.register("relevantExperience")}
                error={methods.formState.errors.relevantExperience}
                defaultValue={""}
            />
            <FormTextInput
                id="edit-proposal-form-portfolio-url"
                tooltip={talentProposalTooltips.portfolio}
                label="Portfolio URL"
                placeholder="Enter your portfolio URL"
                error={methods.formState.errors.portfolioUrl}
                defaultValue={""}
                {...methods.register("portfolioUrl")}
            />
            <FileManager
                label="Relevant files"
                tooltip={talentProposalTooltips.relevantFiles(giggedClientTerminology)}
                inputId="proposal-files-upload-button"
                maxFileSizeKb={5000}
                existingFiles={proposalFiles}
                onFileDrop={handleFileDrop}
                onFileDelete={handleFileDelete}
            />

            <GeneralFormError error={submissionError} className="ml-8" />

            <div className="flex justify-end">
                <Button loading={isSubmitting} type="submit" disabled={!isDirty}>{submitButtonText}</Button>
            </div>
        </form>
    );
};

export default EditProposalForm;