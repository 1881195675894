import { Link } from "react-router-dom";
import { useAppPaths } from "../Routes";
import Card from "./Card";
import Typography from "./Typography";
import { ApplicationSummary, applicationStatuses } from "../models/app/application";
import ApplicationStatusChip from "./ApplicationStatusChip";
import LinkButton from "./LinkButton";
import ApplicationCoverLetterButton from "./ApplicationCoverLetterButton";

export type ApplicationSummaryCardProps = {
    application: ApplicationSummary
}
const ApplicationSummaryCard = ({
    application
}: ApplicationSummaryCardProps) => {
    const appPaths = useAppPaths();

    return (
        <Card key={application.id} className="space-y-6 md:space-y-0 flex flex-col md:flex-row justify-between items-center">
            <div className="space-y-6">
                <div className="space-y-2">
                    <Link to={appPaths.roles.findARoleDetails(application.roleId)}>
                        <Typography className="text-center md:text-left font-medium" variant="title-medium" component="p">{application.roleTitle}</Typography>
                    </Link>
                    <Typography className="text-center md:text-left" variant="title-small" component="p">{application.giggedClientName}</Typography>
                </div>
                <ApplicationStatusChip applicationStatusId={application.applicationStatusId} />
            </div>
            <div className="flex flex-col md:flex-row-reverse md:space-x-reverse md:space-x-4 space-y-4 md:space-y-0 items-center">
                <ApplicationCoverLetterButton application={application} />
                {application.applicationStatusId === applicationStatuses.draft && <LinkButton className="md:ml-auto" variant="tertiary" to={appPaths.applications.edit(application.id)}>Edit application</LinkButton>}
            </div>
        </Card>
    );
};

export default ApplicationSummaryCard;
