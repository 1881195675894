import { useOrganizationContext } from "../api/current-organization/organizationContext";
import { useTalentGigInvites } from "../api/talentGigInvites";
import Card from "../components/Card";
import LinkButton from "../components/LinkButton";
import Loader from "../components/Loader";
import PageContent from "../components/PageContent";
import Typography from "../components/Typography";
import { useIsLargeScreen } from "../hooks/screenSizes";
import { useAppPaths } from "../Routes";

const GigInvitesPage = () => {
    const appPaths = useAppPaths();
    const {
        gigInvites,
        isLoading,
    } = useTalentGigInvites();
    const isLargeScreen = useIsLargeScreen();

    const { gigTerminology } = useOrganizationContext();

    if (isLoading) return <Loader />;

    return (
        <PageContent>
            <Typography variant={isLargeScreen ? "display-large" : "display-small"} component="h1" gutterBottom>{gigTerminology} Invites</Typography>
            {gigInvites.length === 0 ? (
                <Card>
                    <Typography variant="title-large" component="p">None yet...</Typography>
                </Card>
            ) : (
                <div className="space-y-8">
                    {gigInvites.map(gigInvite => (
                        <Card key={gigInvite.gigId} className="flex flex-col md:flex-row text-center md:text-left items-center grow md:mr-4 rounded-b-none md:rounded-b-lg pb-2 md:pb-6">
                            <div className="md:mr-8">
                                <Typography variant="title-large" component="p" className="mb-4">{gigInvite.gigTitle}</Typography>
                                <p className="mb-2">
                                    <span className="font-bold">Sent on: </span>
                                    {gigInvite.dateSent.toLocaleDateString()}</p>
                                <p className="md:mr-8">
                                    <span className="font-bold">Message: </span>
                                    <span className="italic whitespace-pre-wrap">{gigInvite.message}</span>
                                </p>
                            </div>
                            <LinkButton className="block md:ml-auto" to={appPaths.gigs.findAGigDetails(gigInvite.gigId)} variant="tertiary">View {gigTerminology.toLowerCase()}</LinkButton>
                        </Card>
                    ))}
                </div>
            )
            }
        </PageContent>
    );
};

export default GigInvitesPage;