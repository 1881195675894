import { addDays } from "date-fns";
import { useState } from "react";
import { Controller } from "react-hook-form";
import { GigApiFetcherResponse } from "../../api/common/fetching";
import { useEducationLevels } from "../../api/educationLevels";
import Button from "../../components/Button";
import DialogBox, { useDialogBoxState } from "../../components/DialogBox";
import { dialogWidths } from "../../components/DialogBox/DialogBox";
import FormDateInput from "../../components/FormDateInput";
import FormDialogBox, { FormDialogBoxProps } from "../../components/FormDialogBox";
import FormDropdown, { FormDropdownOption } from "../../components/FormDropdown";
import FormTextAreaInput from "../../components/FormTextAreaInput";
import FormTextInput from "../../components/FormTextInput";
import { EducationFormValues, useEducationForm } from "./EducationFormValues";

export type EducationFormProps = Omit<FormDialogBoxProps, "title" | "children"> & {
    initialValues: EducationFormValues
    resetOnSubmit?: boolean
    isEdit?: boolean
    onSubmit: (values: EducationFormValues) => Promise<GigApiFetcherResponse<unknown>>
}

export const EducationFormDialog = ({
    initialValues,
    resetOnSubmit,
    isEdit,
    onSubmit,
    ...dialogProps
}: EducationFormProps) => {
    const {
        educationLevels,
        isLoading: isLoadingEducationLevels,
    } = useEducationLevels();

    const educationLevelOptions: FormDropdownOption<string>[] = educationLevels.map(_ => ({
        label: _.name,
        value: _.id
    }));

    const methods = useEducationForm(initialValues);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { isDirty } = methods.formState;

    const confirmCancelDialogState = useDialogBoxState();
    const { onClose } = dialogProps;

    const handleSubmit = async (values: EducationFormValues) => {
        setIsSubmitting(true);

        try {
            const result = await onSubmit(values);

            if (result.success) {
                methods.reset(resetOnSubmit ? initialValues : values);
                methods.clearErrors();
                onClose();
            }
        }
        finally {
            setIsSubmitting(false);
        }
    };

    const handleCancelButton = () => {
        if (isDirty) {
            confirmCancelDialogState.open();
            return;
        }
        methods.clearErrors();
        onClose();
    };

    const handleDiscardChanges = () => {
        confirmCancelDialogState.close();
        methods.reset(initialValues);
        methods.clearErrors();
        onClose();
    };

    return (
        <>
            <FormDialogBox
                {...dialogProps}
                isOpen={!confirmCancelDialogState.isOpen && dialogProps.isOpen}
                onClose={handleCancelButton}
                title="Education"
            >
                <form onSubmit={methods.handleSubmit(handleSubmit)} className="space-y-6">
                    <FormTextInput
                        required
                        id="education-form-course-title"
                        label="Course title"
                        placeholder="Enter course title"
                        error={methods.formState.errors.courseTitle}
                        {...methods.register("courseTitle")}
                    />
                    <div className="space-y-4">
                        <div className="space-y-4 md:flex md:space-y-0 md:space-x-6">
                            <FormTextInput
                                required
                                id="education-form-institution-name"
                                label="Institution name"
                                placeholder="Enter institution name"
                                error={methods.formState.errors.instituteName}
                                className="md:w-[50%]"
                                {...methods.register("instituteName")}
                            />
                            <Controller
                                name="educationLevelId"
                                control={methods.control}
                                render={({ field: { onChange, value } }) => (
                                    <FormDropdown
                                        required
                                        label="Education level"
                                        options={educationLevelOptions}
                                        error={methods.formState.errors.educationLevelId}
                                        onChange={onChange}
                                        value={value}
                                        disabled={isLoadingEducationLevels}
                                        className="md:w-[50%]"
                                    />
                                )}
                            />
                        </div>
                        <div className="space-y-4 md:flex md:space-y-0 md:space-x-6">
                            <Controller
                                name="startDate"
                                control={methods.control}
                                render={({ field: { onChange, value } }) => (
                                    <FormDateInput
                                        required
                                        id="education-form-start-date"
                                        label="Start date"
                                        value={value}
                                        onChange={onChange}
                                        error={methods.formState.errors.startDate}
                                        maxDate={new Date()}
                                        hideHelpText
                                    />
                                )}
                            />
                            <Controller
                                name="endDate"
                                control={methods.control}
                                render={({ field: { onChange, value } }) => (
                                    <FormDateInput
                                        id="education-form-end-date"
                                        label="End date (optional)"
                                        value={value}
                                        onChange={onChange}
                                        error={methods.formState.errors.endDate}
                                        minDate={addDays(methods.watch().startDate as Date, 1)}
                                        maxDate={methods.formState.isDirty ? new Date() : undefined}
                                        helperText="Leave empty if this is your current job."
                                    />
                                )}
                            />
                        </div>
                        <FormTextAreaInput
                            id="education-form-course-description"
                            label="Your course description"
                            placeholder="Write something here to describe the course."
                            error={methods.formState.errors.courseDescription}
                            register={methods.register("courseDescription")}
                            required
                        />
                    </div>
                    <div className="flex flex-col-reverse md:flex-row justify-end items-center md:space-x-4">
                        <Button className="w-full md:w-auto" type="button" disabled={isSubmitting} variant="tertiary" onClick={handleCancelButton}>Cancel</Button>
                        <Button className="w-full mb-4 md:mb-0 md:w-auto" type="submit" disabled={isEdit && !isDirty} loading={isSubmitting} variant="primary">Save education</Button>
                    </div>
                </form>
            </FormDialogBox>
            <DialogBox {...confirmCancelDialogState} title="Discard changes?" minWidth={dialogWidths["extra-small"]}>
                <div className="flex items-center sm:justify-between sm:space-x-4 flex-col-reverse sm:flex-row">
                    <Button className="w-full sm:w-fit mt-4 sm:mt-0"  type="button" variant="secondary" onClick={confirmCancelDialogState.close}>Back</Button>
                    <Button className="w-full sm:w-fit" type="button" onClick={handleDiscardChanges}>Discard</Button>
                </div>
            </DialogBox>
        </>
    );
};