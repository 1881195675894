import { AuthenticationResult, EventType, PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";

export const msalInstance = new PublicClientApplication(msalConfig);

const accounts = msalInstance.getAllAccounts();

if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event) => {
    const authenticationResult = event?.payload as AuthenticationResult | null;

    if (event.eventType === EventType.LOGIN_SUCCESS && authenticationResult?.account) {
        const account = authenticationResult.account;
        msalInstance.setActiveAccount(account);
    }
});

msalInstance.handleRedirectPromise();
