import { Disclosure } from "@headlessui/react";
import React from "react";
import { Link } from "react-router-dom";
import { UserFocus, UsersFour } from "phosphor-react";

import { useClientPersonalInformationContext } from "../../api/clientPersonalInformation";
import { useOrganizationContext } from "../../api/current-organization/organizationContext";
import { useTalentPersonalInformationContext } from "../../api/talentPersonalInformation";
import useCurrentUser from "../../auth/useCurrentUser";
import { useIsExtraLargeScreen, useIsSmallScreen } from "../../hooks/screenSizes";
import { useAppPaths } from "../../Routes";
import { buildTalentNavItems, buildClientNavItems } from "./SideMenuNavItems";
import SideNavMenuItem from "./SideNavMenuItem";
import { SignOutButton } from "./SignOutButton";
import Typography from "../Typography";

export type SideNavMenuProps = {
    close: () => void
}

const SideNavMenu = ({
    close,
}: SideNavMenuProps) => {
    const currentUser = useCurrentUser();
    const appPaths = useAppPaths();
    const { talentPersonalInformation } = useTalentPersonalInformationContext();
    const { clientPersonalInformation } = useClientPersonalInformationContext();
    const isSmallScreen = useIsSmallScreen();
    const isExtraLarge = useIsExtraLargeScreen();
    const { organizationConfig, gigTerminology, talentTerminologyPlural } = useOrganizationContext();

    const showFindTalentMenuItem =
        !organizationConfig?.isTalentProfilesPrivate &&
        currentUser.userRole === "client";

    const navItems = currentUser.userRole === "client"
        ? buildClientNavItems(currentUser.clientId)
        : buildTalentNavItems(currentUser.talentId);

    const wrapInContainer = (children: React.ReactNode) => (
        isExtraLarge ? (
            <div className="fixed xl:static inset-y-0 left-0 w-[288px] shrink-0 p-5 pr-12 bg-app-background z-40">
                {children}
            </div>
        ) : (
            <Disclosure.Panel className="fixed inset-y-0 left-0 w-[288px] p-5 pr-12 bg-white z-[1010] overflow-y-auto">
                {children}
            </Disclosure.Panel>
        )
    );

    const profileImage = () => {
        if (!clientPersonalInformation?.profileImageUrl && !talentPersonalInformation?.profileImageUrl) {
            return (
                <div className="h-32 w-32 pt-[4px] text-[54px] flex items-center justify-center rounded-full bg-surface mx-auto mb-8">
                    {currentUser.userGivenName?.length > 0 && currentUser.userGivenName[0].toUpperCase()}
                </div>
            );
        }
        return (
            <img
                src={currentUser.userRole === "talent" ? talentPersonalInformation?.profileImageUrl : clientPersonalInformation?.profileImageUrl}
                className="h-32 w-32 object-cover rounded-full mx-auto mb-8"
            />
        );
    };

    return wrapInContainer(
        <nav>
            {profileImage()}
            <div className="text-center mb-4 break-word">
                {currentUser.userRole === "client" ?
                    <>
                        <p className="text-title-medium font-bold">
                            {clientPersonalInformation?.name}
                        </p>
                        <span>User: {currentUser.userGivenName} {currentUser.userFamilyName}</span>
                    </>
                    :
                    <div className="flex flex-col space-y-2">
                        <p className="text-title-medium font-bold break-words">
                            {currentUser.userGivenName}
                        </p>
                        <div>
                            <div className="flex items-center text-primary-text px-2 py-2">
                                <UserFocus size={30} />
                                <Typography variant="body" className="text-left ml-4 w-3/4" component="p">{talentPersonalInformation?.currentRoleTitle}</Typography>
                            </div>
                            <div className="flex items-center text-primary-text px-2 py-2">
                                <UsersFour size={30} />
                                <Typography variant="body" className="text-left ml-4 w-3/4" component="p">{talentPersonalInformation?.currentGiggedClientName}</Typography>
                            </div>
                        </div>
                    </div>
                }
            </div>
            {isSmallScreen && !showFindTalentMenuItem && <div className="border-b border-surface mb-4"></div>}

            <div className="space-y-3">
                {!isSmallScreen && (
                    <div className="border-y border-surface ">
                        {showFindTalentMenuItem &&
                            <Disclosure.Button
                                as={Link}
                                className="block text-primary-text font-bold text-left px-3 py-3 w-full border-b border-surface"
                                to={appPaths.talents.findTalent}
                            >
                                Find {talentTerminologyPlural}
                            </Disclosure.Button>
                        }
                        <Disclosure.Button
                            as={Link}
                            className="block text-primary-text font-bold text-left px-3 py-3 w-full border-b border-surface"
                            to={appPaths.gigs.findAGig}
                        >
                            Find a {gigTerminology}
                        </Disclosure.Button>
                        <Disclosure.Button
                            as={Link}
                            className="block text-primary-text font-bold text-left px-3 py-3 w-full"
                            to={appPaths.roles.findARole}
                        >
                            Find a Role
                        </Disclosure.Button>
                    </div>
                )}

                {navItems.map((navItem) => (
                    <SideNavMenuItem
                        key={navItem.name}
                        item={navItem}
                        onLinkClick={() => !isExtraLarge && close()}
                    />
                ))}

                <SignOutButton />
            </div>
        </nav>
    );
};

export default SideNavMenu;
