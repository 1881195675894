import { useNavigate } from "react-router-dom";
import { GigApiFetcherResponse } from "../api/common/fetching";
import { useOrganizationContext } from "../api/current-organization/organizationContext";
import { useProposal } from "../api/proposal";
import Card, { CardDataField } from "../components/Card";
import EditProposalForm, { EditProposalFormValues } from "../components/EditProposalForm";
import MissingGigSkillsInfoBox from "../components/MissingGigSkillsInfoBox";
import PageContent from "../components/PageContent";
import Typography from "../components/Typography";
import { ApiFile } from "../models/api/file";
import { expectedDurationOptions } from "../models/app/gig";
import { useAppPaths, useProposalId } from "../Routes";
import findOptionLabel from "../utils/findOptionLabel";
import Loader from "../components/Loader";

const ProposalEditPage = () => {
    const appPaths = useAppPaths();
    const proposalId = useProposalId();
    const { proposal, updateProposal, isUpdatingProposal } = useProposal(proposalId);
    const { organizationConfig } = useOrganizationContext();
    const navigate = useNavigate();
    const { gigTerminology } = useOrganizationContext();

    if (!proposalId || !proposal || !organizationConfig) return <Loader />;

    const handleSubmit = async (
        values: EditProposalFormValues,
        proposalFiles: ApiFile[],
    ): Promise<GigApiFetcherResponse<void>> => {
        const fileDataUrisToAdd = proposalFiles
            .filter(file => !proposal.files.find(existingFile => existingFile.id === file.id))
            .map(file => file.url);

        const fileIdsToRemove = proposal.files
            .filter(existingFile => !proposalFiles.find(proposalFile => proposalFile.id === existingFile.id))
            .map(file => file.id);

        const response = await updateProposal({
            ...values,
            fileDataUrisToAdd,
            fileIdsToRemove
        });

        if (response.success) {
            navigate(appPaths.proposals.index);
        }

        return response;
    };

    return (
        <PageContent>
            <MissingGigSkillsInfoBox gigId={proposal.gig.id} />
            <Card className="mb-4 space-y-4 break-words">
                <Typography variant="title-large" component="h2">{proposal.gig.title}</Typography>
                <p className="italic whitespace-pre-wrap">{proposal.gig.description}</p>
                <CardDataField
                    label="Estimated duration"
                    iconName="Clock"
                >
                    {findOptionLabel(expectedDurationOptions, proposal.gig?.expectedDurationId)}
                </CardDataField>
            </Card>
            <Typography variant="display-medium" component="h1" gutterBottom>{`Edit ${gigTerminology} Proposal`}</Typography>
            <EditProposalForm
                proposalId={proposalId}
                isSubmitting={isUpdatingProposal}
                initialValues={{
                    ...proposal,
                }}
                onSubmit={handleSubmit}
                submitButtonText="Update"
                proposalFiles={proposal.files}
            />
        </PageContent>
    );
};

export default ProposalEditPage;