import clsx from "clsx";
import { Link } from "react-router-dom";
import { useAppPaths } from "../../Routes";
import { GigApiFetcherResponse } from "../../api/common/fetching";
import { useOrganizationContext } from "../../api/current-organization/organizationContext";
import BadgeList from "../../components/BadgeList";
import Card, { CardDataField, CardDataFieldLabel } from "../../components/Card";
import InviteButton from "../../components/InviteButton";
import { InviteFormDialogValues } from "../../components/InviteButton/InviteFormDialogValues";
import TalentSkill from "../../components/TalentSkill";
import UserAvatar from "../../components/UserAvatar";
import { Match } from "../../models/app/match";
import Button from "../../components/Button";

export type GigTalentMatchCardProps = {
    gigId: string
    match: Match
    onSubmit: (values: InviteFormDialogValues, talentId: string) => Promise<GigApiFetcherResponse<void>>
    isSubmitting: boolean
    canInvite: boolean
    showCurrentRole?: boolean
}

const GigTalentMatchCard = ({
    gigId,
    match,
    onSubmit,
    isSubmitting,
    canInvite,
    showCurrentRole,
}: GigTalentMatchCardProps) => {
    const matchValue = `${Math.round(match.score * 100)}%`;
    const appPaths = useAppPaths();
    const { giggedClientTerminology } = useOrganizationContext();
    const matchDisplayName = `${match?.talentFirstName} ${match?.talentLastName}`;
    const displayBadgeSection = match.badges.length > 0;

    return (
        <>
            <Card className={"max-w-full space-y-6 md:space-y-0 md:space-x-6 md:flex md:items-center flex-row"}>
                <div className="md:w-[230px] flex flex-col items-center space-y-2">
                    <UserAvatar name={matchDisplayName} userImageUrl={match.talentProfileImageThumbnailUrl} />
                    <Link to={appPaths.talents.findTalentDetails(match.talentId)} target="_blank"><Button className="w-fit" variant="tertiary">View profile</Button></Link>
                </div>
                <div className={clsx("grow space-y-3 text-left")}>
                    <div className="grid md:grid-cols-3 grid-cols-2 gap-3">
                        <CardDataField label={giggedClientTerminology}>
                            {match.talentCurrentGiggedClientName || "No current department"}
                        </CardDataField>
                        {showCurrentRole && (
                            <CardDataField label="Role">
                                {match.talentCurrentRoleTitle || "No current role"}
                            </CardDataField>
                        )}
                        <CardDataField label="Match level">
                            {matchValue}
                        </CardDataField>
                    </div>
                    <div>
                        <CardDataFieldLabel className="text-left">Relevant skills</CardDataFieldLabel>
                        <div className="mt-2 space-y-2 md:max-w-xl text-left">
                            {match.matchedSkills.map(skill => (
                                <TalentSkill key={skill.skillName} skinnyProgress {...skill} />
                            ))}
                        </div>
                    </div>
                    {displayBadgeSection &&
                        <div>
                            <CardDataFieldLabel className="text-left mb-2">Badges</CardDataFieldLabel>
                            <BadgeList badges={match.badges} keyPrefix={match.talentId.toString()} />
                        </div>
                    }
                </div>
                <div className="flex flex-col gap-4">
                    {canInvite &&
                        <InviteButton
                            talentId={match.talentId}
                            isInvited={match.isInvited}
                            id={gigId}
                            className="block"
                            onSubmit={onSubmit}
                            isLoading={isSubmitting}
                        />
                    }
                </div>
            </Card>
        </>
    );
};

export default GigTalentMatchCard;
