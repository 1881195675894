import Card, { CardDataFieldLabel } from "../../components/Card";
import PageContent from "../../components/PageContent";
import Typography from "../../components/Typography";
import { useAppPaths } from "../../Routes";
import { useIsLargeScreen } from "../../hooks/screenSizes";
import Loader from "../../components/Loader";
import { Link } from "react-router-dom";
import { useRoles } from "../../api/roles";
import { roleStatuses } from "../../models/app/role";
import { useOrganizationContext } from "../../api/current-organization/organizationContext";
import LinkButton from "../../components/LinkButton";

const RolesListActivePage = () => {
    const appPaths = useAppPaths();
    const { roles, isLoading } = useRoles({
        roleStatusIds: [roleStatuses.active],
        initialOrderBy: "CreatedAt",
        initialSortOrder: "desc"
    });
    const isLargeScreen = useIsLargeScreen();
    const { talentTerminologyPlural, talentTerminology } = useOrganizationContext();

    if (isLoading) return <Loader />;

    return (
        <PageContent>
            <Typography variant={isLargeScreen ? "display-large" : "display-small"} component="h1" gutterBottom>Active Roles</Typography>
            {roles.length === 0 ? (
                <Card>
                    <Typography variant="title-large" component="p">None yet...</Typography>
                </Card>
            ) : (
                <div className="space-y-8">
                    {roles.map(role => (
                        <div key={role.id} className="md:flex md:items-stretch break-words">
                            <Card className="flex flex-col md:flex-row text-center md:text-left items-center justify-between grow rounded-b-none md:rounded-b-lg pb-2 md:pb-6">
                                <div className="space-y-4 md:mr-8">
                                    <Typography variant="title-large" component="p" className="max-w-md">{role.title}</Typography>
                                    <p className="italic line-clamp-3 md:mr-8 pr-2">{role.description}</p>
                                    <CardDataFieldLabel>{role.allowMultipleHires ? "Multiple hires enabled" : "Single hire only"}</CardDataFieldLabel>
                                </div>
                                <LinkButton className="md:ml-4" variant="secondary" to={appPaths.roles.matches(role.id)}>View matches</LinkButton>
                            </Card>
                            {role.allowMultipleHires && (
                                <Link to={appPaths.roles.applications(role.id)} className="flex flex-col justify-center md:min-w-[15%] md:ml-4">
                                    <Card className="flex items-center h-full justify-center font-bold text-center rounded-t-none md:rounded-t-lg pt-2 md:pt-6">
                                        <div>
                                            <p>View</p>
                                            <p>Applications</p>
                                            <p className="text-primary-hover text-center font-extrabold">({role.submittedApplicationsCount})</p>
                                        </div>
                                    </Card>
                                </Link>
                            )}
                            <Link to={appPaths.roles.details(role.id)} className="flex flex-col justify-center md:min-w-[15%] md:ml-4">
                                <Card className="flex items-center h-full justify-center font-bold text-center rounded-t-none md:rounded-t-lg pt-2 md:pt-6">
                                    <div>
                                        <p className="text-primary-hover text-center font-extrabold text-title-medium">{role.hiredTalents.length}</p>
                                        <p className="text-center">{role.hiredTalents.length > 1 ? talentTerminologyPlural : talentTerminology}</p>
                                    </div>
                                </Card>
                            </Link>
                        </div>
                    ))}
                </div>
            )}
        </PageContent>
    );
};

export default RolesListActivePage;