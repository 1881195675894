import { useApplications } from "../../api/applications";
import { useRole } from "../../api/role";
import Loader from "../../components/Loader";
import PageContent from "../../components/PageContent";
import RoleDetailsCard from "../../components/RoleDetailsCard";
import Typography from "../../components/Typography";
import { useRoleId } from "../../Routes";
import RoleApplicationCard from "./RoleApplicationCard";

const RoleApplicationsListPage = () => {
    const roleId = useRoleId();
    const { role } = useRole(roleId);
    const { applications, isLoading } = useApplications({
        roleId,
        allTalents: true
    });

    if (isLoading || !role) return <Loader />;

    return (
        <PageContent>
            <Typography variant="display-medium" component="h1" gutterBottom>Role</Typography>
            <div className="mb-8">
                <RoleDetailsCard role={role} />
            </div>
            <Typography variant="display-small" component="h2" gutterBottom>Received applications</Typography>
            <div className="space-y-8">
                {applications.length === 0 && (
                    <div className="bg-surface p-8">
                        <Typography variant="title-large" component="p">None yet...</Typography>
                    </div>
                )}
                {applications.map(application => (
                    <RoleApplicationCard key={application.id} application={application} />
                ))}
            </div>
        </PageContent>
    );
};

export default RoleApplicationsListPage;