import clsx from "clsx";
import { useClientPersonalInformationContext } from "../../api/clientPersonalInformation";
import Card from "../../components/Card";
import Chip from "../../components/Chip";
import Typography from "../../components/Typography";
import { ProposalMilestoneSummary } from "../../models/app/proposal";
import CompleteMilestoneButton from "./CompleteMilestoneButton";
import ToolTip from "../../components/Tooltip";
import { clientMilestoneTooltips } from "../../tooltipsContent";

type ClientMilestonePaymentSectionProps = {
    reloadGig: () => void
    milestones: ProposalMilestoneSummary[]
    gigIsCancelled: boolean
}

const ClientMilestonePaymentSection = ({
    reloadGig,
    milestones,
    gigIsCancelled,
}: ClientMilestonePaymentSectionProps) => {

    const {
        isLoading: isLoadingClientPersonalInformation
    } = useClientPersonalInformationContext();

    if (isLoadingClientPersonalInformation) {
        return <p>Loading...</p>;
    }

    return (
        <div>
            <div className="flex flex-row items-center space-x-2 mb-4">
                <Typography variant="title-large" component="h2">Milestones</Typography>
                <ToolTip tooltipText={clientMilestoneTooltips.milestones} />
            </div>
            <div className="space-y-2">
                {milestones.map((milestone, index) => {
                    const isPrevMilestoneCompleted = index > 0 && milestones[index - 1].dateCompleted && !gigIsCancelled;
                    const isMilestoneCompleted = milestone.dateCompleted && !gigIsCancelled;
                    const isMilestoneOngoing = !milestone.dateCompleted && !gigIsCancelled;
                    const isIncompleteMilestone = !milestone.dateCompleted && !gigIsCancelled;
                    const milestoneStatus = isMilestoneCompleted ? "Completed" :
                        isMilestoneOngoing ? "Awaiting completion" :
                            isPrevMilestoneCompleted && isIncompleteMilestone ? "Awaiting payment" :
                                "Awaiting previous milestone completion";

                    return (
                        <Card className={clsx("sm:flex items-center space-y-4 sm:space-y-0", isMilestoneCompleted && "bg-primary-light", isMilestoneOngoing && "border-2 border-primary")}>
                            <div className="grow">
                                <Typography variant="title-small" component="h3" className={clsx((isIncompleteMilestone && !milestones[index - 1].dateCompleted) && "text-[grey]")}>{milestone.description}</Typography>
                                <Typography variant="title-small" component="h3" className="text-[grey] text-[1rem] mt-2">
                                    Status: {milestoneStatus}
                                </Typography>
                            </div>
                            <div className="space-x-2 shrink-0 sm:ml-4">
                                {milestone.dateCompleted && (
                                    <Chip size="large" label="Completed" variant="green" />
                                )}
                                {!milestone.dateCompleted && (
                                    <Chip size="large" label="Current" />
                                )}
                                {/* {!gigIsCancelled && (
                                    <Button disabled={!isPrevMilestoneCompleted} variant={isPrevMilestoneCompleted ? "secondary" : undefined}>Complete now</Button>
                                )} */}
                                {!milestone.dateCompleted && !gigIsCancelled && (
                                    <CompleteMilestoneButton reloadGig={reloadGig} milestoneId={milestone.id} />
                                )}
                            </div>
                        </Card>
                    );
                })}
            </div>
        </div>
    );
};

export default ClientMilestonePaymentSection;
