import { Link } from "react-router-dom";
import Card, { CardDataField, CardDataFieldLabel } from "../../components/Card";
import LinkButton from "../../components/LinkButton";
import PageContent from "../../components/PageContent";
import Typography from "../../components/Typography";
import { useAppPaths } from "../../Routes";
import { useIsLargeScreen } from "../../hooks/screenSizes";
import Loader from "../../components/Loader";
import { useRoles } from "../../api/roles";
import { roleAssignmentTypeOptions, roleAssignmentTypes, roleStatuses } from "../../models/app/role";

const RolesListOpenPage = () => {
    const appPaths = useAppPaths();
    const { roles, isLoading } = useRoles({
        roleStatusIds: [roleStatuses.open],
        initialOrderBy: "CreatedAt",
        initialSortOrder: "desc"
    });

    const isLargeScreen = useIsLargeScreen();

    if (isLoading) return <Loader />;

    return (
        <PageContent>
            <Typography variant={isLargeScreen ? "display-large" : "display-small"} component="h1" gutterBottom>Open Roles</Typography>
            {roles.length === 0 ? (
                <Card>
                    <Typography variant="title-large" component="p">None yet...</Typography>
                </Card>
            ) : (
                <div className="space-y-8">
                    {roles.map(role => (
                        <div key={role.id} className="flex flex-col md:flex-row items-stretch break-words">
                            <Card key={role.id} className="flex flex-col text-center md:text-left md:flex-row grow items-center rounded-b-none md:rounded-b-lg pb-2 md:pb-6">
                                <div className="space-y-4 md:mr-8">
                                    <Typography variant="title-large" component="h3" className="max-w-md">{role.title}</Typography>
                                    <p className="italic line-clamp-3 md:mr-8 w-full">{role.description}</p>
                                    <CardDataFieldLabel>{role.allowMultipleHires ? "Multiple hires enabled" : "Single hire only"}</CardDataFieldLabel>
                                    <CardDataFieldLabel>
                                        Assignment type: <span className="font-normal">{roleAssignmentTypeOptions[role?.roleAssignmentTypeId].label}</span>
                                    </CardDataFieldLabel>
                                </div>
                                <LinkButton className="md:ml-auto" variant="tertiary" to={appPaths.roles.edit(role.id)}>Edit</LinkButton>
                                <LinkButton className="md:ml-4" variant="secondary" to={appPaths.roles.matches(role.id)}>View matches</LinkButton>
                            </Card>
                            {role.roleAssignmentTypeId === roleAssignmentTypes.applications && (
                                <Link to={appPaths.roles.applications(role.id)} className="flex flex-col justify-center md:ml-4 md:min-w-[15%]">
                                    <Card className="flex items-center h-full justify-center font-bold text-center rounded-t-none md:rounded-t-lg pt-2 md:pt-6">
                                        <div>
                                            <p>View</p>
                                            <p>Applications</p>
                                            <p className="text-primary-hover text-center font-extrabold">({role.submittedApplicationsCount})</p>
                                        </div>
                                    </Card>
                                </Link>
                            )}
                        </div>
                    ))}
                </div>
            )}
        </PageContent>
    );
};

export default RolesListOpenPage;