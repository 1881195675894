import clsx from "clsx";
import { Icon } from "phosphor-react";
import { Link } from "react-router-dom";
import Typography from "../Typography";

export type SmallDashboardButtonVariant = "normal" | "inverted" | "card";

export type SmallDashboardButtonProps = {
    Icon: Icon
    name: string
    count?: React.ReactNode
    variant?: SmallDashboardButtonVariant
    href?: string
}

const containerVariantClasses = {
    normal: "bg-primary text-primary-bg-text",
    inverted: "bg-primary-text text-white",
    card: "bg-surface text-primary-bg-text",
};

const SmallDashboardButton = ({
    Icon,
    name,
    count,
    variant = "normal",
    href,
}: SmallDashboardButtonProps) => {
    const classes = clsx(
        "w-full flex items-center h-28 p-6 rounded-lg space-x-4",
        containerVariantClasses[variant],
    );

    const content = (
        <>
            <div className={clsx(
                "flex p-3 rounded-full opacity-80",
                variant === "normal" ? "bg-white text-primary-text" : "bg-primary text-primary-text",
            )}>
                <Icon size={32} weight="duotone" />
            </div>
            <div>
                <Typography
                    variant="body"
                    component="label"
                    className={clsx(
                        "block font-bold text-primary-bg-text",
                        variant === "inverted" && "",
                        href && "cursor-pointer",
                    )}
                >
                    {count ?? null}
                </Typography>
                <Typography
                    variant="body"
                    component="label"
                    className={clsx(
                        "block font-bold text-primary-bg-text",
                        href && "cursor-pointer",
                    )}
                >
                    {name}
                </Typography>
            </div>
        </>
    );

    return href ? (
        <Link to={href} className={classes + "child:cursor-pointer"}>
            {content}
        </Link>
    ) : (
        <div className={classes}>
            {content}
        </div>
    );
};

export default SmallDashboardButton;
