import { ApiApplicationDetails, ApiApplicationRoleSummary, ApiApplicationSummary, ApiCreateApplicationDto, ApiEditableApplicationFields, ApiUpdateApplicationDto } from "../api/application";

export type EditableApplicationFields = ApiEditableApplicationFields;

export type CreateApplicationDto = ApiCreateApplicationDto;

export type UpdateApplicationDto = ApiUpdateApplicationDto;

export type ApplicationSummary = ApiApplicationSummary;

export type ApplicationRoleSummary = ApiApplicationRoleSummary;

export type ApplicationDetails = ApiApplicationDetails;

export const applicationStatuses = {
    draft: 0,
    submitted: 1,
    hired: 2,
    unsuccessful: 3,
    cancelled: 4,
    completed: 5, 
    pending: 6
};

export const applicationStatusOptions = [
    { label: "Draft", value: 0 },
    { label: "Submitted", value: 1 },
    { label: "Hired", value: 2 },
    { label: "Unsuccessful", value: 3 },
    { label: "Cancelled", value: 4 },
    { label: "Completed", value: 5 },
    { label: "Pending", value: 6 }
];