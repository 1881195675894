import { useEffect } from "react";
import { useClientPersonalInformation } from "../../api/clientPersonalInformation";
import { useOrganizationContext } from "../../api/current-organization/organizationContext";
import Card from "../../components/Card";
import FormAccordion from "../../components/FormAccordion";
import InfoBox from "../../components/InfoBox";
import PageContent from "../../components/PageContent";
import AddedExtras from "../../components/AddedExtras";
import ClientPersonalDetailsForm from "./ClientPersonalDetailsForm";
import useCurrentUser from "../../auth/useCurrentUser";


const ClientProfileEditPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const {
        giggedClientTerminology,
    } = useOrganizationContext();
    const { clientPersonalInformation } = useClientPersonalInformation();
    const { userRole } = useCurrentUser();

    const profileSections = [
        {
            title: `${giggedClientTerminology} details`,
            content: (
                <ClientPersonalDetailsForm />
            )
        },
    ];

    if (userRole === "talent") {
        profileSections.push(
            {
                title: "External links",
                content: (
                    <AddedExtras />
                ),
            },
        );
    }

    return (
        <PageContent className="child:mx-0">
            {clientPersonalInformation && !clientPersonalInformation?.isProfileComplete && (
                <InfoBox className="mb-4" variant="info">
                    <p>Please complete these details in your profile before you go any further:
                        <ul className="list-disc ml-8">
                            <li>{giggedClientTerminology} name</li>
                            <li>{giggedClientTerminology} overview</li>
                            <li>Location</li>
                        </ul>
                    </p>
                </InfoBox>
            )}
            <Card className="rounded-none sm:rounded-lg">
                <FormAccordion
                    steps={profileSections}
                />
            </Card>
        </PageContent>
    );
};

export default ClientProfileEditPage;