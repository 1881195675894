import { Conversation, Participant, Client, Media } from "@twilio/conversations";
import TimeAgo from "javascript-time-ago";
import _ from "lodash";
import { getConversationParticipants } from "../api/conversations";
import { SetParticipantsType, SetUnreadMessagesType } from "../models/app/conversation";
import { ReduxMedia, ReduxMessage } from "../store/reducers/messageListReducer";
import { NotificationsType, NotificationVariantType } from "../store/reducers/notificationsReducer";
import { NOTIFICATION_TIMEOUT, UNEXPECTED_ERROR_MESSAGE } from "./constants";
import { UseCurrentUserReturn } from "../auth/useCurrentUser";

export const getTypingMessage = (typingData: string[]): string =>
    typingData.length > 1
        ? `${typingData.length + " participants are typing..."}`
        : `${typingData[0] + " is typing..."}`;

export const pushNotification = (
    messages: { variant: NotificationVariantType; message: string }[],
    func?: (messages: NotificationsType) => void
): void => {
    if (func) {
        func(
            messages.map(({ variant, message }) => ({
                variant,
                message,
                id: new Date().getTime(),
                dismissAfter: NOTIFICATION_TIMEOUT,
            }))
        );
    }
};

export const successNotification = ({
    message,
    addNotifications,
}: {
    message: string;
    addNotifications?: (messages: NotificationsType) => void;
}): void => {
    if (!addNotifications) {
        return;
    }
    pushNotification(
        [
            {
                message,
                variant: "success",
            },
        ],
        addNotifications
    );
};

export const unexpectedErrorNotification = (
    addNotifications?: (messages: NotificationsType) => void
): void => {
    if (!addNotifications) {
        return;
    }
    pushNotification(
        [
            {
                message: UNEXPECTED_ERROR_MESSAGE,
                variant: "error",
            },
        ],
        addNotifications
    );
};

export const handlePromiseRejection = async (
    func: () => void,
    addNotifications?: (messages: NotificationsType) => void
): Promise<void> => {
    if (!addNotifications) {
        return;
    }
    try {
        await func();
    } catch {
        unexpectedErrorNotification(addNotifications);
        return Promise.reject(UNEXPECTED_ERROR_MESSAGE);
    }
};

export const getLastMessage = (messages: ReduxMessage[], typingData: string[]) => {
    if (messages === undefined || messages === null) {
        return "Loading...";
    }
    if (typingData.length) {
        return getTypingMessage(typingData);
    }
    if (messages.length === 0) {
        return "No messages";
    }
    return messages[messages.length - 1].body || "Media message";
};

export const setUnreadMessagesCount = (
    currentConversationSid: string,
    conversationSid: string,
    unreadMessages: Record<string, number>,
    updateUnreadMessages: SetUnreadMessagesType
) => {
    if (currentConversationSid == conversationSid && unreadMessages[conversationSid] !== 0) {
        updateUnreadMessages(conversationSid, 0);
        return 0;
    }
    if (currentConversationSid == conversationSid) {
        return 0;
    }
    return unreadMessages[conversationSid];
};

export const measureWidth = (text: string): number => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    if (!context) {
        return 0;
    }
    context.font = "bold 14px Inter";
    return context.measureText(text).width;
};

export const truncateMiddle = (text: string, countWidth: number) => {
    const width = measureWidth(text);
    if (width > 288 - countWidth) {
        const textLength = text.length;
        const avgLetterSize = width / textLength;
        const nrOfLetters = (288 - countWidth) / avgLetterSize;
        const delEachSide = (textLength - nrOfLetters + 1) / 2;
        const endLeft = Math.floor(textLength / 2 - delEachSide);
        const startRight = Math.ceil(textLength / 2 + delEachSide);
        return text.substr(0, endLeft) + "..." + text.substr(startRight);
    }
    return text;
};

export const getLastMessageTime = (messages: ReduxMessage[]) => {
    const lastMessageDate = _.last(messages)?.dateCreated;
    if (!lastMessageDate) {
        return "";
    }
  
    return new TimeAgo("en-GB").format(lastMessageDate, "twitter-now");
};

export const calculateUnreadMessagesWidth = (count: number) => {
    if (count === 0 || !count) {
        return 0;
    }
    return measureWidth(count.toString()) + 32;
};

export const loadUnreadMessagesCount = async (
    conversation: Conversation,
    updateUnreadMessages: SetUnreadMessagesType
) => {
    let count = 0;
  
    try {
        count =
            (await conversation.getUnreadMessagesCount()) ??
            (await conversation.getMessagesCount());
    } catch (e) {
        console.error("getUnreadMessagesCount threw an error", e);
    }
  
    updateUnreadMessages(conversation.sid, count);
};

export const handleParticipantsUpdate = async (
    participant: Participant,
    updateParticipants: SetParticipantsType
) => {
    const result = await getConversationParticipants(participant.conversation);
    updateParticipants(result, participant.conversation.sid);
};

export const getSubscribedConversations = async (
    client: Client,
    currentUserDetails: UseCurrentUserReturn
): Promise<Conversation[]> => {
    let participantIdentity: string;
    let newName: string;

    if (currentUserDetails.talentId) {
        participantIdentity = "talent-" + currentUserDetails.talentId;
        newName = `${currentUserDetails.userGivenName} ${currentUserDetails.userFamilyName}`;
    } else if (currentUserDetails.clientId) {
        participantIdentity = "giggedclient-" + currentUserDetails.clientId;
        newName = currentUserDetails.clientName || currentUserDetails.clientName || "";
    }

    let subscribedConversations = await client.getSubscribedConversations();

    let conversations = subscribedConversations.items;

    await Promise.all(
        conversations.map(async conversation => {
            const participant = Array.from(conversation._participants.values()).find(p => p.identity === participantIdentity);

            if (participant) {
                const attributes = participant.attributes;

                if (attributes && typeof attributes === "object" && "name" in attributes && attributes.name !== newName) {
                    await participant.updateAttributes({ name: newName });
                }
            }
        })
    );

    while (subscribedConversations.hasNextPage) {
        subscribedConversations = await subscribedConversations.nextPage();
        conversations = [...conversations, ...subscribedConversations.items];

        await Promise.all(
            subscribedConversations.items.map(async conversation => {
                const participant = Array.from(conversation._participants.values()).find(p => p.identity === participantIdentity);

                if (participant) {
                    const attributes = participant.attributes;
                    
                    if (attributes && typeof attributes === "object" && "name" in attributes && attributes.name !== newName) {
                        await participant.updateAttributes({ name: newName });
                    }
                }
            })
        );
    }
  
    return conversations;
};

export const mediaMap = new Map<string, Media>();

export const getSdkMediaObject = (reduxMedia: ReduxMedia): Media =>
    getSdkObject(mediaMap, reduxMedia.sid, "media");

const capitalize = (string: string): string =>
    `${string[0].toUpperCase()}${string.substring(1)}`;


const getSdkObject = <T>(
    objectMap: Map<string, T>,
    sid: string,
    type: "conversation" | "message" | "media" | "participant"
): T => {
    const sdkObject = objectMap.get(sid);
  
    if (!sdkObject) {
        throw new Error(`${capitalize(type)} with SID ${sid} was not found.`);
    }
  
    return sdkObject;
};