import { ApiRoleInviteDto } from "../models/api/roleInvite";
import { RoleInviteDto } from "../models/app/roleInvite";
import { mapFromApiRoleInvite } from "../models/mappers/roleInvite";
import { useGiggedApiSWR } from "./common/giggedApiSWR";

export type UseTalentRoleInvitesReturn = {
    roleInvites: RoleInviteDto[]
    isLoading: boolean
}

export const useTalentRoleInvites = (): UseTalentRoleInvitesReturn => {
    const url = "api/me/role-invites";

    const apiCall = useGiggedApiSWR<ApiRoleInviteDto[]>(url);

    return {
        roleInvites: (apiCall.data || []).map(mapFromApiRoleInvite),
        isLoading: apiCall.isLoading,
    };
};
