import FindARoleContextProvider from "./FindARoleContextProvider";
import FindARolePageComponent from "./FindARolePageComponent";

const FindARolePage = () => {
    return (
        <FindARoleContextProvider>
            <FindARolePageComponent />
        </FindARoleContextProvider>
    );
};

export default FindARolePage;
