import { TalentSkillDto } from "../../models/app/talentSkill";
import Chip, { ChipContainer } from "../../components/Chip";

export type TalentSkillChipsProps = {
    skills: TalentSkillDto[],
    removeSkill: (skillId: string) => void,
    isRemovingSkill: boolean
}

const TalentSkillChips = ({
    skills,
    removeSkill,
    isRemovingSkill,
}: TalentSkillChipsProps) => {
    if (isRemovingSkill) return (
        <p className="mt-2">Loading...</p>
    );

    return (
        <ChipContainer className="pt-6">
            {skills
                .sort((a, b) => (new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()))
                .map(talentSkill => (
                    <Chip
                        key={`talent-skill-${talentSkill.skillId}`}
                        label={`${talentSkill.skillName} (${talentSkill.yearsExperience < 11 ? talentSkill.yearsExperience : "11+"} ${talentSkill.yearsExperience === 1 ? "Year" : "Years"})`}
                        onDelete={() => removeSkill(talentSkill.skillId)} />
                ))

            }
        </ChipContainer>
    );
};

export default TalentSkillChips;