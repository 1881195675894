import useCurrentUser from "../auth/useCurrentUser";
import { ApiRoleDetailsDto } from "../models/api/role";
import Card, { CardDataFieldLabel, CardDataField } from "./Card";
import Chip, { ChipContainer } from "./Chip";
import GigFilesAccordion from "./GigFilesAccordion/GigFilesAccordion";
import TitleUnderline from "./TitleUnderline";
import Typography from "./Typography";

export type RoleDetailsCardProps = {
    role: ApiRoleDetailsDto
}

const RoleDetailsCard = ({
    role,
}: RoleDetailsCardProps) => {
    const { userRole } = useCurrentUser();

    return (
        <Card key={role.id} className="space-y-6 md:flex break-words">
            <div className="space-y-4 w-full md:mr-8">
                <div className="space-y-4">
                    {userRole === "talent" && (
                        <Typography variant="title-large" component="p">{role.giggedClientName}</Typography>
                    )}
                    <Typography variant="display-small" className="max-w-xl" component="h1">{role.title}</Typography>
                    <div className="flex flex-row space-x-2"><CardDataFieldLabel>Role category: </CardDataFieldLabel><Typography variant="body" component="p">{role.roleCategoryName}</Typography></div>
                    <Typography variant="body" component="p" className="italic whitespace-pre-wrap">{role.description}</Typography>
                    <TitleUnderline fullWidth />
                </div>
                <div>
                    <CardDataFieldLabel>Skills required</CardDataFieldLabel>
                    <ChipContainer className="mt-2 mr-4">
                        {role.skills.map(skill => (
                            <Chip key={skill.name} variant="secondary" label={skill.name} />
                        ))}
                    </ChipContainer>
                </div>
                {/* {
                    gig.files && gig.files.length > 0 && (
                        <GigFilesAccordion files={gig.files}/>
                    )
                } */}
            </div>
            {/* <div className="md:ml-auto md:self-start md:shrink-0 space-y-8">
                <div className="child:p-4 child:border-surface border border-surface rounded-lg">
                    <CardDataField className="border-b" label="Initial stage">{gig.initialStageLabel}</CardDataField>
                    <CardDataField className="border-b" label="Expected duration">{gig.expectedDurationLabel}</CardDataField>
                </div>
            </div> */}
        </Card>
    );
};

export default RoleDetailsCard;
