import { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import clsx from "clsx";
import { CaretDown, CaretUp } from "phosphor-react";
import FormInputLabel from "./FormInputLabel";
import { FieldError } from "react-hook-form";
import FormInputMessage from "./FormInputError";

export type FormDropdownOption<TValue extends string | number> = {
    label: string
    value: TValue
}

export type FormDropdownProps<TOption extends FormDropdownOption<string | number>> = {
    options: TOption[]
    label?: string
    pleaseSelectText?: string
    value: TOption["value"]
    onChange: (value: TOption["value"], option: TOption) => void
    error?: FieldError | string
    required?: boolean
    disabled?: boolean
    className?: string
    tooltip?: string
    isLoadingOptions?: boolean
}

const FormDropdown = <TOption extends FormDropdownOption<string | number>>({
    label,
    pleaseSelectText = "Select an option",
    options,
    value,
    onChange,
    error,
    required,
    className,
    tooltip,
    disabled,
    isLoadingOptions,
}: FormDropdownProps<TOption>) => {
    const _disabled = disabled || isLoadingOptions;

    // Below is not ideal but works for now. Will need to improve if we want to have none selected state when TValue is string.
    const valueIsNumber = typeof value === "number";
    const selected = valueIsNumber
        ? (value as number) >= 0
            ? options.find(option => option.value === value)
            : undefined
        : options.find(option => option.value === value);
        
    if (!isLoadingOptions && value && value != -1 && !selected) throw Error(`Couldn't find option with value ${value}.`);

    const handleChange = (value: TOption["value"]) => {
        const option = options.find(_ => _.value === value);

        if (!option) throw new Error(`Expected option with value ${value}.`);

        onChange(value, option);
    };

    return (
        <div className={className}>
            {label && (
                <FormInputLabel className="whitespace-nowrap" required={required} tooltipText={tooltip}>{label}</FormInputLabel>
            )}
            <Listbox value={value} onChange={handleChange} disabled={_disabled}>
                {({ open }) => (
                    <div className="relative mt-1">
                        <Listbox.Button className={clsx(
                            "border relative w-full cursor-default rounded-xl bg-white p-4 pr-10 text-left sm:text-sm focus:outline-none focus:ring-2 focus:ring-primary-text",
                            open && "border-b rounded-b-none z-50 ring-2 ring-primary-text",
                            error && "outline-error border-error focus:ring-error ring-error focus:outline outline outline-2 pr-10",
                            open && error && "ring-0",
                            _disabled && "border-disabled"
                        )}>
                            <span className={clsx(
                                "block truncate",
                                !selected && "text-placeholder-text",
                                _disabled && "text-disabled-text"
                            )}>
                                {isLoadingOptions ?
                                    "Loading..." :
                                    selected ? selected.label : pleaseSelectText}
                            </span>
                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-4">
                                {open ?
                                    <CaretUp
                                        className={clsx(
                                            "h-5 w-5 text-primary-text",
                                            _disabled && "text-disabled-text"
                                        )}
                                        aria-hidden
                                    /> :
                                    <CaretDown
                                        className={clsx(
                                            "h-5 w-5 text-primary-text",
                                            _disabled && "text-disabled-text"
                                        )}
                                        aria-hidden
                                    />
                                }
                            </span>
                        </Listbox.Button>
                        <Transition
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Listbox.Options
                                className={clsx(
                                    "drop-shadow-lg rounded-b-lg absolute mt-1 max-h-60 w-full overflow-auto",
                                    "scrollbar-thin scrollbar-thumb-scrollbar bg-white py-1 text-base",
                                    "focus:outline-none sm:text-sm z-50",
                                    open && "border-t"
                                )}
                            >
                                {options.map((option, index) => (
                                    <Listbox.Option
                                        key={index}
                                        className={({ active }) => clsx(
                                            "relative cursor-default select-none py-2 px-4",
                                            active && "bg-surface-accent",
                                        )}
                                        value={option.value}
                                    >
                                        {({ selected }) => (
                                            <>
                                                <span
                                                    className={clsx(
                                                        "block truncate",
                                                        selected ? "font-medium" : "font-normal"
                                                    )}
                                                >
                                                    {option.label}
                                                </span>
                                            </>
                                        )}
                                    </Listbox.Option>
                                ))}
                            </Listbox.Options>
                        </Transition>
                    </div>
                )}
            </Listbox>
            <FormInputMessage error={error} />
        </div>
    );
};

export default FormDropdown;
