import { useEffect, useState } from "react";

import { useOrganizationContext } from "../../api/current-organization/organizationContext";
import { useGig } from "../../api/gig";
import Button from "../../components/Button";
import GigDetailsCard from "../../components/GigDetailsCard";
import PageContent from "../../components/PageContent";
import Typography from "../../components/Typography";
import { useAppPaths, useGigId } from "../../Routes";
import Loader from "../../components/Loader";
import MatchesInfo from "./MatchesInfo";
import LinkButton from "../../components/LinkButton";
import TitleUnderline from "../../components/TitleUnderline";
import { CircularProgress } from "@mui/material";
import { successToast } from "../../toast";
import { InviteFormDialogValues } from "../../components/InviteButton/InviteFormDialogValues";
import GigMatchesList from "./GigMatchesList";

const GigsMatchesPage = () => {
    const appPaths = useAppPaths();
    const gigId = useGigId();
    const { gig, isLoading, regenerateMatches, isRegeneratingMatches, reload, inviteToGig, isInvitingToGig } = useGig(gigId);
    const [forceReload, setForceReload] = useState(false);
    const { talentTerminology, talentTerminologyPlural, gigTerminology } = useOrganizationContext();

    useEffect(() => {
        const interval = setInterval(() => {
            setForceReload(value => !value);
        }, 5000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (isLoading) return;
        if (gig?.isFetchingMatches) reload();
    }, [isLoading, forceReload]);

    const handleConfirmInvite = async (values: InviteFormDialogValues, talentId: string) => {
        const response = await inviteToGig({
            message: values.message,
            talentId,
        });

        if (response.success) successToast(`${talentTerminology} invited successfully!`);

        return response;

    };

    if (isLoading || !gig) return <Loader />;

    return (
        <PageContent>
            <Typography variant="display-medium" component="h1" gutterBottom>{gigTerminology}</Typography>
            <div className="mb-8">
                <GigDetailsCard gig={gig} />
            </div>
            <div className="sm:flex items-center mb-4 space-y-4 sm:space-y-0">
                <Typography variant="display-small" component="h2">Matches</Typography>
                <Button className="ml-auto" variant="secondary" onClick={regenerateMatches} disabled={isRegeneratingMatches || gig.isFetchingMatches}>Refresh matches</Button>
            </div>
            {(isRegeneratingMatches || gig.isFetchingMatches) ? (
                <div className="mt-6 sm:mt-8 text-center">
                    <CircularProgress className="mx-auto block" />
                    <Typography className="mt-4 text-center" variant="title-medium" component="p">Your gig matches are now being generated</Typography>
                    <Typography className="mt-4 text-center" variant="body" component="p">This usually takes less than 30 seconds to complete.</Typography>
                </div>
            ) : (
                <>
                    {gig.matches.length !== 0 && <MatchesInfo />}
                    <div className="flex flex-col space-y-8 my-8">
                        <GigMatchesList
                            id={gig.id}
                            matches={gig.matches}
                            externalMatches={gig.externalMatches?.matches || []}
                            onSubmit={handleConfirmInvite}
                            isSubmitting={isInvitingToGig}
                        />
                    </div>
                    <div className="flex justify-center py-6">
                        <TitleUnderline />
                    </div>
                    <div className="text-center">
                        <Typography variant="body" component="p" className="mb-6">Want to discover more top {talentTerminologyPlural.toLowerCase()}? Check out our {talentTerminology.toLowerCase()} pool by clicking below!</Typography>
                        <div className="flex justify-center">
                            <LinkButton to={appPaths.talents.findTalent}>Search here</LinkButton>
                        </div>
                    </div>
                </>
            )}
        </PageContent>
    );
};

export default GigsMatchesPage;