import { useNavigate } from "react-router-dom";
import Card from "../components/Card";
import PageContent from "../components/PageContent";
import Typography from "../components/Typography";
import { useAppPaths, useApplicationId } from "../Routes";
import { UpdateApplicationDto, applicationStatuses } from "../models/app/application";
import { successToast } from "../toast";
import { useApplication } from "../api/application";
import EditApplicationForm from "../components/EditApplication/EditApplicationForm";
import Loader from "../components/Loader";

const ApplicationEditPage = () => {
    const appPaths = useAppPaths();
    const navigate = useNavigate();
    const applicationId = useApplicationId();
    const { application, updateApplication, isUpdatingApplication } = useApplication(applicationId);

    const handleSubmitForm = async (values: UpdateApplicationDto) => {
        const response = await updateApplication(values);

        if (response.success) {
            successToast(
                `Application ${values.applicationStatusId === applicationStatuses.draft ?
                    "draft saved." :
                    "submitted successfully!"
                }`
            );
            navigate(appPaths.applications.index);
        }

        return response;
    };

    if (!application) return <Loader />;

    return (
        <PageContent>
            <Card className="mb-4 space-y-4 break-words">
                <Typography variant="title-small" component="h2">{application.role.giggedClientName}</Typography>
                <Typography variant="title-large" component="h2">{application.role.title}</Typography>
                <p className="italic whitespace-pre-wrap">{application.role.description}</p>
            </Card>
            <Typography variant="display-medium" component="h1" gutterBottom>Role Application</Typography>

            <EditApplicationForm
                isSubmitting={isUpdatingApplication}
                onSubmit={handleSubmitForm}
                submitButtonText="Submit application"
                application={application}
            />
        </PageContent>
    );
};

export default ApplicationEditPage;