import Card from "../../components/Card";
import Typography from "../../components/Typography";
import GigSummaryCard from "../../components/GigSummaryCard";
import { useAppPaths } from "../../Routes";
import { GigSummary } from "../../models/app/gig";

export type GigsListProps = {
    gigs: GigSummary[]
    isLoading: boolean
};

const GigsList = ({ gigs, isLoading }: GigsListProps) => {
    const appPaths = useAppPaths();

    if (isLoading) return <p>Loading...</p>;

    if (gigs.length === 0) {
        return (
            <Card className="w-full self-start">
                <Typography variant="title-large" component="p">No results</Typography>
            </Card>
        );
    }

    return (
        <div className="space-y-8 w-full overflow-hidden">
            {gigs.map(gig => (
                <GigSummaryCard key={gig.id} gig={gig} viewGigLink={appPaths.gigs.findAGigDetails(gig.id)} />
            ))}
        </div>
    );
};

export default GigsList;
