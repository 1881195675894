import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { useClientPersonalInformationContext } from "../../api/clientPersonalInformation";
import { useTalentPersonalInformationContext } from "../../api/talentPersonalInformation";
import useCurrentUser from "../../auth/useCurrentUser";
import { SignOutButton } from "./SignOutButton";

const UserMenu = () => {
    const currentUser = useCurrentUser();
    const { talentPersonalInformation } = useTalentPersonalInformationContext();
    const { clientPersonalInformation } = useClientPersonalInformationContext();

    const profileImage = () => {
        if (!talentPersonalInformation?.profileImageUrl && !clientPersonalInformation?.profileImageUrl) {
            return (
                <div className="h-8 w-8 flex items-center justify-center rounded-full bg-surface">
                    {currentUser.userGivenName?.length > 0 && currentUser.userGivenName[0].toUpperCase()}
                </div>
            );
        }
        return (
            <img
                src={currentUser.userRole === "talent" ? talentPersonalInformation?.profileImageUrl : clientPersonalInformation?.profileImageUrl}
                className="h-8 w-8 object-cover rounded-full border-[1px] border-transparent"
            />
        );
    };

    return (
        <Menu as="div" className="relative">
            <div>
                <Menu.Button className="bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white border-2 border-primary">
                    <span className="sr-only">Open user menu</span>
                    {profileImage()}
                </Menu.Button>
            </div>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-lg shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
                    <Menu.Item>
                        <SignOutButton />
                    </Menu.Item>
                </Menu.Items>
            </Transition>
        </Menu>
    );
};

export default UserMenu;