import { JSONObject } from "@twilio/conversations";
import { useOrganizationContext } from "../../../api/current-organization/organizationContext";
import useCurrentUser from "../../../auth/useCurrentUser";
import { messageBannerTypes } from "../../../models/app/conversation";
import { ReduxParticipant } from "../../../store/reducers/participantsReducer";
import MessageTypeDefault from "./MessageTypes/MessageTypeDefault";
import MessageTypeSpecial from "./MessageTypes/MessageTypeSpecial";
import { MessageStatuses } from "../../../api/conversations";
import { useEffect, useState } from "react";
import { ReduxMedia } from "../../../store/reducers/messageListReducer";
import { getButtonHref, getButtonText } from "../../../utils/conversations";

export type MessageType = keyof typeof messageBannerTypes;

export type MessageListItemProps = {
    participant: ReduxParticipant | undefined;
    messageTime: string;
    text: string;
    messageType?: string;
    messageGigId?: string;
    messageProposalId?: string
    messageRoleId?: string;
    messageApplicationId?: string
    bannerText?: string
    getStatus: Promise<MessageStatuses>;
    messageMedia: ReduxMedia[] | null;
    attachments?: Record<string, Blob>;
    onDownload: () => Promise<Error | undefined>
    onOpen: (mediaSid: string, file: ReduxMedia) => void
}

const formatText = (text: string): JSX.Element[] => {
    const formattedText = text.split("\n").map((str, index) => <p key={index}>{str}</p>);
    return formattedText;
};

const MessageListItem = ({
    participant,
    messageTime,
    text,
    messageType,
    messageGigId,
    messageProposalId,
    messageRoleId,
    messageApplicationId,
    getStatus,
    messageMedia,
    onDownload,
    onOpen
}: MessageListItemProps) => {
    const { talentId, clientId, userRole } = useCurrentUser();
    const { gigTerminology } = useOrganizationContext();
    const [messageStatus, setMessageStatus] = useState<MessageStatuses>({});

    const isMyMessage = (userRole === "client" && participant?.identity === `giggedclient-${clientId}`) ||
        (userRole === "talent" && participant?.identity === `talent-${talentId}`);

    const participantName = participant?.attributes
        ? (participant?.attributes as JSONObject)["name"]?.toString()
        : "";

    const bannerText = messageBannerTypes[messageType as MessageType]?.replace("Gig", gigTerminology);

    useEffect(() => {
        getStatus.then((value) => setMessageStatus(value));
    }, [getStatus]);

    const buttonHref = getButtonHref(userRole, messageGigId, messageRoleId, messageProposalId, messageApplicationId)[messageType as MessageType];

    if (messageType) {
        return (
            <MessageTypeSpecial
                bannerText={bannerText}
                buttonText={getButtonText(gigTerminology)[messageType as MessageType] as string}
                messageTime={messageTime}
                messageContent={formatText(text)}
                participantName={participantName ?? ""}
                isMyMessage={isMyMessage}
                buttonHref={buttonHref}
                messageStatus={messageStatus}
                messageMedia={messageMedia}
                onDownload={onDownload}
                onOpen={onOpen}
            />
        );
    }

    return (
        <MessageTypeDefault
            messageTime={messageTime}
            messageContent={formatText(text)}
            participantName={participantName ?? ""}
            isMyMessage={isMyMessage}
            messageStatus={messageStatus}
            messageMedia={messageMedia}
            onDownload={onDownload}
            onOpen={onOpen}
        />
    );
};

export default MessageListItem;