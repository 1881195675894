import { Controller } from "react-hook-form";
import Button from "../../components/Button";
import DialogBox, { useDialogBoxState } from "../../components/DialogBox";
import FormDropdown, { FormDropdownOption } from "../../components/FormDropdown";
import FormTextAreaInput from "../../components/FormTextAreaInput";
import { useTalent } from "../../api/talent";
import { successToast } from "../../toast";
import { useOrganizationContext } from "../../api/current-organization/organizationContext";
import ApiError from "../../api/common/apiError";
import GeneralFormError from "../../components/GeneralFormError";
import { useState } from "react";
import { dialogWidths } from "../../components/DialogBox/DialogBox";
import { useInviteToRole } from "../../api/role";
import { useFindARoleContext } from "../FindARole/findARoleContext";
import { InviteTalentToRoleFormDialogValues, useInviteToRoleForm } from "./InviteToRoleFormValues";
import { useRoles } from "../../api/roles";
import { roleStatuses } from "../../models/app/role";

export type InviteToRoleButtonProps = {
    talentId: string
    className?: string
}
const InviteToRoleButton = ({
    talentId,
    className,
}: InviteToRoleButtonProps) => {
    const { talent } = useTalent(talentId);
    const [inviteToRole, isInvitingToRole] = useInviteToRole();

    const { roles: postedRoles, refetchRoles, isLoading: isLoadingRoles } = useRoles();
    const filteredRoles = postedRoles?.filter(role =>
        role.roleStatusId === roleStatuses.open ||
        (role.roleStatusId === roleStatuses.active && role.allowMultipleHires)
    );

    const dialogState = useDialogBoxState();
    const methods = useInviteToRoleForm();

    const [submissionError, setSubmissionError] = useState<ApiError | undefined>(undefined);

    const roleOptions: FormDropdownOption<string>[] = filteredRoles
        .filter(_ => !_.invitedTalentIds.includes(talentId))
        .map(role => ({
            value: role.id,
            label: role.title,
        }));

    const handleConfirmInvite = async (values: InviteTalentToRoleFormDialogValues) => {
        const response = await inviteToRole({
            roleId: methods.getValues().roleId as string,
            dto: {
                message: values.message,
                talentId,
            }
        });

        if (response.success) {
            successToast("Role invitation sent!");
            dialogState.close();
            methods.reset();
            refetchRoles();
            return;
        }

        setSubmissionError(response.error);
    };

    return (
        <>
            <Button dark variant="secondary" onClick={dialogState.onOpen} className={className}>
                Invite to role
            </Button>
            <form onSubmit={methods.handleSubmit(handleConfirmInvite)}>
                <DialogBox
                    {...dialogState}
                    title={"Invite to role"}
                    minWidth={dialogWidths.medium}

                >
                    {roleOptions.length === 0 ? (
                        <p className="max-w-sm">{`You have no roles that ${talent?.fullName} can be invited to.`}</p>
                    ) : (
                        <>
                            <Controller
                                name="roleId"
                                control={methods.control}
                                render={({ field: { onChange, value } }) => (
                                    <FormDropdown
                                        disabled={isLoadingRoles || isInvitingToRole}
                                        pleaseSelectText={"Select a role"}
                                        options={roleOptions}
                                        error={methods.formState.errors.roleId}
                                        onChange={onChange}
                                        value={value || ""}
                                    />
                                )}
                            />
                            <FormTextAreaInput
                                id="invite-to-role-message"
                                rows={7}
                                placeholder="Add a message to go with your invite"
                                register={methods.register("message")}
                                error={methods.formState.errors.message}
                                disabled={methods.formState.isSubmitting}
                            />
                            <GeneralFormError error={submissionError} />
                            <div className="mt-4">
                                <Button className="w-full" type="submit" loading={isInvitingToRole || isLoadingRoles}>Send invite</Button>
                            </div>
                        </>
                    )}
                </DialogBox>
            </form>
        </>
    );
};

export default InviteToRoleButton;