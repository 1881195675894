import { addDays } from "date-fns";
import { useState } from "react";
import ApiError from "../../api/common/apiError";
import { GigApiFetcherResponse } from "../../api/common/fetching";
import useBeforeUnload from "../../hooks/useWarnBeforeUnload";
import { CreateGigDto } from "../../models/app/gig";
import { GigFormValues } from "../GigForm";
import TabStepper from "../TabStepper";
import Step1Title from "./Step1Title";
import Step2Duration from "./Step2Duration";
import Step3StartDate from "./Step3StartDate";
import Step4Deadline from "./Step4Deadline";
import Step5Description from "./Step5Description";
import Step6CurrentStage from "./Step6CurrentStage";
import Step7Skills from "./Step7Skills";
import Step8Summary from "./Step8Summary";
import StepRemote from "./StepRemote";


export type GigFormProps = {
    onSaveAsDraft: (values: CreateGigDto) => Promise<GigApiFetcherResponse<unknown>>,
    onSaveAndPost: (values: CreateGigDto) => Promise<GigApiFetcherResponse<unknown>>,
}

const CreateGigForm = ({
    onSaveAsDraft,
    onSaveAndPost,
}: GigFormProps) => {
    useBeforeUnload();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submissionError, setSubmissionError] = useState<ApiError | undefined>(undefined);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [gigFormData, setGigFormData] = useState<GigFormValues>({
        title: "",
        expectedDurationId: -1,
        startDate: null,
        deadlineDate: null,
        description: "",
        initialStageId: -1,
        gigStatusId: 1,
        skills: [],
        isComplianceCheckRequired: false,
        isGigFilesPublic: false,
        isCompletedRemotely: ""
    });

    const updateAction = (data: Partial<GigFormValues>) => {
        setGigFormData(oldValue => ({
            ...oldValue,
            ...data
        }));
    };

    const handleBackClick = (data: Partial<GigFormValues>) => {
        updateAction(data);
        setSelectedIndex(value => value - 1);
        window.scrollTo(0, 0);
    };

    const handleStepSubmit = (data: Partial<GigFormValues>) => {
        updateAction(data);
        setSelectedIndex(value => value + 1);
        window.scrollTo(0, 0);
    };

    const handleSubmit = async (data: GigFormValues, callback: (values: CreateGigDto) => Promise<GigApiFetcherResponse<unknown>>) => {
        setIsSubmitting(true);
        updateAction(data);

        if (data.startDate === null) throw Error("Expected start date not to be null.");

        const response = await callback({
            ...data,
            startDate: data.startDate,
            skillIds: data.skills.map(skill => skill.id),
            isCompletedRemotely: JSON.parse(data.isCompletedRemotely)
        });
        setIsSubmitting(false);

        if (!response.success) {
            setSubmissionError(response.error);
        }
    };

    const handleSaveAsDraft = async (data: GigFormValues) => {
        await handleSubmit(data, onSaveAsDraft);
    };

    const handleSaveAndPost = async (data: GigFormValues) => {
        await handleSubmit(data, onSaveAndPost);
    };

    const pages = [
        {
            title: "Title",
            content: <Step1Title onSubmit={handleStepSubmit} defaultValues={gigFormData} />
        },
        {
            title: "Duration",
            content: <Step2Duration onSubmit={handleStepSubmit} onBackClick={handleBackClick} defaultValues={gigFormData} />
        },
        {
            title: "Start Date",
            content: <Step3StartDate onSubmit={handleStepSubmit} onBackClick={handleBackClick} defaultValues={gigFormData} />
        },
        {
            title: "Deadline",
            content: <Step4Deadline onSubmit={handleStepSubmit} onBackClick={handleBackClick} defaultValues={gigFormData} minDate={addDays(gigFormData.startDate as Date, 1)} />
        },
        {
            title: "Description",
            content: <Step5Description onSubmit={handleStepSubmit} onBackClick={handleBackClick} defaultValues={gigFormData} title={gigFormData.title} />
        },
        {
            title: "Stage",
            content: <Step6CurrentStage onSubmit={handleStepSubmit} onBackClick={handleBackClick} defaultValues={gigFormData} />
        },
        {
            title: "Skills",
            content: <Step7Skills onSubmit={handleStepSubmit} onBackClick={handleBackClick} defaultValues={gigFormData} />
        },
    ];

    pages.push(
        {
            title: "Remote",
            content: <StepRemote onSubmit={handleStepSubmit} onBackClick={handleBackClick} defaultValues={gigFormData} />
        },
        {
            title: "Summary",
            content: <Step8Summary onSaveAsDraft={handleSaveAsDraft} onSaveAndPost={handleSaveAndPost} isSubmitting={isSubmitting} defaultValues={gigFormData} submissionError={submissionError} />
        }
    );

    return (
        <>
            <TabStepper
                selectedIndex={selectedIndex}
                setSelectedIndex={setSelectedIndex}
                pages={pages}
            />
            <p className="text-sm mt-6">
                Need help? Click the icon on the bottom right of your screen and we will be right with you.
            </p>
        </>
    );
};

export default CreateGigForm;