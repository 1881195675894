import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

export type InviteTalentToRoleFormDialogValues = {
    roleId: string
    message: string
};

export const inviteToRoleFormValidationSchemaFields = yup.object({
    roleId: yup.string().required("You must select a role"),
    message: yup.string().required("Message is required."),
});

export const useInviteToRoleForm = () => {
    return useForm<InviteTalentToRoleFormDialogValues>({
        resolver: yupResolver(inviteToRoleFormValidationSchemaFields)
    });
};