import useCurrentUser from "../../auth/useCurrentUser";
import LinkButton from "../../components/LinkButton";
import { ApiRoleSummaryDto } from "../../models/api/role";
import { applicationStatuses } from "../../models/app/application";
import { useAppPaths } from "../../Routes";
import { getApplicationStatusButtonText } from "../../utils/getApplicationStatusText";

export type SubmitApplicationButtonProps = {
    role: ApiRoleSummaryDto
}

const SubmitApplicationButton = ({
    role,
} : SubmitApplicationButtonProps) => {
    const appPaths = useAppPaths();
    const { userRole } = useCurrentUser();
    
    const canApplyToRole = !role.submittedApplicationId && role.submittedApplicationStatusId != applicationStatuses.draft || (
        role.submittedApplicationStatusId && [
            applicationStatuses.completed, 
            applicationStatuses.unsuccessful, 
            applicationStatuses.cancelled
        ].includes(role.submittedApplicationStatusId)
    );
            
    const applicationStatusButton = getApplicationStatusButtonText(role.submittedApplicationStatusId);

    if (userRole !== "talent") return null;

    return (
        <LinkButton
            to={appPaths.applications.create(role.id)}
            scrollToTop
            className="block"
            disabled={!canApplyToRole}
        >
            {applicationStatusButton}
        </LinkButton>
    );
};

export default SubmitApplicationButton;
