import clsx from "clsx";

export type TypographyVariant =
    "display-large" |
    "display-medium" |
    "display-small" |
    "headline-large" |
    "headline-medium" |
    "headline-small" |
    "title-large" |
    "title-medium" |
    "title-small" |
    "body";

export type TypographyComponent =
    "h1" |
    "h2" |
    "h3" |
    "p" |
    "span" |
    "label";

export type TypographyProps = {
    children: React.ReactNode
    variant: TypographyVariant
    component: TypographyComponent
    gutterBottom?: boolean
    className?: string
    underline?: boolean
}

const variantClasses: { [key in TypographyVariant]: string } = {
    "display-large": "text-display-large leading-[1.16]",
    "display-medium": "text-display-medium leading-[1.16]",
    "display-small": "text-display-small leading-[1.16]",
    "headline-large": "text-headline-large leading-[1.16]",
    "headline-medium": "text-headline-medium leading-[1.16]",
    "headline-small": "text-headline-small leading-[1.16]",
    "title-large": "text-title-large leading-[1.16]",
    "title-medium": "text-title-medium leading-[1.16]",
    "title-small": "text-title-small leading-[1.16]",
    "body": "",
};

const gutters: { [key in TypographyVariant]: string } = {
    "display-large": "mb-8",
    "display-medium": "mb-8",
    "display-small": "mb-8",
    "headline-large": "mb-4",
    "headline-medium": "mb-4",
    "headline-small": "mb-4",
    "title-large": "mb-2",
    "title-medium": "mb-2",
    "title-small": "mb-2",
    body: ""
};

const Typography = ({
    children,
    component,
    variant,
    gutterBottom,
    className,
    underline,
}: TypographyProps) => {
    const Component = component;
    return (
        <Component
            className={clsx(
                className,
                variantClasses[variant],
                gutterBottom && gutters[variant],
                underline && "underline decoration-primary underline-offset-8"
            )}
        >
            {children}
        </Component>
    );
};

export default Typography;
