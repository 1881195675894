import Typography from "./Typography";
import LinkButton from "./LinkButton";
import Card, { CardDataField } from "./Card";
import Chip, { ChipContainer } from "./Chip";
import CardDataFieldLabel from "./Card/CardDataFieldLabel";
import TitleUnderline from "./TitleUnderline";
import { GigSummary } from "../models/app/gig";
import { Link } from "react-router-dom";
import { useOrganizationContext } from "../api/current-organization/organizationContext";
import { useAppPaths } from "../Routes";
import useCurrentUser from "../auth/useCurrentUser";
import { Check } from "phosphor-react";

export type GigSummaryCardProps = {
    gig: GigSummary
    viewGigLink: string
}

const GigSummaryCard = ({
    gig,
    viewGigLink,
}: GigSummaryCardProps) => {
    const { gigTerminology } = useOrganizationContext();
    const appPaths = useAppPaths();
    const { userRole } = useCurrentUser();

    return (
        <Card className="space-y-6 w-full break-words" key={gig.id}>
            <div className="flex sm:flex-row flex-col-reverse justify-between">
                <div className="space-y-4 pr-2">
                    <Typography variant="title-small" component="p">
                        <Link to={appPaths.clients.details(gig.giggedClientId)}>
                            {gig.giggedClientName || gig.giggedClientCompanyName}
                        </Link>
                    </Typography>
                    <Typography variant="title-large" component="h2" className="max-w-2xl">{gig?.title}</Typography>
                    <Typography variant="body" component="p" className="italic line-clamp-3">{gig?.description}</Typography>
                </div>
                {userRole === "talent" && gig.submittedProposalId && (
                    <div className="flex flex-row items-center mb-4 sm:mb-auto space-x-2 border rounded-md py-[0.25rem] px-[0.5rem] w-fit">
                        <Check size={18} />
                        <p className="text-[0.9rem] whitespace-nowrap">Proposal submitted</p>
                    </div>
                )}
            </div>
            <TitleUnderline fullWidth />
            <div className="flex sm:space-x-8 flex-col sm:flex-row gap-2">
                <CardDataField label="Initial stage">{gig.initialStageLabel}</CardDataField>
                <CardDataField label="Expected duration">{gig.expectedDurationLabel}</CardDataField>
                <CardDataField label="Remote">{gig.isCompletedRemotely ? "Yes" : "No"}</CardDataField>
            </div>
            <div className="flex justify-between flex-col md:flex-row space-y-4">
                <div className="space-y-2 md:w-4/5">
                    <CardDataFieldLabel>Top three skills needed</CardDataFieldLabel>
                    <ChipContainer className="mr-4">
                        {gig.skills.map(skill => (
                            <Chip key={skill.id} variant="secondary" label={skill.name} />
                        ))}
                    </ChipContainer>
                </div>
                <div className="block mt-4 sm:self-end sm:mt-0">
                    <LinkButton
                        to={viewGigLink}
                        variant="secondary"
                        scrollToTop
                    >
                        View {gigTerminology.toLowerCase()}
                    </LinkButton>
                </div>
            </div>
        </Card>
    );
};

export default GigSummaryCard;
