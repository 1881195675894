import gigApiFetcher from "./common/fetching";
import { SkillDto } from "../models/app/skill";
import { GigApiPaginatedData } from "./common/apiResponse";

export const fetchSkills = async (
    query: string,
    accessToken: string | null,
    pageIndex = 0,
    pageSize = 100
): Promise<SkillDto[]> => {
    const response = await gigApiFetcher<never, GigApiPaginatedData<SkillDto>>({
        url: `api/skills?pageIndex=${pageIndex}&pageSize=${pageSize}&searchTerm=${query}`,
        method: "GET",
        body: undefined,
        token: accessToken || undefined,
    });

    return response.items;
};

export const fetchSkillOptions = async (query: string, accessToken: string | null, pageIndex: number) => {
    const skillsResponse = await fetchSkills(query, accessToken, pageIndex);
    
    return skillsResponse
        .map(skill => ({
            value: skill.id,
            label: skill.name,
        }));
};

export const fetchSkillsCount = async (accessToken: string | null) => {
    const response = await gigApiFetcher<never, SkillCount>({
        url: "api/skills?pageIndex=0&pageSize=100",
        method: "GET",
        body: undefined,
        token: accessToken || undefined,
    });

    return response.totalCount;
};

type SkillCount = {
    totalCount: number
}