import gigApiFetcher from "./common/fetching";
import { SpecializationDto } from "../models/app/specialization";
import { GigApiPaginatedData } from "./common/apiResponse";

export const fetchSpecializations = async (
    query: string,
    accessToken: string | null,
    pageIndex = 0,
    pageSize = 100
): Promise<SpecializationDto[]> => {
    const response = await gigApiFetcher<never, GigApiPaginatedData<SpecializationDto>>({
        url: `api/specializations?pageIndex=${pageIndex}&pageSize=${pageSize}&searchTerm=${query}`,
        method: "GET",
        body: undefined,
        token: accessToken || undefined,
    });

    return response.items;
};

export const fetchSpecializationOptions = async (query = "", accessToken: string | null) => {
    const specializationsResponse = await fetchSpecializations(query, accessToken);
    
    return specializationsResponse
        .map(specialization => ({
            value: specialization.id,
            label: specialization.name,
        }));
};