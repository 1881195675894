import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import Button from "../Button";
import FormTextInput from "../FormTextInput";
import CreateFormPageLayout from "./CreateGigFormPageLayout";
import { GigFormValues, buildGigFormValidationSchemaFields } from "../GigForm/GigFormValues";
import {  formClassNames } from "./classNames";
import LinkButton from "../LinkButton";
import { useOrganizationContext } from "../../api/current-organization/organizationContext";
import { useAppPaths } from "../../Routes";

export type CreateGigFormStep1TitleFields = Pick<GigFormValues, "title">;

export type CreateGigFormStep1TitleProps = {
    onSubmit: (value: CreateGigFormStep1TitleFields) => void
    defaultValues: CreateGigFormStep1TitleFields
}

const CreateGigFormStep1Title = ({
    onSubmit,
    defaultValues,
}: CreateGigFormStep1TitleProps) => {
    const appPaths = useAppPaths();

    const { gigTerminology, talentTerminology } = useOrganizationContext();

    const validationSchema: yup.SchemaOf<CreateGigFormStep1TitleFields> = yup.object({
        title: buildGigFormValidationSchemaFields.title,
    });

    const methods = useForm<CreateGigFormStep1TitleFields>({
        mode: "onSubmit",
        resolver: yupResolver(validationSchema),
        defaultValues
    });    

    return (
        <form
            className={formClassNames}
            onSubmit={methods.handleSubmit(onSubmit)}
            noValidate
        >
            <CreateFormPageLayout
                title="Start with a catchy title."
                description={`A good title will help your ${gigTerminology.toLowerCase()} stand out to the right ${talentTerminology.toLowerCase()}! This is the headline for your ${gigTerminology.toLowerCase()} and is the first thing they will see.`}
                content={
                    <FormTextInput
                        required
                        id="create-gig-form-title"
                        label={`Name of the ${gigTerminology.toLowerCase()}`}
                        placeholder={`Enter name of the ${gigTerminology.toLowerCase()}`}
                        error={methods.formState.errors.title}
                        defaultValue={defaultValues.title}
                        {...methods.register("title")}
                    />
                }
                iconName="TextAa"
                buttons={
                    <>
                        <LinkButton to={appPaths.gigs.createIntro} variant="secondary">Back</LinkButton>
                        <Button type="submit">Next</Button>
                    </>
                }
                helpText={
                    <span>
                        <p className="font-bold mb-2">Example titles:</p>
                        <ul className="list-disc ml-[1rem]">
                            <li>UI/UX Consultant with Figma experience for energy company's rebrand project</li>
                            <li>Wordpress Expert required to design and build new e-commerce website</li>
                            <li>iOS Developer needed to update iOS app UI for new OS/device specs</li>
                        </ul>
                    </span>
                }
            />
        </form>
    );
};

export default CreateGigFormStep1Title;