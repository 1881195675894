import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";

export type TalentPersonalDetailsFormValues = {
    firstName: string
    lastName: string
    aboutMe: string
    receiveNewGigNotifications?: boolean
    locationId?: string | null
}

const buildPersonalDetailsFormValidationSchema = () => yup.object({
    firstName: yup.string().required("First name is required.").max(64, "The maximum length for first name is 64 characters."),
    lastName: yup.string().required("Last name is required.").max(64, "The maximum length for last name is 64 characters."),
    aboutMe: yup.string().required("About me is required."),
    receiveNewGigNotifications: yup.boolean(),
    locationId: yup.string().nullable(),
});

export const usePersonalDetailsForm = (
    initialValues?: TalentPersonalDetailsFormValues
) => useForm<TalentPersonalDetailsFormValues>({
    resolver: yupResolver(buildPersonalDetailsFormValidationSchema()),
    defaultValues: initialValues,    
});
