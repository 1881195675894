import {  GithubLogo, LinkedinLogo } from "phosphor-react";
import { useEffect } from "react";
import { useAddedExtras } from "../api/addedExtras";
import useCurrentUser from "../auth/useCurrentUser";
import Button from "./Button";
import FormTextInput from "./FormTextInput";
import { successToast } from "../toast";
import { AddedExtrasFromValues, useAddedExtrasForm } from "../pages/TalentProfileEdit/AddedExtrasFormValues";

const AddedExtras = () => {
    const {
        addedExtras,
        isLoading,
        isValidating,
        updateAddedExtras,
        isUpdatingAddedExtras,
    } = useAddedExtras();

    const { userRole } = useCurrentUser();

    const methods = useAddedExtrasForm();
    const { reset, formState } = methods;

    useEffect(() => {
        reset({
            ...addedExtras,
            portfolioUrl: addedExtras?.portfolioUrl || "",
            gitHubUrl: addedExtras?.gitHubUrl || "",
            linkedInUrl: addedExtras?.linkedInUrl || ""
        });
    }, [reset, addedExtras]);

    const handleUpdateAddedExtras = async (values: AddedExtrasFromValues) => {
        const response = await updateAddedExtras(values);

        if (response.success) {
            successToast("External links successfully updated.");
        }

        return response;
    };

    return (
        <div>
            {isLoading ? (
                <p>Loading...</p>
            ) : (
                <form onSubmit={methods.handleSubmit(handleUpdateAddedExtras)}>
                    <div className="space-y-6 ">
                        {userRole === "talent" && (
                            <>
                                <FormTextInput
                                    id="added-extras-form-portfolio-url"
                                    label="Online portfolio link"
                                    placeholder="Enter online portfolio link"
                                    error={methods.formState.errors.portfolioUrl}
                                    tooltip="Add a link here to your online portfolio which showcases your best work to potential clients."
                                    {...methods.register("portfolioUrl")}
                                />
                                <FormTextInput
                                    id="added-extras-form-linkedin-url"
                                    label="LinkedIn link"
                                    placeholder="Enter LinkedIn link"
                                    error={methods.formState.errors.linkedInUrl}
                                    StartAdornmentIcon={LinkedinLogo}
                                    fillStartAdornment
                                    {...methods.register("linkedInUrl")}
                                />
                            </>
                        )}
                        <FormTextInput
                            id="added-extras-form-github-url"
                            label="GitHub link"
                            placeholder="Enter GitHub link"
                            error={methods.formState.errors.gitHubUrl}
                            StartAdornmentIcon={GithubLogo}
                            fillStartAdornment
                            {...methods.register("gitHubUrl")}
                        />
                    </div>
                    <div className="md:flex mt-8">
                        <Button
                            type="submit"
                            disabled={!formState.isDirty}

                            loading={isUpdatingAddedExtras || isValidating} className="md:ml-auto w-full md:w-auto">
                            Save &amp; update
                        </Button>
                    </div>
                </form>
            )}
        </div>
    );
};

export default AddedExtras;
