import { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";

import { useTalentSkills } from "../../api/talentSkills";
import Button from "../../components/Button";
import FormDropdown from "../../components/FormDropdown";
import SkillsSearchDropdown from "../../components/SkillsSearchDropdown";
import { GigApiFetcherResponse } from "../../api/common/fetching";

import TalentSkillChips from "./TalentSkillChips";
import { TalentSkillsFormValues, validationSchemaFields } from "./TalentSkillsFormValues";
import { talentProfileEditTooltips } from "../../tooltipsContent";
import { useOrganizationContext } from "../../api/current-organization/organizationContext";
import ErrorList from "../../components/ErrorList";
import { useTalentPersonalInformationContext } from "../../api/talentPersonalInformation";

const validationSchema = yup.object(validationSchemaFields);

const TalentSkillsForm = () => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errors, setErrors] = useState<string[]>([]);
    const { addTalentSkill, removeTalentSkill, talentSkills, isRemovingTalentSkill } = useTalentSkills();
    const { gigTerminology } = useOrganizationContext();
    const { reload: reloadPersonalInformation } = useTalentPersonalInformationContext();

    const handleRemoveSkill = async (skillId: string): Promise<GigApiFetcherResponse<void>> => {
        const response = await removeTalentSkill(skillId);

        if (response.success && talentSkills.length <= 3) {
            reloadPersonalInformation();
        }

        return response;
    };

    const handleSubmit = async (values: TalentSkillsFormValues) => {
        setErrors([]);

        const existingSkill = talentSkills.find(_ => _.skillId === values.skillId);

        if (existingSkill) {
            setErrors([`Skill "${existingSkill.skillName}" already added.`]);
            methods.reset();
            return;
        }

        setIsSubmitting(true);

        const response = await addTalentSkill(values.skillId, values.yearsExperience);

        setIsSubmitting(false);

        if (!response.success) {
            setErrors(response.error.userMessages);
        } else {
            methods.reset();
            if (talentSkills.length >= 2) reloadPersonalInformation();
        }
    };

    const methods = useForm<TalentSkillsFormValues>({
        resolver: yupResolver(validationSchema)
    });

    const yearsExperienceOptions = Array.from(Array(11).keys()).map(i => ({
        label: `${i + 1 < 11 ? i + 1 : "11+"} ${i + 1 === 1 ? "Year" : "Years"}`,
        value: i + 1 < 11 ? i + 1 : i = 15
    }));

    return (
        <div>
            <form
                onSubmit={methods.handleSubmit(handleSubmit)}
                noValidate
                className="flex gap-6 flex-wrap md:flex-nowrap md:items-start"
            >
                <div className="flex gap-6 flex-wrap w-full md:flex-nowrap">
                    <div className="w-full">
                        <Controller
                            name="skillId"
                            control={methods.control}
                            render={({ field: { onChange, value } }) => (
                                <SkillsSearchDropdown
                                    required
                                    tooltip={talentProfileEditTooltips.addSkills(gigTerminology)}
                                    value={value}
                                    disabled={isSubmitting}
                                    onChange={onChange}
                                    error={methods.formState.errors.skillId}
                                />
                            )}
                        />
                    </div>
                    <div className="w-full">
                        <Controller
                            name="yearsExperience"
                            control={methods.control}
                            render={({ field: { onChange, value } }) => (
                                <FormDropdown
                                    required
                                    label="Select years of experience"
                                    options={yearsExperienceOptions}
                                    error={methods.formState.errors.yearsExperience}
                                    value={value}
                                    onChange={onChange}
                                    disabled={isSubmitting}
                                />
                            )}
                        />
                    </div>
                </div>
                <Button
                    className="w-full md:mt-[2rem] md:w-auto"
                    loading={isSubmitting}
                    variant="secondary"
                    type="submit"
                >
                    Add skill
                </Button>
            </form>

            <ErrorList errors={errors} />

            <TalentSkillChips skills={talentSkills} removeSkill={handleRemoveSkill} isRemovingSkill={isRemovingTalentSkill} />
            
        </div>
    );
};

export default TalentSkillsForm;