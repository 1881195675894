import { useEffect } from "react";
import { useTalentPersonalInformationContext } from "../../api/talentPersonalInformation";
import Card from "../../components/Card";
import FormAccordion from "../../components/FormAccordion";
import InfoBox from "../../components/InfoBox";
import PageContent from "../../components/PageContent";
import AddedExtras from "../../components/AddedExtras";
import CareerHistory from "./CareerHistory";
import Certifications from "./Certifications";
import Education from "./Education";
import TalentIndustrialExperienceForm from "./TalentIndustrialExperienceForm";
import TalentPersonalDetailsForm from "./TalentPersonalDetailsForm";
import TalentProfileVideosForm from "./TalentProfileVideosForm";
import TalentSkillsForm from "./TalentSkillsForm";
import TalentSpecializationForm from "./TalentSpecializationForm";

const TalentProfileEditPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const { talentPersonalInformation } = useTalentPersonalInformationContext();

    return (
        <PageContent className="child:mx-0">
            {talentPersonalInformation && !talentPersonalInformation?.profileIsComplete && (
                <InfoBox className="mb-4" variant="info" RootElement="div">
                    Please complete the required fields listed below:
                    <ul className="list-disc ml-8">
                        <li>Profile Picture</li>
                        <li>About Me</li>
                        <li>Location</li>
                        <li>Specialization</li>
                        <li>Minimum 3 Skills</li>
                    </ul>
                </InfoBox>
            )}
            <Card className="rounded-none sm:rounded-lg">
                <FormAccordion
                    steps={[
                        {
                            title: "Your details",
                            content: (
                                <TalentPersonalDetailsForm />
                            )
                        },
                        {
                            title: "Your skills",
                            content: (
                                <>
                                    <p className="mb-8">Make sure to add all the skills you hold relevant to your talent. It will increase your chances of being matched to a gig by our super smart algorithm! Remember to add your years of experience for each skill too.</p>
                                    <div className="space-y-12">
                                        <TalentSpecializationForm />
                                        <TalentSkillsForm />
                                    </div>
                                </>
                            ),
                        },
                        {
                            title: "Experience",
                            content: (
                                <>
                                    <p className="mb-8">Some of our clients will be looking for specific industry experience so make sure to add any here to make you stand out from the crowd.</p>
                                    <div className="space-y-12">
                                        <TalentIndustrialExperienceForm />
                                        <CareerHistory />
                                    </div>
                                </>
                            ),
                        },
                        {
                            title: "Education",
                            content: (
                                <>
                                    <p className="mb-8">Add any relevant education to your skills here. </p>
                                    <div className="space-y-12">
                                        <Education />
                                        <Certifications />
                                    </div>
                                </>
                            ),
                        },
                        {
                            title: "External links",
                            content: (
                                <AddedExtras />
                            ),
                        },
                        {
                            title: "Profile videos",
                            content: (
                                <TalentProfileVideosForm />
                            )
                        }
                    ]}
                />
            </Card>
        </PageContent>
    );
};

export default TalentProfileEditPage;