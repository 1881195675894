import { TalentRoleAssignmentDto } from "../models/app/roleAssignment";
import { useGiggedApiSWR } from "./common/giggedApiSWR";

export type UseTalentRoleAssignmentsReturn = {
    roleAssignments: TalentRoleAssignmentDto[]
    isLoading: boolean
}

export const useTalentRoleAssignments = (): UseTalentRoleAssignmentsReturn => {
    const url = "api/me/roles/assignments";

    const apiCall = useGiggedApiSWR<TalentRoleAssignmentDto[]>(url);

    return {
        roleAssignments: apiCall.data || [],
        isLoading: apiCall.isLoading,
    };
};
