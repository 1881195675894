import * as yup from "yup";
import { ApiEditableProposalMilestoneFields } from "../../models/api/proposal";

export const validationSchemaFields = {
    estimatedDurationDays: yup.number()
        .required("Estimated duration is required.")
        .min(1, "Estimated duration must be at least 1 day.")
        .max(3650, "Estimated duration can be 10 years at most")
        .typeError("Estimated duration is required."),
    introduction: yup.string().required("Introduction is required."),
    relevantExperience: yup.string().required("Relevant experience is required."),
    portfolioUrl: yup.string(),
    talentDefinedMilestones: yup.boolean(),
    milestones: yup.array().when(["talentDefinedMilestones"], {
        is: (talentDefinedMilestones: boolean) => talentDefinedMilestones,
        then: yup.array().of(
            yup.object().shape({
                description: yup.string().transform((value: string) => value.trim()).required("Description is required."),
            })
        )
    }),
};

export type CreateProposalFormValues = {
    estimatedDurationDays: number,
    introduction: string,
    relevantExperience: string,
    portfolioUrl: string,
    talentDefinedMilestones: boolean,
    milestones: ApiEditableProposalMilestoneFields[],
};