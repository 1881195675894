import { useEffect, useState } from "react";
import ApiError from "../../api/common/apiError";
import { GigApiFetcherResponse } from "../../api/common/fetching";
import useBeforeUnload from "../../hooks/useWarnBeforeUnload";
import TabStepper from "../TabStepper";

import { ApiCreateRoleDto } from "../../models/api/role";
import { RoleFormValues } from "../RoleForm";
import StepTitle from "./StepTitle";
import StepDescription from "./StepDescription";
import StepSkills from "./StepSkills";
import StepRoleCategory from "./StepRoleCategory";
import StepSummary from "./StepSummary";
import StepAssignmentType from "./StepAssignmentType";
import StepVisibility from "./StepVisibility";
import { capitalizeStrings } from "../../utils/capitalizeStrings";
import { roleAssignmentTypes } from "../../models/app/role";

export type RoleFormProps = {
    onPost: (values: ApiCreateRoleDto) => Promise<GigApiFetcherResponse<unknown>>
    onSaveAsDraft: (values: ApiCreateRoleDto) => Promise<GigApiFetcherResponse<unknown>>
}

const CreateRoleForm = ({
    onPost,
    onSaveAsDraft
}: RoleFormProps) => {
    useBeforeUnload();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submissionError, setSubmissionError] = useState<ApiError | undefined>(undefined);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [roleFormData, setRoleFormData] = useState<RoleFormValues>({
        title: "",
        description: "",
        roleCategoryId: "",
        skills: [],
        roleStatusId: 1,
        roleAssignmentTypeId: -1,
        roleVisibilityTypeId: -1
    });

    const updateAction = (data: Partial<RoleFormValues>) => {
        setRoleFormData(oldValue => ({
            ...oldValue,
            ...data
        }));
    };

    const handleBackClick = (data: Partial<RoleFormValues>) => {
        updateAction(data);
        setSelectedIndex(value => value - 1);
        window.scrollTo(0, 0);
    };

    const handleStepSubmit = (data: Partial<RoleFormValues>) => {
        updateAction(data);
        setSelectedIndex(value => value + 1);
        window.scrollTo(0, 0);
    };

    const handleSubmit = async (data: RoleFormValues, callback: (values: ApiCreateRoleDto) => Promise<GigApiFetcherResponse<unknown>>) => {
        setIsSubmitting(true);
        updateAction(data);

        const response = await callback({
            ...data,
            title: capitalizeStrings(data.title),
            skillIds: data.skills.map(skill => skill.id),
        });
        setIsSubmitting(false);

        if (!response.success) {
            setSubmissionError(response.error);
        }
    };

    const handleSaveAndPost = async (data: RoleFormValues) => {
        await handleSubmit(data, onPost);
    };

    const handleSaveAsDraft = async (data: RoleFormValues) => {
        await handleSubmit(data, onSaveAsDraft);
    };

    const pages = [
        {
            title: "Title",
            content: <StepTitle onSubmit={handleStepSubmit} defaultValues={roleFormData} />
        },
        {
            title: "Description",
            content: <StepDescription onSubmit={handleStepSubmit} onBackClick={handleBackClick} defaultValues={roleFormData} />
        },
        {
            title: "Role Category",
            content: <StepRoleCategory onSubmit={handleStepSubmit} onBackClick={handleBackClick} defaultValues={roleFormData} />
        },
        {
            title: "Skills",
            content: <StepSkills onSubmit={handleStepSubmit} onBackClick={handleBackClick} defaultValues={roleFormData} />
        },
        {
            title: "Assignment type",
            content: <StepAssignmentType onSubmit={handleStepSubmit} onBackClick={handleBackClick} defaultValues={roleFormData} />
        },
    ];

    if (roleFormData.roleAssignmentTypeId === roleAssignmentTypes.applications) {
        pages.push({
            title: "Visibility",
            content: <StepVisibility onSubmit={handleStepSubmit} onBackClick={handleBackClick} defaultValues={roleFormData} />
        });
    }

    pages.push({
        title: "Summary",
        content: <StepSummary onSaveAndPost={handleSaveAndPost} onSaveAsDraft={handleSaveAsDraft} isSubmitting={isSubmitting} defaultValues={roleFormData} submissionError={submissionError} />
    });


    return (
        <TabStepper
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
            pages={pages}
        />
    );
};

export default CreateRoleForm;