
const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

export const formatDate = (date: Date, hideCurrentYear?: boolean): string => {
    try {
        const isCurrentYear = date.getFullYear() == new Date().getFullYear();
        const monthString = months[date.getMonth()];
        return `${date.getDate()}${nth(date)} ${monthString} ${hideCurrentYear && isCurrentYear ? "" : date.getFullYear()}`;
    } catch (error) {
        console.error("Error formatting date from date:", error);
        throw error;
    }
};

export const formatTime = (date:Date) => {
    let hours = date.getHours();
    const minutes = date.getMinutes();    
    const ampm = hours >= 12 ? "pm" : "am";
  
    hours %= 12;
    hours = hours || 12;    
    const tempMinutes = minutes < 10 ? `0${minutes}` : minutes;
  
    return `${hours}:${tempMinutes}${ampm}`;
  
};
  
const nth = function (date: Date): string {
    if (date.getDate() > 3 && date.getDate() < 21) 
        return "th";
    
    switch (date.getDate() % 10) {
    case 1:
        return "st";
    case 2:
        return "nd";
    case 3:
        return "rd";
    default:
        return "th";
    }
};

export const todayAtMidnight = () => {
    const date = new Date();
    date.setHours(0,0,0,0);

    return date;
};

export const formatDateOnlyString = (parseableDateString?: string): string => {
    if (!parseableDateString) return "";

    return new Date(parseableDateString).toLocaleDateString();
};

export default formatDateOnlyString;
