import { SortOrder } from "../../api/common/sortOrder";
import { ApiCancelGigDto, ApiCompleteGigDto, ApiCreateGigDto, ApiEditableGigFields, ApiGigDetails, ApiGigSkill, ApiGigSummary, ApiUpdateGigDto } from "../api/gig";
import { ProposalSummary } from "./proposal";

type AppGig<T> = Omit<T, "startDate" | "deadlineDate"> & {
    startDate: Date
    deadlineDate: Date | null
};

export type EditableGigFields = AppGig<ApiEditableGigFields>

export type CreateGigDto = AppGig<ApiCreateGigDto>
export type UpdateGigDto = AppGig<ApiUpdateGigDto>

export type GigSummary =  Omit<AppGig<ApiGigSummary>, "hiredProposal"> & {
    expectedDurationLabel: string
    initialStageLabel: string
}

export type GigDetailsSkill = ApiGigSkill

export type GigDetails = AppGig<ApiGigDetails> & {
    expectedDurationLabel: string
    initialStageLabel: string
    hiredProposal?: ProposalSummary
}

export type InviteToGigDto = {
    talentId: string
    message: string
}

export type AddFileToGigDto = {
    fileDataUri: string
}

export type DeleteFileFromGigDto = {
    fileId: string
}


export type CompleteGigDto = ApiCompleteGigDto;
export type CancelGigDto = ApiCancelGigDto;

export const gigStatuses = {
    draft: 0,
    posted: 1,
    hired: 2,
    cancelled: 3,
    completed: 4,
};

export const expectedDurationOptions = [
    { label: "Less than a week", value: 0 },
    { label: "Less than a month", value: 1 },
    { label: "1 month", value: 2 },
    { label: "2-3 months", value: 3 },
    { label: "3-6 months", value: 4 },
    { label: "More than 6 months", value: 5 },
];

export const initialStageOptions = [
    { label: "Not started", value: 0 },
    { label: "Just kicked off", value: 1 },
    { label: "Partly complete", value: 2 },
];

export const remoteOptions = [
    {label: "Yes", value: "true"},
    {label: "No", value: "false"},
];

export const remoteFilterOptions = [
    {label: "Yes", value: "true"},
    {label: "No", value: "false"},
    {label: "Show all", value: ""}
];

const gigOrderByFields = [
    { value: "Title", label: "Title" },
    { value: "CreatedAt", label: "Created At" },
    { value: "StartDate", label: "Start Date" },
    { value: "DeadlineDate", label: "Deadline Date" },
    { value: "InitialStageId", label: "Initial Stage" },
    { value: "ExpectedDurationId", label: "Expected Duration" },
] as const;

export type GigOrderByFieldKeys = typeof gigOrderByFields[number]["value"] |
    "PostedDate" |
    "CompletedDate" |
    "CancelledDate";

export const gigOrderByOptions = gigOrderByFields.flatMap(({ value, label }): {
    value: `${GigOrderByFieldKeys}:${SortOrder}`
    label: string
}[] => {
    return [
        {
            value: `${value}:asc`,
            label: `Sort by ${label.toLowerCase()} ASC`,
        },
        {
            value: `${value}:desc`,
            label: `Sort by ${label.toLowerCase()} DESC`,
        },
    ];
});