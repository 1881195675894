import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

export type InviteTalentToGigFormDialogValues = {
    gigId: string
    message: string
};

export const inviteToGigFormValidationSchemaFields = (gigTerminology: string) => {

    return yup.object({
        gigId: yup.string().required(`You must select a ${gigTerminology}.`),
        message: yup.string().required("Message is required."),
    });
};

export const useInviteToGigForm = (gigTerminology: string) => {
    return useForm<InviteTalentToGigFormDialogValues>({
        resolver: yupResolver(inviteToGigFormValidationSchemaFields(gigTerminology))
    });
};