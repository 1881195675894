import { createTheme } from "@mui/material";
import { OrganizationConfigTheme } from "./models/api/organization";

export const themeValues = {
    "default": {
        primaryMain: "#36dd93",
        primaryText: "#0b1b29",
        paperBackground: "#FFFFFF",
    },
    "red": {
        primaryMain: "#BA1A21",
        primaryText: "#0b1b29",
        paperBackground: "#FFFBFF"
    },
    "blue": {
        primaryMain: "#496CEE",
        primaryText: "#0b1b29",
        paperBackground: "#FEFBFF"
    },
    "purple": {
        primaryMain: "#812CD5",
        primaryText: "#0b1b29",
        paperBackground: "#FFFBFF"
    },
    "black": {
        primaryMain: "#1A1C1E",
        primaryText: "#0b1b29",
        paperBackground: "#FDFCFF"
    }
};

const theme = (theme: OrganizationConfigTheme) => createTheme({
    palette: {
        primary: {
            main: themeValues[theme].primaryMain,
            dark: themeValues[theme].primaryMain,
        },
        text: {
            primary: themeValues[theme].primaryText,
        },
    },
    typography: {
        fontFamily: "Mulish, sans-serif",
    },
    components: {
        MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundColor: themeValues[theme].paperBackground,
                }
            }
        },
        MuiButton: {
            defaultProps: {
                disableRipple: true,
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    borderRadius: "12px",
                    "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                            borderColor: themeValues[theme].primaryText,
                            borderRadius: "12px",
                        },
                        "&:hover fieldset": {
                            borderColor: themeValues[theme].primaryText,
                        },
                        "&.Mui-focused fieldset": {
                            borderWidth: "3px !important",
                            borderColor: themeValues[theme].primaryText
                        },
                    },
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    backgroundColor: "white"
                }
            }
        }
    },
});


export default theme;