import { ApplicationSummary } from "../models/app/application";
import Button from "./Button";
import { CardDataFieldLabel } from "./Card";
import DialogBox, { useDialogBoxState } from "./DialogBox";
import { dialogWidths } from "./DialogBox/DialogBox";

export type ApplicationCoverLetterButtonProps = {
    application: ApplicationSummary
    className?: string
}

const ApplicationCoverLetterButton = ({
    application,
    className,
}: ApplicationCoverLetterButtonProps) => {
    const dialogState = useDialogBoxState();

    const portfolioHref = application.portfolioUrl.startsWith("http") ?
        application.portfolioUrl :
        `//${application.portfolioUrl}`;

    return (
        <span className={className}>
            <Button variant="secondary" onClick={dialogState.open}>Cover letter</Button>
            <DialogBox {...dialogState} title="Cover letter" minWidth={dialogWidths.medium}>
                <div className="space-y-6 mt-6 text-left">
                    <div>
                        <p>
                            {application.coverLetter}
                        </p>
                    </div>
                    {application.portfolioUrl && (
                        <div>
                            <CardDataFieldLabel>Portfolio link</CardDataFieldLabel>
                            <a href={portfolioHref} target="_blank">
                                {application.portfolioUrl}
                            </a>
                        </div>
                    )}
                </div>
            </DialogBox>
        </span>
    );
};

export default ApplicationCoverLetterButton;
