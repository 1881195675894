import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import Button from "../Button";
import { RoleFormValues, buildRoleFormValidationSchemaFields } from "../RoleForm";
import { formClassNames } from "../CreateGigForm/classNames";
import CreateFormPageLayout from "../CreateGigForm/CreateGigFormPageLayout";
import { useEffect, useState } from "react";
import FormSearchDropdown, { FormSearchDropdownOption, LoadOptionsCallback } from "../FormSearchDropdown/FormSearchDropdown";
import useAccessToken from "../../auth/useAccessToken";
import { fetchRoleCategories } from "../../api/roleCategories";
import { useDebouncedCallback } from "use-debounce";

export type CreateFormStepRoleCategoryFields = Pick<RoleFormValues, "roleCategoryId">;

export type CreateRoleFormStepRoleCategoryProps = {
    onSubmit: (value: CreateFormStepRoleCategoryFields) => void
    onBackClick: (value: CreateFormStepRoleCategoryFields) => void
    defaultValues: CreateFormStepRoleCategoryFields
}

const CreateRoleFormStepRoleCategory = ({
    onSubmit,
    onBackClick,
    defaultValues,
}: CreateRoleFormStepRoleCategoryProps) => {
    const accessToken = useAccessToken();
    const schema = buildRoleFormValidationSchemaFields();
    const [allCategoryOptions, setAllCategoryOptions] = useState<FormSearchDropdownOption[]>([]);

    const fetchCategoryOptions = async (searchQuery: string) => {
        const categoriesResponse = await fetchRoleCategories(searchQuery, accessToken);

        return categoriesResponse
            .map(category => ({
                value: category.id,
                label: category.name,
            }));
    };

    useEffect(() => {
        if (!accessToken) return;

        const loadAllCategories = async () => {
            const fetchedCategoryOptions = await fetchCategoryOptions("");
            setAllCategoryOptions(fetchedCategoryOptions);
        };

        loadAllCategories();
    }, [accessToken]);

    const loadCategoryOptions = (inputValue: string, callback: LoadOptionsCallback) => {
        fetchCategoryOptions(inputValue)
            .then(options => callback(options));
    };

    const debouncedLoadCategoryOptions = useDebouncedCallback(loadCategoryOptions, 700);

    const validationSchema: yup.SchemaOf<CreateFormStepRoleCategoryFields> = yup.object({
        roleCategoryId: schema.fields.roleCategoryId
    });

    const methods = useForm<CreateFormStepRoleCategoryFields>({
        resolver: yupResolver(validationSchema),
        defaultValues
    });

    const handleBackClick = () => {
        onBackClick(methods.getValues());
    };

    return (
        <>
            <form
                className={formClassNames}
                onSubmit={methods.handleSubmit(onSubmit)}
                noValidate
            >
                <CreateFormPageLayout
                    title="What category does this role come under?"
                    description="Please select from the categories available in the dropdown."
                    content={
                        <div className="mb-6">
                            <Controller
                                name="roleCategoryId"
                                control={methods.control}
                                defaultValue={defaultValues.roleCategoryId}
                                render={({ field: { onChange, value } }) => (
                                    <FormSearchDropdown
                                        value={value}
                                        label="Role category"
                                        loadOptions={debouncedLoadCategoryOptions}
                                        onChange={onChange}
                                        defaultOptions={allCategoryOptions}
                                        required={true}
                                        error={methods.formState.errors.roleCategoryId}
                                    />
                                )}
                            />
                        </div>
                    }
                    iconName="SquaresFour"
                    buttons={
                        <>
                            <Button onClick={handleBackClick} variant="secondary" type="button">Back</Button>
                            <Button type="submit">Next</Button>
                        </>
                    }
                />
            </form>
        </>
    );
};

export default CreateRoleFormStepRoleCategory;