import * as yup from "yup";
import { ApiProposalMilestone } from "../../models/api/proposal";

export const validationSchemaFields = {
    estimatedDurationDays: yup.number()
        .required("Estimated duration is required.")
        .min(1, "Estimated duration must be at least 1 day.")
        .max(3650, "Estimated duration can be 10 years at most")
        .typeError("Estimated duration is required."),
    introduction: yup.string().required("Introduction is required."),
    relevantExperience: yup.string().required("Relevant experience is required."),
    portfolioUrl: yup.string(),
};

export type EditProposalFormValues = {
    estimatedDurationDays: number,
    introduction: string,
    relevantExperience: string,
    portfolioUrl: string,
    milestones: ApiProposalMilestone[],
};