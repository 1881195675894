import { useState } from "react";
import { useOrganizationContext } from "../../api/current-organization/organizationContext";
import Button from "../Button";
import DialogBox, { useDialogBoxState } from "../DialogBox";
import FormTextAreaInput from "../FormTextAreaInput";
import { InviteFormDialogValues, useInviteForm } from "./InviteFormDialogValues";
import ApiError from "../../api/common/apiError";
import GeneralFormError from "../GeneralFormError";
import { dialogWidths } from "../DialogBox/DialogBox";
import { GigApiFetcherResponse } from "../../api/common/fetching";

export type InviteFormDialogProps = {
    talentId: string
    isInvited: boolean
    id: string
    className?: string
    onSubmit: (values: InviteFormDialogValues, talentId: string) => Promise<GigApiFetcherResponse<void>>
    isLoading: boolean
}
const InviteFormDialog = ({
    talentId,
    isInvited,
    id,
    className,
    onSubmit,
    isLoading
}: InviteFormDialogProps) => {
    const methods = useInviteForm();
    const [submissionError, setSubmissionError] = useState<ApiError | undefined>(undefined);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const dialogState = useDialogBoxState();
    const { talentTerminology } = useOrganizationContext();

    const handleConfirmInvite = async (values: InviteFormDialogValues) => {
        setIsSubmitting(true);

        const response = await onSubmit(values, talentId);

        setIsSubmitting(false);

        if (response.success) {
            dialogState.close();
            return;
        }

        setSubmissionError(response.error);
    };

    return (
        <span className={className}>
            <div className="m-auto">
                <Button onClick={dialogState.onOpen} disabled={isInvited} variant={"primary"} className="w-full sm:mt-0">
                    {isInvited ? "Invited" : "Invite"}
                </Button>
            </div>
            <form onSubmit={methods.handleSubmit(handleConfirmInvite)}>
                <DialogBox
                    {...dialogState}
                    title={`Invite ${talentTerminology.toLowerCase()}`}
                    minWidth={dialogWidths.medium}
                >
                    <FormTextAreaInput
                        id="invite-message"
                        rows={7}
                        placeholder="Add a message to go with your invite"
                        register={methods.register("message")}
                        error={methods.formState.errors.message}
                        disabled={methods.formState.isSubmitting}
                    />

                    <GeneralFormError error={submissionError} />

                    <div className="mt-4">
                        <Button className="w-full" type="submit" loading={isSubmitting || isLoading}>Send invite</Button>
                    </div>
                </DialogBox>
            </form>
        </span>
    );
};

export default InviteFormDialog;