import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

export type AssignRoleFormValues = {
    message: string
};

export const assignRoleFormValidationSchemaFields = () => {
    return yup.object({
        message: yup.string().required("Message is required."),
    });
};

export const useAssignRoleForm = () => {
    const schema = assignRoleFormValidationSchemaFields();
    return useForm<AssignRoleFormValues>({
        resolver: yupResolver(schema)
    });
};