import { useEffect, useState } from "react";
import { GigApiFetcherResponse, useAuthenticatedGigApiFetcher } from "./common/fetching";
import { useGiggedApiSWR } from "./common/giggedApiSWR";
import { ApiRoleAssignmentDetailsDto } from "../models/api/roleAssignment";
import { RoleAssignmentDetailsDto } from "../models/app/roleAssignment";
import { mapFromApiRoleAssignmentDetailsDto } from "../models/mappers/roleAssignment";

export const useAcceptRoleAssignment = () => useAuthenticatedGigApiFetcher<never, void, string>("PUT",
    (roleAssignmentId) => ({
        url: `api/role-assignments/${roleAssignmentId}/actions/accept`,
    }));

export type UseTalentRoleAssignmentReturn<TGigId extends string | undefined> = TGigId extends undefined ?
    { roleAssignment: undefined } :
    {
        roleAssignment: RoleAssignmentDetailsDto
        acceptRoleAssignment: () => Promise<GigApiFetcherResponse<void>>
        isLoading: boolean
        isAcceptingRoleAssignment: boolean
    }

export const useTalentRoleAssignment = <TRoleAssignmentId extends string | undefined>(roleAssignmentId?: TRoleAssignmentId): UseTalentRoleAssignmentReturn<TRoleAssignmentId> => {
    const [roleAssignment, setRoleAssignment] = useState<RoleAssignmentDetailsDto | undefined>();
    const [acceptRoleAssignment, isAcceptingRoleAssignment] = useAcceptRoleAssignment();

    const url = `api/role-assignments/${roleAssignmentId}`;

    const apiCall = useGiggedApiSWR<ApiRoleAssignmentDetailsDto>(url);

    useEffect(() => {
        if (apiCall.data === undefined) {
            setRoleAssignment(undefined);
            return;
        }

        setRoleAssignment(mapFromApiRoleAssignmentDetailsDto(apiCall.data));
    }, [apiCall.data]);

    if (roleAssignmentId === undefined) return { roleAssignment: undefined } as UseTalentRoleAssignmentReturn<TRoleAssignmentId>;

    return {
        ...apiCall,
        roleAssignment,
        isLoading: apiCall.isLoading,
        acceptRoleAssignment: async () => {
            const response = await acceptRoleAssignment(roleAssignmentId);

            if (response.success) {
                apiCall.mutate();
            }

            return response;
        },
        isAcceptingRoleAssignment,
    } as UseTalentRoleAssignmentReturn<TRoleAssignmentId>;
};