import clsx from "clsx";
import { useOrganizationContext } from "../api/current-organization/organizationContext";
import { useAcceptRoleAssignment } from "../api/talentRoleAssignment";
import { TalentRoleAssignmentDto, roleAssignmentStatusOptions, roleAssignmentStatusTypes } from "../models/app/roleAssignment";
import { errorToast, successToast } from "../toast";
import formatDateOnlyString from "../utils/formatDateString";
import Button from "./Button";
import Card from "./Card";
import DialogBox, { useDialogBoxState } from "./DialogBox";
import { dialogWidths } from "./DialogBox/DialogBox";
import Tooltip from "./Tooltip";
import Typography from "./Typography";

export type TalentRoleAssignmentCardProps = {
    roleAssignment: TalentRoleAssignmentDto
}
export const TalentRoleAssignmentCard = ({
    roleAssignment,
}: TalentRoleAssignmentCardProps) => {
    const acceptAssignmentDialogState = useDialogBoxState();
    const { giggedClientTerminology } = useOrganizationContext();
    const [acceptRoleAssignment, isSubmitting] = useAcceptRoleAssignment();

    const handleAccept = async (roleAssignmentId: string) => {
        const response = await acceptRoleAssignment(roleAssignmentId);

        if (response.success) {
            successToast("Role accepted.");
            acceptAssignmentDialogState.close();
            window.location.reload();
        }
        if (!response.success) {
            response.error.userMessages.map((error: string) => errorToast(`Error: ${error}`)
            );
        }
    };

    const isButtonDisabled = roleAssignment.roleAssignmentStatusId !== roleAssignmentStatusTypes.submitted;

    return (
        <>
            <Card key={roleAssignment.roleId} className="flex flex-col md:flex-row text-center md:text-left items-center grow md:mr-4 rounded-b-none md:rounded-b-lg pb-2 md:pb-6">
                <div className="md:mr-8">
                    <Typography variant="title-large" component="p" className="mb-4">{roleAssignment.roleTitle}</Typography>
                    <p className="mb-2">
                        <span className="font-bold">{giggedClientTerminology}: </span>
                        {roleAssignment.giggedClientName}</p>
                    <p className="mb-2">
                        <span className="font-bold">Sent on: </span>
                        {formatDateOnlyString(roleAssignment.dateSent)}</p>
                    <p className="mb-2">
                        <span className="font-bold">Status: </span>
                        {roleAssignmentStatusOptions[roleAssignment.roleAssignmentStatusId].label}</p>
                    <p className="md:mr-8">
                        <span className="font-bold">Message: </span>
                        <span className="italic whitespace-pre-wrap">{roleAssignment.message}</span>
                    </p>
                </div>
                <div className="md:ml-auto">
                    <Tooltip 
                        tooltipText="This assignment is not in 'Submitted' status. You can only accept assignments that are marked as 'Submitted'."
                        isEnabled={isButtonDisabled}
                    >
                        <Button
                            onClick={acceptAssignmentDialogState.open}
                            disabled={isButtonDisabled}
                            className={clsx(isButtonDisabled && "cursor-not-allowed", "block my-4 md:my-0 md:ml-auto")}
                            variant="primary"
                        >
                            Accept
                        </Button>
                    </Tooltip>
                </div>
            </Card>
            <DialogBox
                {...acceptAssignmentDialogState}
                title="Accept Role Assignment"
                maxWidth={dialogWidths.medium}
                titleBold
            >
                <div className="flex flex-col gap-4">
                    <Typography variant="body" component="p">{"Are you sure you want to accept this role assignment?"}</Typography>
                    <Typography variant="body" component="p">{"Please note: Accepting will replace any current role you hold."}</Typography>
                </div>
                <div className="flex items-center sm:justify-between sm:space-x-4 flex-col-reverse sm:flex-row">
                    <Button className="w-full sm:w-fit mt-4 sm:mt-0" disabled={isSubmitting} variant="secondary" onClick={acceptAssignmentDialogState.close}>Cancel</Button>
                    <Button className="w-full sm:w-fit" loading={isSubmitting} variant="primary" onClick={() => handleAccept(roleAssignment.roleAssignmentId)}>Confirm</Button>
                </div>
            </DialogBox>
        </>
    );
};

export default TalentRoleAssignmentCard;