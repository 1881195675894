import { useForm } from "react-hook-form";
import * as yup from "yup";

export const validationSchemaFields = {
    coverLetter: yup.string().required("Cover letter is required."),
    portfolioUrl: yup.string(),
};

export type ApplicationFormValues = {
    coverLetter: string
    portfolioUrl: string
    applicationStatusId: number
};

export const useApplicationForm = (
    defaultValues?: ApplicationFormValues,    
) => {
    return useForm<ApplicationFormValues>({
        defaultValues,
    });
};