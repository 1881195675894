import gigApiFetcher from "./common/fetching";
import { LocationDto } from "../models/app/location";
import { GigApiPaginatedData } from "./common/apiResponse";

export const fetchLocations = async (
    query: string,
    accessToken: string | null,
    pageIndex = 0,
    pageSize = 100
): Promise<LocationDto[]> => {
    const response = await gigApiFetcher<never, GigApiPaginatedData<LocationDto>>({
        url: `api/locations?pageIndex=${pageIndex}&pageSize=${pageSize}&searchTerm=${query}`,
        method: "GET",
        body: undefined,
        token: accessToken || undefined,
    });

    return response.items;
};

export const fetchLocationOptions = async (query = "", accessToken: string | null) => {
    const locationsResponse = await fetchLocations(query, accessToken);
    
    return locationsResponse
        .map(location => ({
            value: location.id,
            label: location.name,
        }));
};