import clsx from "clsx";
import { proposalStatuses, proposalStatusLabels } from "../models/app/proposal";

export type ProposalStatusChipProps = {
    proposalStatusId: number
    onHold: boolean
}

const ProposalStatusChip = ({
    proposalStatusId,
    onHold
}: ProposalStatusChipProps) => {
    const label = onHold ? "On Hold" : proposalStatusLabels[proposalStatusId];
    return (
        <div className="md:min-w-[110px] text-center md:text-left">
            <span className={clsx(
                "rounded-full px-4 py-1.5 font-bold text-white",
                onHold ? "!bg-[#f49536]" :
                    proposalStatusId === proposalStatuses.pendingReview && "bg-[#db7c29]",
                proposalStatusId === proposalStatuses.submitted && "bg-[#9e9e9e]",
                proposalStatusId === proposalStatuses.reviewRejected && "bg-[#d82020]",
                proposalStatusId === proposalStatuses.hired && "bg-primary",
                proposalStatusId === proposalStatuses.unsuccessful && "bg-[#d82020]",
                proposalStatusId === proposalStatuses.completed && "bg-none !text-black border-2",
                proposalStatusId === proposalStatuses.cancelled && "!text-black !p-0",
            )}>
                {label}
            </span>
        </div>
    );
};

export default ProposalStatusChip;