import { useNavigate } from "react-router-dom";
import { GigApiFetcherResponse } from "../api/common/fetching";
import { useOrganizationContext } from "../api/current-organization/organizationContext";
import { useGig } from "../api/gig";
import { useProposals } from "../api/proposals";
import Card, { CardDataField } from "../components/Card";
import CreateProposalForm, { CreateProposalFormValues } from "../components/CreateProposalForm";
import MissingGigSkillsInfoBox from "../components/MissingGigSkillsInfoBox";
import PageContent from "../components/PageContent";
import Typography from "../components/Typography";
import { ApiFile } from "../models/api/file";
import { expectedDurationOptions } from "../models/app/gig";
import { useAppPaths, useGigId } from "../Routes";
import { DEFAULT_PROPOSAL_MILESTONE } from "../utils/constants";
import findOptionLabel from "../utils/findOptionLabel";

const ProposalCreatePage = () => {
    const appPaths = useAppPaths();
    const navigate = useNavigate();
    const { createProposal, isCreatingProposal } = useProposals();
    const gigId = useGigId();
    const { gig } = useGig(gigId as string);
    const { gigTerminology } = useOrganizationContext();

    if (!gigId || !gig) return null;
    
    const onSubmit = async (
        values: CreateProposalFormValues, 
        proposalFiles: ApiFile[]
    ): Promise<GigApiFetcherResponse<string>> => {
        const proposalMilestones = values.talentDefinedMilestones ? values.milestones : [
            {
                description: DEFAULT_PROPOSAL_MILESTONE,
            }
        ];

        const response = await createProposal({ 
            ...values, 
            gigId, 
            fileDataUris: proposalFiles.map(proposalFile => proposalFile.url),
            milestones: proposalMilestones
        });

        if (response.success) {
            navigate(appPaths.gigs.findAGigDetails(gigId));
        }

        return response;
    };
    
    return (
        <PageContent>
            <MissingGigSkillsInfoBox gigId={gigId} />
            <Card className="mb-4 space-y-4 break-words">
                <Typography variant="title-large" component="h2">{gig?.title}</Typography>
                <p className="italic whitespace-pre-wrap">{gig?.description}</p>
                <div className="flex">
                    <CardDataField
                        label="Estimated duration"
                        iconName="Clock"
                        className="mr-auto"
                    >
                        {findOptionLabel(expectedDurationOptions, gig?.expectedDurationId)}
                    </CardDataField>
                </div>
            </Card>
            <Typography variant="display-medium" component="h1" gutterBottom>{`${gigTerminology} Proposal`}</Typography>
            <CreateProposalForm
                isSubmitting={isCreatingProposal}
                onSubmit={onSubmit}
                submitButtonText="Submit proposal"
            />
        </PageContent>
    );
};

export default ProposalCreatePage;