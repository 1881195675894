import { useState } from "react";
import { GigApiPaginatedData } from "./common/apiResponse";
import { useGiggedApiSWR, UseGiggedApiSWRReturn } from "./common/giggedApiSWR";
import { SortOrder } from "./common/sortOrder";
import { RoleAssignmentSummaryDto } from "../models/app/roleAssignment";
import { ApiRoleAssignmentSummaryDto } from "../models/api/roleAssignment";
import { mapFromApiRoleAssignmentSummaryDto } from "../models/mappers/roleAssignment";

export type UseRoleAssignmentsReturn =
    Omit<UseGiggedApiSWRReturn<GigApiPaginatedData<ApiRoleAssignmentSummaryDto>>, "data"> & {
        roleAssignments: RoleAssignmentSummaryDto[]
        sortOrder:  SortOrder
        orderBy: string
        pageIndex?: number
        pageSize?: number
        totalCount?: number
        setSearchTerm: (searchTerm?: string) => void
        setSortOrder: React.Dispatch<React.SetStateAction<SortOrder>>
        setOrderBy: React.Dispatch<React.SetStateAction<string>>
        setPageIndex: (pageIndex: number) => void
        setPageSize: (pageSize: number) => void
        setRoleAssignmentStatusIds: (statusIds: number[]) => void
    };

export const useRoleAssignments = (initialPageSize = 10): UseRoleAssignmentsReturn => {
    const [searchTerm, setSearchTerm] = useState<string | undefined>();
    const [orderBy, setOrderBy] = useState("DateSent");
    const [sortOrder, setSortOrder] = useState<SortOrder>("desc");
    const [pageIndex, setPageIndex] = useState(0);
    const [pageSize, setPageSize] = useState(initialPageSize);
    const [roleAssignmentStatusIds, setRoleAssignmentStatusIds] = useState<number[]>([]);

    const url = "api/me/role-assignments?" +
        `pageIndex=${pageIndex}&` +
        `pageSize=${pageSize}&` +
        `orderBy=${orderBy}&` +
        `sortOrder=${sortOrder}&` +
        `searchTerm=${searchTerm || ""}&` +
        (roleAssignmentStatusIds.length > 0 ? `&roleAssignmentStatusIds=${roleAssignmentStatusIds.join(",")}&` : "");

    const apiCall = useGiggedApiSWR<GigApiPaginatedData<ApiRoleAssignmentSummaryDto>>(url);

    const roleAssignments = apiCall.data ? apiCall.data.items.map(mapFromApiRoleAssignmentSummaryDto) : [];

    return {
        ...apiCall,
        ...apiCall.data,
        roleAssignments,
        sortOrder,
        orderBy,
        setSearchTerm,
        setSortOrder,
        setOrderBy,
        setPageIndex,
        setPageSize,
        setRoleAssignmentStatusIds,
    };
};