import rawConfig from "./config.json";

const config = {
    giggedApiBaseUrl: rawConfig.GiggedApiBaseUrl,
    firebase: rawConfig.Firebase,
    allowedImageFileExtensions: [
        "tif",
        "pjp",
        "xbm",
        "jxl",
        "svgz",
        "jpg",
        "jpeg",
        "ico",
        "tiff",
        "gif",
        "svg",
        "jfif",
        "webp",
        "png",
        "bmp",
        "pjpeg",
        "avif"
    ],
    allowedFileExtensions: [
        "pdf",
        "doc",
        "docx",
        "xls",
        "xlsx",
        "ppt",
        "pptx",
        "csv",
        "zip",
        "mov",
        "eps",
        "ai",
        "m4v",
        "mp4",
        "mp3",
        "3gp",
        "opus",
        "ogg",
        "oga",
        "wmv",
        "avi",
        "text",
        "txt",
        "rar",
        "stl",
        "obj",
        "iges",
        "psd",
        "dwg",
        "indd",
        "prpoj",
        "aep",
    ]
};

Object.freeze(config);

export default config;