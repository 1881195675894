import { useEffect, useState } from "react";
import { ReduxConversation } from "../../../store/reducers/conversationReducer";
import { ReduxMedia, ReduxMessage } from "../../../store/reducers/messageListReducer";
import { ReduxParticipant } from "../../../store/reducers/participantsReducer";
import MessageListItem from "./MessageListItem";
import { formatDate, formatTime } from "../../../utils/dateFormatters";
import { JSONObject } from "@twilio/conversations";
import { getBlobFile, getMessageStatus } from "../../../api/conversations";
import { actionCreators, AppState } from "../../../store";
import { useDispatch, useSelector } from "react-redux";
import { saveAs } from "file-saver";
import { addNotifications } from "../../../store/action-creators";
import { getSdkMediaObject } from "../../../utils/conversationsHelpers";
import { bindActionCreators } from "redux";

export type MessageListProps = {
    messages: ReduxMessage[]
    conversation: ReduxConversation
    participants: ReduxParticipant[]
    lastReadIndex: number
};

export type DatedMessageList = {
    [Key: string]: ReduxMessage[];
}

function getMessageTime(message: ReduxMessage) {
    return formatTime(message.dateCreated ?? new Date());
}

function getMessageType(message: ReduxMessage) {
    return message?.attributes ? (message?.attributes as JSONObject)["type"]?.toString() : "";
}

function getMessageGigId(message: ReduxMessage) {
    return message?.attributes ? (message?.attributes as JSONObject)["gigId"]?.toString() : "";
}

function getMessageProposalId(message: ReduxMessage) {
    return message?.attributes ? (message?.attributes as JSONObject)["proposalId"]?.toString() : "";
}

function getMessageRoleId(message: ReduxMessage) {
    return message?.attributes ? (message?.attributes as JSONObject)["roleId"]?.toString() : "";
}

function getMessageApplicationId(message: ReduxMessage) {
    return message?.attributes ? (message?.attributes as JSONObject)["applicationId"]?.toString() : "";
}


const MessageList = ({
    messages,
    participants,
    conversation
}: MessageListProps) => {
    if (messages === undefined) {
        return <div>Nothing to see here.</div>;
    }

    const dispatch = useDispatch();

    const {
        addAttachment,
    } = bindActionCreators(actionCreators, dispatch);

    const [messagesGroupedByDate, setMessagesGroupedByDate] = useState<DatedMessageList>();

    const conversationAttachments = useSelector(
        (state: AppState) => state.attachments[conversation.sid]
    );

    const groupMessagesByDate = () => {
        const tempMessages: DatedMessageList = {};

        messages.forEach((element) => {
            if (!element.dateCreated) return;

            const tempDate = `${element.dateCreated.getFullYear()}-${element.dateCreated.getMonth() + 1}-${element.dateCreated.getDate()}`;

            if (!tempMessages[tempDate]) {
                tempMessages[tempDate] = [];
            }

            // Find the correct position to insert the message based on the index value
            const insertIndex = tempMessages[tempDate].findIndex((msg) => msg.index > element.index);
            if (insertIndex === -1) {
                tempMessages[tempDate].push(element);
            } else {
                tempMessages[tempDate].splice(insertIndex, 0, element);
            }
        });

        return tempMessages;
    };

    const onDownloadAttachments = async (message: ReduxMessage) => {
        const attachedMedia = message.attachedMedia?.map(getSdkMediaObject);
        if (message.index === -1) {
            return undefined;
        }
        if (!attachedMedia?.length) {
            return new Error("No media attached");
        }

        for (const media of attachedMedia) {
            const blob = await getBlobFile(media, addNotifications);
            addAttachment(conversation.sid, message.sid, media.sid, blob);
        }

        return;
    };

    const onFileOpen = (file: Blob, { filename }: ReduxMedia) => {
        saveAs(file, filename ?? "");
    };

    useEffect(() => {
        setMessagesGroupedByDate(groupMessagesByDate());
    }, [messages]);

    return (
        <>
            {
                messagesGroupedByDate && Object.keys(messagesGroupedByDate).map((date) => {
                    return (
                        <div className="w-full" key={date}>
                            <p className="w-full text-center text-secondary-text mt-8">{formatDate(new Date(date), true)}</p>
                            {messagesGroupedByDate[date] != undefined && messagesGroupedByDate[date].map((message: ReduxMessage, index) => (
                                <div key={message.sid + "message" + index}>
                                    <MessageListItem
                                        text={message.body ?? ""}
                                        participant={participants.find(participant =>
                                            participant.sid === message.participantSid)}
                                        messageTime={getMessageTime(message) || ""}
                                        messageType={getMessageType(message)}
                                        messageGigId={getMessageGigId(message)}
                                        messageRoleId={getMessageRoleId(message)}
                                        messageProposalId={getMessageProposalId(message)}
                                        messageApplicationId={getMessageApplicationId(message)}
                                        getStatus={getMessageStatus(message, participants)}
                                        messageMedia={message.attachedMedia}
                                        onDownload={async () => await onDownloadAttachments(message)}
                                        onOpen={(
                                            mediaSid: string,
                                            file: ReduxMedia
                                        ) => onFileOpen(
                                            conversationAttachments?.[message.sid][mediaSid],
                                            file
                                        )}
                                    />
                                </div>
                            ))}
                        </div>
                    );
                })
            }
        </>
    );
};

export default MessageList;