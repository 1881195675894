import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { b2cPolicies } from "../auth/authConfig";
import { useAuthContext } from "../auth/authContext";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppPaths } from "../Routes";
import Loader from "./Loader";
import useCurrentUser from "../auth/useCurrentUser";

const LoginPage = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const { instance } = useMsal();
    const { loginRequest } = useAuthContext();
    const isAuthenticated = useIsAuthenticated();
    const navigate = useNavigate();
    const appPaths = useAppPaths();
    const user = useCurrentUser();
    const { userRole, idpUserId } = user;
    const isAdmin = !userRole && idpUserId;

    useEffect(() => {
        if (isAuthenticated) {
            isAdmin ? navigate(appPaths.gigs.findAGig) : navigate(appPaths.dashboard);
            setIsLoading(false);
            return;
        }

        instance.loginRedirect({
            ...loginRequest,
            authority: b2cPolicies.authorities.signUpSignIn.authority
        });

    }, [instance, isAuthenticated, navigate, isAdmin]);
    
    return (
        <>{isLoading ? <Loader /> : null}</>
    );
};

export default LoginPage;