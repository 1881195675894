import clsx from "clsx";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../components/Button";
import Card from "../components/Card";
import DialogBox, { useDialogBoxState } from "../components/DialogBox";
import PageContent from "../components/PageContent";
import Typography from "../components/Typography";
import { useRoleId } from "../Routes";
import { useAppPaths } from "../Routes";
import { successToast } from "../toast";
import Loader from "../components/Loader";
import { useRole } from "../api/role";
import { RoleFormFields, RoleFormValues, useRoleForm } from "../components/RoleForm";
import ApiError from "../api/common/apiError";
import GeneralFormError from "../components/GeneralFormError";
import { roleStatuses } from "../models/app/role";
import { GigApiFetcherResponse } from "../api/common/fetching";

const RoleEditPage = () => {
    const [roleLoaded, setRoleLoaded] = useState(false);
    const appPaths = useAppPaths();
    const roleId = useRoleId();
    const confirmCancelDialogState = useDialogBoxState();
    const navigate = useNavigate();
    const { role, updateRole, isUpdatingRole } = useRole(roleId);
    const [submissionError, setSubmissionError] = useState<ApiError | undefined>();

    const methods = useRoleForm({
        ...role,
        title: role?.title || "",
        description: role?.description || "",
        roleCategoryId: role?.roleCategoryId || "",
        skills: role?.skills || [],
        roleStatusId: role?.roleStatusId || 1,
        roleAssignmentTypeId: -1,
        roleVisibilityTypeId: -1
    });

    const { reset, formState: { isDirty } } = methods;

    useEffect(() => {
        if (roleLoaded) return;

        reset({
            ...role,
            title: role?.title || "",
            description: role?.description || "",
            roleCategoryId: role?.roleCategoryId || "",
            skills: role?.skills || [],
        });

        if (role) {
            setRoleLoaded(true);
        }
    }, [reset, role, roleLoaded]);

    if (!role) return <Loader />;

    const handleSubmit = async (value: RoleFormValues, openAfterSaving: boolean): Promise<GigApiFetcherResponse<void>> => {
        setSubmissionError(undefined);

        const response = await updateRole({
            title: value.title,
            description: value.description, 
            roleCategoryId: value.roleCategoryId, 
            roleStatusId: value.roleStatusId,
            roleAssignmentTypeId: value.roleAssignmentTypeId,
            roleVisibilityTypeId: value.roleVisibilityTypeId,
            skillIds: value.skills.map(_ => _.id),
            openAfterSaving: openAfterSaving
        });

        if (!response.success) setSubmissionError(response.error);

        return response;
    };

    const handleSaveAndPost = async (values: RoleFormValues) => {
        setSubmissionError(undefined);

        const response = await handleSubmit(values, true);

        if (response.success) {
            successToast("Role updated successfully.");
            navigate(appPaths.roles.matches(role.id));
        }
    };

    const handleSaveAsDraft = methods.handleSubmit(async (values) => {
        setSubmissionError(undefined);

        const response = await handleSubmit(values, false);

        if (response.success) {
            successToast("Draft updated successfully.");
            navigate(appPaths.roles.draft);
        }
    });

    const isDraft = role.roleStatusId === roleStatuses.draft;
    const isOpen = role.roleStatusId === roleStatuses.open;

    const handleDiscardChanges = () => {
        navigate(isOpen ?
            appPaths.roles.open :
            appPaths.roles.draft);    
    };

    return (
        <PageContent className="sm:pt-8 pt-4">
            <Card>
                <Typography variant="display-medium" component="h1" gutterBottom>Edit role</Typography>
                <form onSubmit={methods.handleSubmit(handleSaveAndPost)} noValidate>
                    <RoleFormFields
                        methods={methods}
                        initialValues={role}
                    />

                    <GeneralFormError error={submissionError} className="ml-8" />
                    <div className={clsx(
                        "mb-0 mx-0 mt-8 space-y-4",
                        "md:flex md:flex-row-reverse md:justify-end md:space-y-0"
                    )}>
                        <Button
                            type="submit"
                            loading={isUpdatingRole}
                            disabled={!isDirty && !isDraft}
                            className="w-full md:w-auto"
                        >
                            {isDraft ? "Post role" : "Save changes"}
                        </Button>
                        {isDraft && (
                            <Button
                                type="button"
                                onClick={handleSaveAsDraft}
                                loading={isUpdatingRole}
                                className="w-full md:w-auto md:mr-4"
                                variant="secondary"
                            >
                                Save draft
                            </Button>
                        )}
                        <Button type="button" disabled={isUpdatingRole} className="w-full md:w-auto md:mr-auto" variant="tertiary" onClick={confirmCancelDialogState.open}>Discard changes</Button>
                    </div>
                </form>
                <DialogBox
                    {...confirmCancelDialogState}
                    title={`Discard changes to ${methods.watch("title")}?`}
                >
                    <div className="space-y-4">
                        <Button className="block mx-auto w-full" type="button" onClick={handleDiscardChanges}>Discard changes</Button>
                        <Button className="block mx-auto w-full" type="button" variant="secondary" onClick={confirmCancelDialogState.close}>Back to edit role</Button>
                    </div>
                </DialogBox>

            </Card>
        </PageContent>
    );
};

export default RoleEditPage;
