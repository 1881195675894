import { useState } from "react";
import { GigApiPaginatedData } from "./common/apiResponse";
import { GigApiFetcherResponse, useAuthenticatedGigApiFetcher } from "./common/fetching";
import { useGiggedApiSWR, UseGiggedApiSWRReturn } from "./common/giggedApiSWR";
import { SortOrder } from "./common/sortOrder";
import { useStateParams } from "../hooks/useStateParams";
import { ApiCreateRoleDto, ApiRoleSummaryDto } from "../models/api/role";

const useCreateRole = () => useAuthenticatedGigApiFetcher<ApiCreateRoleDto, string, ApiCreateRoleDto>("POST",
    (dto) => ({
        url: "api/me/roles",
        body: dto,
    }));

export type UseRolesParams = {
    initialPageSize?: number
    initialOrderBy?: string
    initialSortOrder?: SortOrder
    allClients?: boolean
    pauseFetching?: boolean
    giggedClientId?: string
    roleStatusIds?: number[]
}

export type UseRolesReturn =
    Omit<UseGiggedApiSWRReturn<GigApiPaginatedData<ApiRoleSummaryDto>>, "data"> &
    Partial<Omit<UseGiggedApiSWRReturn<GigApiPaginatedData<ApiRoleSummaryDto>>["data"], "items">> &
    {
        roles: ApiRoleSummaryDto[]
        sortOrder: SortOrder
        orderBy: string
        skillIds: string[]
        searchTerm: string
        pauseFetching?: boolean
        setSearchTerm: (value: string) => void
        setSkillIds: (skillIds: string[]) => void
        setSortOrder: (value: SortOrder) => void
        setOrderBy: (value: string) => void
        setPageIndex: (value: number) => void
        setPageSize: (value: number) => void
        createRole: (dto: ApiCreateRoleDto) => Promise<GigApiFetcherResponse<string>>
        refetchRoles: () => void
        isCreatingRole: boolean
    }

export type UseRoles = (params?: UseRolesParams) => UseRolesReturn;

export const useRoles: UseRoles = ({
    initialPageSize,
    initialOrderBy,
    initialSortOrder,
    giggedClientId,
    allClients,
    pauseFetching,
    roleStatusIds
} = {}) => {
    const [searchTerm, setSearchTerm] = useStateParams("", "searchTerm", "string");
    const [skillIds, setSkillIds] = useStateParams<string[]>([], "skillIds", "stringArray");
    const [sortOrder, setSortOrder] = useState<SortOrder>(initialSortOrder || "asc");
    const [orderBy, setOrderBy] = useState<string>(initialOrderBy || "Title");
    const [pageIndex, setPageIndex] = useState(0);
    const [pageSize, setPageSize] = useState(initialPageSize || 100);

    const searchTermQuery = searchTerm && searchTerm !== "" ? `&searchTerm=${searchTerm}` : "";
    const skillsQuery = skillIds.length > 0 ? `&skillIds=${skillIds.join(",")}` : "";
    const roleStatusQuery = roleStatusIds && roleStatusIds.length > 0 ? `&roleStatusIds=${roleStatusIds?.join(",")}` : "";


    const url = `api/${allClients ? "" : "me/"}roles?` +
        `pageIndex=${pageIndex}` +
        `&pageSize=${pageSize}` +
        `&sortOrder=${sortOrder}` +
        `&orderBy=${orderBy}` +
        (giggedClientId ? `&giggedClientId=${giggedClientId}` : "") +
        searchTermQuery +
        skillsQuery +
        roleStatusQuery;

    const { data: apiCallData, ...apiCall } =
        useGiggedApiSWR<GigApiPaginatedData<ApiRoleSummaryDto>>(pauseFetching ? null : url);

    const [createRole, isCreatingRole] = useCreateRole();

    const roles = apiCallData?.items || [];

    return {
        ...apiCall,
        ...apiCallData,
        roles,
        skillIds,
        searchTerm,
        sortOrder,
        orderBy,
        setSearchTerm,
        setSkillIds,
        setSortOrder,
        setOrderBy,
        setPageIndex,
        setPageSize,
        refetchRoles: apiCall.refetch,
        createRole: async (dto: ApiCreateRoleDto) => {
            const response = await createRole(dto);

            if (response.success) {
                apiCall.mutate();
            }

            return response;
        },
        isCreatingRole,
    };
};