import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { isValidOptionalUrl } from "../../utils/yup-validators/isValidOptionalUrl";

const buildNullableUrlValidator = () => yup.string()
    .max(2048)
    .test(isValidOptionalUrl)
    .nullable();

const schemaFields = {
    portfolioUrl: buildNullableUrlValidator(),
    gitHubUrl: buildNullableUrlValidator(),
    linkedInUrl: buildNullableUrlValidator(),
};

export type AddedExtrasFromValues = {
    portfolioUrl?: string
    gitHubUrl?: string,
    linkedInUrl?: string
}

const validationSchema = yup.object(schemaFields);

export const useAddedExtrasForm = (defaultValues?: AddedExtrasFromValues) => {
    return useForm<AddedExtrasFromValues>({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            portfolioUrl: defaultValues?.portfolioUrl || "",
            gitHubUrl: defaultValues?.gitHubUrl || "",
            linkedInUrl: defaultValues?.linkedInUrl || "",
        },
    });
};
