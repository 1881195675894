import clsx from "clsx";
import { Briefcase, FolderOpen } from "phosphor-react";
import { Link } from "react-router-dom";
import Typography from "../Typography";


export type LargeDashboardButtonProps = {
    Icon: typeof Briefcase | typeof FolderOpen
    name: React.ReactNode
    href: string
    variant?: "dark" | "light" | "primary"
}

const LargeDashboardButton = ({
    Icon,
    name,
    href,
    variant
}: LargeDashboardButtonProps) => {
    return (
        <Link to={href} className={clsx(
            "flex flex-col bg-white h-48 p-6 rounded-lg child:cursor-pointer w-full md:max-w-[33%]",
            variant === "dark" && "!bg-secondary",
            variant === "primary" &&  "!bg-primary"
        )}>
            <Icon 
                size={32} 
                weight="duotone" 
                className={clsx(
                    variant === "dark" && "text-secondary-bg-text"
                )}
            />
            <Typography 
                variant="headline-small" 
                component="label" 
                className={clsx(
                    "mt-auto",
                    variant === "dark" && "text-secondary-bg-text"
                )}
            >
                {name}
            </Typography>
        </Link>
    );
};

export default LargeDashboardButton;
