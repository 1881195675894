import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";

import Button from "../Button";
import FormRadioGroup from "../FormRadioGroup";
import { useOrganizationContext } from "../../api/current-organization/organizationContext";
import { RoleFormValues, buildRoleFormValidationSchemaFields } from "../RoleForm";
import { roleVisibilityOptions } from "../../models/app/role";
import { formClassNames } from "../CreateGigForm/classNames";
import CreateFormPageLayout from "../CreateGigForm/CreateGigFormPageLayout";

export type CreateRoleFormStepVisibility = Pick<RoleFormValues, "roleVisibilityTypeId">;

export type CreateRoleFormStepVisibilityProps = {
    onSubmit: (value: CreateRoleFormStepVisibility) => void
    onBackClick: (value: CreateRoleFormStepVisibility) => void
    defaultValues: CreateRoleFormStepVisibility
}

const CreateRoleFormStepVisibility = ({
    onSubmit,
    onBackClick,
    defaultValues,
}: CreateRoleFormStepVisibilityProps) => {
    const { giggedClientTerminology } = useOrganizationContext();
    const schema = buildRoleFormValidationSchemaFields();

    const validationSchema: yup.SchemaOf<CreateRoleFormStepVisibility> = yup.object({
        roleVisibilityTypeId: schema.fields.roleVisibilityTypeId,
    });

    const methods = useForm<CreateRoleFormStepVisibility>({
        resolver: yupResolver(validationSchema),
        defaultValues,
    });
    
    const handleBackClick = () => {
        onBackClick(methods.getValues());
    };

    return (
        <form
            className={formClassNames}
            onSubmit={methods.handleSubmit(onSubmit)}
            noValidate
        >
            <CreateFormPageLayout
                title={"Role visibility"}
                description={`Is this role available to your ${giggedClientTerminology.toLowerCase()} only or organisation-wide?`}
                content={
                    <Controller
                        name="roleVisibilityTypeId"
                        control={methods.control}
                        render={({ field: { onChange, value } }) => (
                            <FormRadioGroup
                                required
                                label={"Role visibility"}
                                options={roleVisibilityOptions}
                                error={methods.formState.errors.roleVisibilityTypeId}
                                defaultValue={value}
                                onChange={onChange}
                                value={value}
                            />
                        )}
                    />
                }
                iconName="Eye"
                buttons={
                    <>
                        <Button variant="secondary" onClick={handleBackClick} type="button">Back</Button>
                        <Button type="submit">Next</Button>
                    </>
                }
            />
        </form>
    );
};

export default CreateRoleFormStepVisibility;