import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { GigApiFetcherResponse } from "../../api/common/fetching";
import { useOrganizationContext } from "../../api/current-organization/organizationContext";
import Button from "../../components/Button";
import DialogBox, { useDialogBoxState } from "../../components/DialogBox";
import FormDialogBox, { FormDialogBoxProps } from "../../components/FormDialogBox";
import FormTextInput from "../../components/FormTextInput";
import { talentProposalTooltips } from "../../tooltipsContent";
import { ProposalMilestoneFormValues, validationSchemaFields } from "./ProposalMilestoneFormValues";

const validationSchema = yup.object(validationSchemaFields);

export type ProposalMilestoneFormProps = Omit<FormDialogBoxProps, "title" | "children"> & {
    initialValues: ProposalMilestoneFormValues
    resetOnSubmit?: boolean
    isEdit?: boolean
    onSubmit: (values: ProposalMilestoneFormValues) => Promise<GigApiFetcherResponse<unknown>>
}

export const ProposalMilestoneFormDialog = ({
    initialValues,
    resetOnSubmit,
    isEdit,
    onSubmit,
    ...dialogProps
}: ProposalMilestoneFormProps) => {
    const { gigTerminology } = useOrganizationContext();

    const methods = useForm<ProposalMilestoneFormValues>({
        defaultValues: initialValues,
        resolver: yupResolver(validationSchema)
    });

    const [isSubmitting, setIsSubmitting] = useState(false);
    const { isDirty } = methods.formState;

    const confirmCancelDialogState = useDialogBoxState();
    const { onClose } = dialogProps;

    const handleSubmit = async (values: ProposalMilestoneFormValues) => {
        setIsSubmitting(true);

        try {
            const result = await onSubmit(values);

            if (result.success) {
                methods.reset(resetOnSubmit ? initialValues : values);
                methods.clearErrors();
                onClose();
            }
        }
        finally {
            setIsSubmitting(false);
        }
    };

    const handleCancelButton = () => {
        if (isDirty) {
            confirmCancelDialogState.open();
            return;
        }
        methods.clearErrors();
        onClose();
    };

    const handleDiscardChanges = () => {
        confirmCancelDialogState.close();
        methods.reset(initialValues);
        methods.clearErrors();
        onClose();
    };

    return (
        <>
            <FormDialogBox
                {...dialogProps}
                isOpen={dialogProps.isOpen && !confirmCancelDialogState.isOpen}
                onClose={handleCancelButton}
                title="Proposal Milestone"
            >
                <form onSubmit={methods.handleSubmit(handleSubmit)} className="space-y-6">
                    <div className="space-y-4">
                        <FormTextInput
                            required
                            tooltip={talentProposalTooltips.milestoneDescription(gigTerminology)}
                            label="Description"
                            placeholder="Enter milestone description"
                            type="text"
                            error={methods.formState.errors.description}
                            {...methods.register("description")}
                        />
                    </div>
                    <div className="flex flex-col-reverse md:flex-row justify-end items-center md:space-x-4">
                        <Button type="button" disabled={isSubmitting} className="w-full md:w-auto" variant="tertiary" onClick={handleCancelButton}>Cancel</Button>
                        <Button type="button" disabled={isEdit && !isDirty} loading={isSubmitting} className="w-full mb-4 md:mb-0 md:w-auto" variant="primary" onClick={methods.handleSubmit(handleSubmit)}>Save milestone</Button>
                    </div>
                </form>
            </FormDialogBox>
            <DialogBox {...confirmCancelDialogState} title="Discard changes?">
                <div className="flex items-center sm:justify-end sm:space-x-4 flex-col-reverse sm:flex-row">
                    <Button className="w-full sm:w-fit mt-4 sm:mt-0" type="button" variant="secondary" onClick={confirmCancelDialogState.close}>Back</Button>
                    <Button className="w-full sm:w-fit" type="button" onClick={handleDiscardChanges}>Discard</Button>
                </div>
            </DialogBox>
        </>
    );
};