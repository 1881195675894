import { useEffect, useState } from "react";

import { useOrganizationContext } from "../../api/current-organization/organizationContext";
import Button from "../../components/Button";
import PageContent from "../../components/PageContent";
import Typography from "../../components/Typography";
import { useAppPaths, useRoleId } from "../../Routes";
import MatchesList from "../../components/MatchesList";
import Loader from "../../components/Loader";
import LinkButton from "../../components/LinkButton";
import TitleUnderline from "../../components/TitleUnderline";
import { CircularProgress } from "@mui/material";
import { useRole } from "../../api/role";
import RoleDetailsCard from "../../components/RoleDetailsCard";
import { InviteFormDialogValues } from "../../components/InviteButton/InviteFormDialogValues";
import { successToast } from "../../toast";
import { roleStatuses } from "../../models/app/role";

const RolesMatchesPage = () => {
    const appPaths = useAppPaths();
    const roleId = useRoleId();
    const { role, isLoading, regenerateMatches, isRegeneratingMatches, reload, inviteToRole, isInvitingToRole } = useRole(roleId);
    const [forceReload, setForceReload] = useState(false);
    const { talentTerminology } = useOrganizationContext();

    useEffect(() => {
        const interval = setInterval(() => {
            setForceReload(value => !value);
        }, 5000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (isLoading) return;
        if (role?.isFetchingMatches) reload();
    }, [isLoading, forceReload]);

    const handleConfirmInvite = async (values: InviteFormDialogValues, talentId: string) => {
        const response = await inviteToRole({
            message: values.message,
            talentId,
        });

        if (response.success) successToast(`${talentTerminology} invited successfully!`);

        return response;
    };

    const canInvite = (role?.roleStatusId === roleStatuses.open) || (role?.roleStatusId === roleStatuses.active && role?.allowMultipleHires);

    if (isLoading || !role) return <Loader />;

    return (
        <PageContent>
            <Typography variant="display-medium" component="h1" gutterBottom>Role</Typography>
            <div className="mb-8">
                <RoleDetailsCard role={role} />
            </div>
            <div className="sm:flex items-center mb-4 space-y-4 sm:space-y-0">
                <Typography variant="display-small" component="h2">Matches</Typography>
                <Button className="ml-auto" variant="secondary" onClick={regenerateMatches} disabled={isRegeneratingMatches || role.isFetchingMatches}>Refresh matches</Button>
            </div>
            {(isRegeneratingMatches || role.isFetchingMatches) ? (
                <div className="mt-6 sm:mt-8 text-center">
                    <CircularProgress className="mx-auto block" />
                    <Typography className="mt-4 text-center" variant="title-medium" component="p">Your role matches are now being generated</Typography>
                    <Typography className="mt-4 text-center" variant="body" component="p">This usually takes less than 30 seconds to complete.</Typography>
                </div>
            ) : (
                <>
                    {/* {role.matches.length !== 0 && <MatchesInfo />} */}
                    <div className="flex flex-col space-y-8 my-8">
                        <MatchesList
                            id={role.id}
                            matches={role.matches}
                            onSubmit={handleConfirmInvite}
                            isSubmitting={isInvitingToRole}
                            canInvite={canInvite}
                            showCurrentRole={true}
                            roleAssignmentTypeId={role.roleAssignmentTypeId}
                            // externalMatches={role.externalMatches?.matches || []}
                        />
                    </div>
                    {/* <div className="flex justify-center py-6">
                        <TitleUnderline />
                    </div>
                    <div className="text-center">
                        <div className="flex justify-center">
                            <LinkButton to={appPaths.talents.findTalent}>Search here</LinkButton>
                        </div>
                    </div> */}
                </>
            )}
        </PageContent>
    );
};

export default RolesMatchesPage;