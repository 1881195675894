import { useTalentRoleInvites } from "../api/talentRoleInvites";
import Card from "../components/Card";
import LinkButton from "../components/LinkButton";
import Loader from "../components/Loader";
import PageContent from "../components/PageContent";
import Typography from "../components/Typography";
import { useIsLargeScreen } from "../hooks/screenSizes";
import { useAppPaths } from "../Routes";

const RoleInvitesPage = () => {
    const appPaths = useAppPaths();

    const { roleInvites, isLoading } = useTalentRoleInvites();
    const isLargeScreen = useIsLargeScreen();

    if (isLoading) return <Loader />;

    return (
        <PageContent>
            <Typography variant={isLargeScreen ? "display-large" : "display-small"} component="h1" gutterBottom>Role Invites</Typography>
            {roleInvites.length === 0 ? (
                <Card>
                    <Typography variant="title-large" component="p">None yet...</Typography>
                </Card>
            ) : (
                <div className="space-y-8">
                    {roleInvites.map(roleInvite => (
                        <Card key={roleInvite.roleId} className="flex flex-col md:flex-row text-center md:text-left items-center grow md:mr-4 rounded-b-none md:rounded-b-lg pb-2 md:pb-6">
                            <div className="md:mr-8">
                                <Typography variant="title-large" component="p" className="mb-4">{roleInvite.roleTitle}</Typography>
                                <p className="mb-2">
                                    <span className="font-bold">Sent on: </span>
                                    {roleInvite.dateSent.toLocaleDateString()}</p>
                                <p className="md:mr-8">
                                    <span className="font-bold">Message: </span>
                                    <span className="italic whitespace-pre-wrap">{roleInvite.message}</span>
                                </p>
                            </div>
                            <LinkButton className="block md:ml-auto" to={appPaths.roles.findARoleDetails(roleInvite.roleId)} variant="tertiary">View role</LinkButton>
                        </Card>
                    ))}
                </div>
            )
            }
        </PageContent>
    );
};

export default RoleInvitesPage;