import { SortOrder } from "../../api/common/sortOrder";


const roleOrderByFields = [
    { value: "Title", label: "Title" },
    { value: "CreatedAt", label: "Created At" },
] as const;

export type RoleOrderByFieldKeys = typeof roleOrderByFields[number]["value"];

export const roleOrderByOptions = roleOrderByFields.flatMap(({ value, label }): {
    value: `${RoleOrderByFieldKeys}:${SortOrder}`
    label: string
}[] => {
    return [
        {
            value: `${value}:asc`,
            label: `Sort by ${label.toLowerCase()} ASC`,
        },
        {
            value: `${value}:desc`,
            label: `Sort by ${label.toLowerCase()} DESC`,
        },
    ];
});

export const roleStatuses = {
    inReview: 0,
    draft: 1,
    open: 2,
    active: 3,
    closed: 4,
    archived: 5,
};

export const roleVisibilityOptions = [
    { label: "Department only", value: 0 },
    { label: "Organisation-wide", value: 1 }
];

export const roleAssignmentTypeOptions = [
    { label: "Open to applications", value: 0 },
    { label: "Assignment only", value: 1 },
];

export const roleAssignmentTypes = {
    applications: 0,
    assignment: 1
};

export const roleVisibilityTypes = {
    department: 0,
    organisation: 1
};