import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";

import Button from "../Button";
import CreateFormPageLayout from "./CreateGigFormPageLayout";
import FormDateInput from "../FormDateInput";
import { GigFormValues, buildGigFormValidationSchemaFields } from "../GigForm";
import { formClassNames } from "./classNames";
import { useOrganizationContext } from "../../api/current-organization/organizationContext";

export type CreateGigFormStep3StartDateFields = Pick<GigFormValues, "startDate">;

export type CreateGigFormStep3StartDateProps = {
    onSubmit: (value: CreateGigFormStep3StartDateFields) => void
    onBackClick: (value: CreateGigFormStep3StartDateFields) => void
    defaultValues: CreateGigFormStep3StartDateFields
}

const CreateGigFormStep3StartDate = ({
    onSubmit,
    onBackClick,
    defaultValues,
}: CreateGigFormStep3StartDateProps) => {
    const { gigTerminology } = useOrganizationContext();

    const validationSchema: yup.SchemaOf<CreateGigFormStep3StartDateFields> = yup.object({
        startDate: buildGigFormValidationSchemaFields.startDate,
    });

    const methods = useForm<CreateGigFormStep3StartDateFields>({
        resolver: yupResolver(validationSchema),
        defaultValues
    });

    const handleBackClick = () => {
        onBackClick(methods.getValues());
    };

    return (
        <form
            className={formClassNames}
            onSubmit={methods.handleSubmit(onSubmit)}
            noValidate
        >
            <CreateFormPageLayout
                title={`When do you need your ${gigTerminology.toLowerCase()} to begin?`}
                description={`This might not be set in stone, but will help to give a rough idea of how soon you are looking to get the ${gigTerminology.toLowerCase()} started.`}
                content={
                    <Controller
                        name="startDate"
                        control={methods.control}
                        render={({ field: { onChange, value } }) => (
                            <FormDateInput
                                required
                                id="create-gig-form-start-date"
                                label="Start date"
                                value={value}
                                onChange={onChange}
                                error={methods.formState.errors.startDate}
                                minDate={new Date()}
                            />
                        )}
                    />
                }
                iconName="CalendarCheck"
                buttons={
                    <>
                        <Button onClick={handleBackClick} variant="secondary" type="button">Back</Button>
                        <Button type="submit">Next</Button>
                    </>
                }
            />
        </form>
    );
};

export default CreateGigFormStep3StartDate;