import gigApiFetcher from "./common/fetching";
import { GigApiPaginatedData } from "./common/apiResponse";
import { ApiBadgeSummaryDto } from "../models/api/badge";

export const fetchBadges = async (
    query: string,
    accessToken: string | null,
    pageIndex = 0,
    pageSize = 100
): Promise<ApiBadgeSummaryDto[]> => {
    const response = await gigApiFetcher<never, GigApiPaginatedData<ApiBadgeSummaryDto>>({
        url: `api/badges?pageIndex=${pageIndex}&pageSize=${pageSize}&searchTerm=${query}`,
        method: "GET",
        body: undefined,
        token: accessToken || undefined,
    });

    return response.items;
};

export const fetchBadgeOptions = async (query: string, accessToken: string | null) => {
    const badgesResponse = await fetchBadges(query, accessToken);
    return badgesResponse
        .map(badge => ({
            value: badge.badgeId,
            label: badge.name,
        }));
};