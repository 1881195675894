import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { getSdkConversationObject } from "../../utils/conversationsObjects";
import { unexpectedErrorNotification } from "../../utils/conversationsHelpers";
import { actionCreators, AppState } from "../../store";
import { updateCurrentConversationSdk } from "../../api/conversations";
import { ReduxConversation } from "../../store/reducers/conversationReducer";
import MessagesContainer from "./Messages/MessagesContainer";
import { useIsMediumScreen } from "../../hooks/screenSizes";
import ConversationList from "./ConversationList/ConversationList";
import Loader from "../Loader";

export type InboxContainerProps = {
    openedConversation?: ReduxConversation
    conversations: ReduxConversation[]
}

const InboxContainer = ({
    openedConversation,
    conversations
}: InboxContainerProps) => {
    const sid = useSelector((state: AppState) => state.sid);
    const loadingStatus = useSelector((state: AppState) => state.loadingStatus);
    const messages = useSelector((state: AppState) => state.messages);
    const isMediumScreen = useIsMediumScreen();
    const [messageBoxInView, setMessageBoxInView] = useState(false);

    const dispatch = useDispatch();
    const {
        updateCurrentConversation,
        updateParticipants,
        updateUnreadMessages,
        setLastReadIndex,
        addNotifications,
    } = bindActionCreators(actionCreators, dispatch);

    if (loadingStatus) {
        return <Loader />;
    }

    const handleConversationClicked = async (conversation: ReduxConversation) => {
        setMessageBoxInView(true);
        try {
            setLastReadIndex(conversation.lastReadMessageIndex ?? -1);

            await updateCurrentConversationSdk(
                updateCurrentConversation,
                conversation,
                updateParticipants
            );

            updateUnreadMessages(conversation.sid, 0);

            const lastMessage =
                messages[conversation.sid].length &&
                messages[conversation.sid][messages[conversation.sid].length - 1];

            if (lastMessage && lastMessage.index !== -1) {
                await getSdkConversationObject(
                    conversation
                ).updateLastReadMessageIndex(lastMessage.index);
            }
        } catch {
            unexpectedErrorNotification(addNotifications);
        }
    };

    const handleBackClick = () => {
        setMessageBoxInView(false);
    };

    return (
        <>
            {isMediumScreen ? (
                <div className="flex flex-row overflow-hidden h-[85vh]">
                    <ConversationList conversations={conversations} messages={messages} handleConversationClicked={handleConversationClicked} sid={sid} />
                    <MessagesContainer conversation={openedConversation} />
                </div>
            ) : (
                <>
                    {messageBoxInView ? (
                        <MessagesContainer
                            conversation={openedConversation}
                            handleBackClick={handleBackClick}
                        />
                    ) : (
                        <div className="h-[85vh] overflow-auto">
                            <ConversationList conversations={conversations} messages={messages} handleConversationClicked={handleConversationClicked} sid={sid} />
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default InboxContainer;