import { Match } from "../models/app/match";
import Card from "./Card";
import { CardDataField } from "./Card";
import CardDataFieldLabel from "./Card/CardDataFieldLabel";
import TalentSkill from "./TalentSkill";
import InviteButton from "./InviteButton";
import UserAvatar from "./UserAvatar";
import clsx from "clsx";
import BadgeList from "./BadgeList";
import Button from "./Button";
import { Link } from "react-router-dom";
import { useAppPaths } from "../Routes";
import { InviteFormDialogValues } from "./InviteButton/InviteFormDialogValues";
import { GigApiFetcherResponse } from "../api/common/fetching";
import { useOrganizationContext } from "../api/current-organization/organizationContext";
import { useDialogBoxState } from "./DialogBox";
import { useRole } from "../api/role";
import { useAssignRoleForm } from "./RoleAssign/AssignRoleFormValues";
import AssignRoleFormDialog from "./RoleAssign/AssignRoleFormDialog";
import { ApiAssignRoleDto } from "../models/api/role";
import { successToast } from "../toast";
import { roleAssignmentTypes } from "../models/app/role";

export type TalentMatchCardProps = {
    id: string
    match: Match
    onSubmit: (values: InviteFormDialogValues, talentId: string) => Promise<GigApiFetcherResponse<void>>
    isSubmitting: boolean
    canInvite: boolean
    showCurrentRole?: boolean
    roleAssignmentTypeId?: number
}

const TalentMatchCard = ({
    id,
    match,
    onSubmit,
    isSubmitting,
    canInvite,
    showCurrentRole,
    roleAssignmentTypeId
}: TalentMatchCardProps) => {
    const matchValue = `${Math.round(match.score * 100)}%`;
    const appPaths = useAppPaths();
    const { giggedClientTerminology } = useOrganizationContext();
    const assignDialogBoxState = useDialogBoxState();
    const { assignRole, isAssigningRole } = useRole(id);
    const methods = useAssignRoleForm();
    const matchDisplayName = `${match?.talentFirstName} ${match?.talentLastName}`;
    const displayBadgeSection = match.badges.length > 0;


    const handleAssign = async (values: ApiAssignRoleDto) => {
        const response = await assignRole(values);

        if (response.success) {
            successToast("Role assignment submitted.");
        }

        return response;
    };

    const handleAssignDialogClose = () => {
        methods.reset();
        assignDialogBoxState.close();
    };

    return (
        <>
            <Card className={"max-w-full space-y-6 md:space-y-0 md:space-x-6 md:flex md:items-center flex-row"}>
                <div className="md:w-[230px] flex flex-col items-center space-y-2">
                    <UserAvatar name={matchDisplayName} userImageUrl={match.talentProfileImageThumbnailUrl} />
                    <Link to={appPaths.talents.findTalentDetails(match.talentId)} target="_blank"><Button className="w-fit" variant="tertiary">View profile</Button></Link>
                </div>
                <div className={clsx("grow space-y-3 text-left")}>
                    <div className="grid md:grid-cols-3 grid-cols-2 gap-3">
                        <CardDataField label={giggedClientTerminology}>
                            {match.talentCurrentGiggedClientName || "No current department"}
                        </CardDataField>
                        {showCurrentRole && (
                            <CardDataField label="Role">
                                {match.talentCurrentRoleTitle || "No current role"}
                            </CardDataField>
                        )}
                        <CardDataField label="Match level">
                            {matchValue}
                        </CardDataField>
                    </div>
                    <div>
                        <CardDataFieldLabel className="text-left">Relevant skills</CardDataFieldLabel>
                        <div className="mt-2 space-y-2 md:max-w-xl text-left">
                            {match.matchedSkills.map(skill => (
                                <TalentSkill key={skill.skillName} skinnyProgress {...skill} />
                            ))}
                        </div>
                    </div>
                    {displayBadgeSection &&
                        <div>
                            <CardDataFieldLabel className="text-left mb-2">Badges</CardDataFieldLabel>
                            <BadgeList badges={match.badges} keyPrefix={match.talentId.toString()} />
                        </div>
                    }
                </div>
                <div className="flex flex-col gap-4">
                    {(roleAssignmentTypeId && roleAssignmentTypeId === roleAssignmentTypes.assignment) ? (
                        <Button
                            disabled={isAssigningRole || match.isAssigned}
                            loading={isAssigningRole}
                            onClick={assignDialogBoxState.open}
                            variant="primary"
                        >
                            {match.isAssigned ? "Pending" : "Assign"}
                        </Button>
                    ) : null}
                    {canInvite && 
                        (roleAssignmentTypeId === roleAssignmentTypes.applications || !roleAssignmentTypeId) && (
                        <InviteButton
                            talentId={match.talentId}
                            isInvited={match.isInvited}
                            id={id}
                            className="block"
                            onSubmit={onSubmit}
                            isLoading={isSubmitting}
                        />
                    )}
                </div>
            </Card>
            <AssignRoleFormDialog
                {...assignDialogBoxState}
                isOpen={assignDialogBoxState.isOpen}
                onClose={handleAssignDialogClose}
                closeDialog={assignDialogBoxState.close}
                onAssign={handleAssign}
                talentId={match.talentId}
                loading={isAssigningRole}
                methods={methods}
            />
        </>
    );
};

export default TalentMatchCard;
