import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import _ from "lodash";
import { useEffect, useState } from "react";
import { ReduxConversation } from "../../../store/reducers/conversationReducer";
import { ReduxMessage } from "../../../store/reducers/messageListReducer";
import { truncateMiddle, calculateUnreadMessagesWidth, getLastMessageTime } from "../../../utils/conversationsHelpers";
import { getParticipantName } from "../../../api/conversations";
import useCurrentUser from "../../../auth/useCurrentUser";

TimeAgo.addDefaultLocale(en);

export type ConversationListItemProps = {
    conversation: ReduxConversation
    messages: ReduxMessage[]
    unreadMessagesCount: number
    currentConversationSid: string
    onClick: () => void
}

const ConversationListItem = ({
    conversation,
    messages,
    unreadMessagesCount,
    currentConversationSid,
    onClick,
}: ConversationListItemProps) => {
    const [backgroundColor, setBackgroundColor] = useState("blue");
    const title = truncateMiddle(
        conversation.friendlyName ?? conversation.sid,
        calculateUnreadMessagesWidth(unreadMessagesCount)
    );

    const time = getLastMessageTime(messages);
    const lastMessage = _.last(messages);
    const { userRole } = useCurrentUser();
    const currentUserRole = userRole || "";
    const participantName = getParticipantName(conversation, currentUserRole);

    useEffect(() => {
        if (currentConversationSid === conversation.sid) {
            setBackgroundColor("#eff3f8");
        } else {
            setBackgroundColor("white");
        }
    }, [currentConversationSid, conversation.sid]);

    return (
        <div
            onClick={onClick}
            className="flex flex-row justify-between w-full p-6 items-center border-surface border-b-[1px] min-h-[102px] cursor-pointer space-x-6"
            style={{ backgroundColor: backgroundColor }}
        >
            <div className="whitespace-nowrap overflow-hidden overflow-ellipsis">
                {title}
                <p className="truncate mt-2 text-secondary-text">{lastMessage?.body}</p>
                {participantName}
            </div>
            <div className="flex flex-col items-end whitespace-nowrap">
                <p className="text-secondary-text">{time}</p>
                {unreadMessagesCount > 0 && (
                    <span className="bg-primary rounded-full text-white text-[1rem] w-[25px] h-[25px] text-center mt-[0.25rem]">{unreadMessagesCount}</span>
                )}
            </div>
        </div>
    );
};

export default ConversationListItem;