import clsx from "clsx";
import { Check } from "phosphor-react";
import { Link } from "react-router-dom";
import { MessageStatuses } from "../../../../api/conversations";
import { ReduxMedia } from "../../../../store/reducers/messageListReducer";
import MessageMedia from "../MessageMedia";

type MessageTypeSpecialProps = {
    bannerText: string,
    messageContent: JSX.Element[],
    buttonText?: string,
    buttonHref?: string,
    messageTime: string,
    participantName: string,
    isMyMessage?: boolean
    messageStatus: MessageStatuses;
    messageMedia: ReduxMedia[] | null
    onDownload: () => Promise<Error | undefined>
    onOpen: (mediaSid: string, file: ReduxMedia) => void
}

const MessageTypeSpecial = ({
    bannerText,
    messageContent,
    buttonText,
    buttonHref,
    messageTime,
    participantName,
    isMyMessage,
    messageStatus,
    messageMedia,
    onDownload,
    onOpen
}: MessageTypeSpecialProps) => {

    return (
        <div className={clsx("flex flex-col m-8", isMyMessage ? "items-end text-right" : "items-start text-left")}>
            <div className="flex flex-col space-y-2 max-w-[80%] md:max-w-[40%] min-w-[30%]">
                <p>{participantName}</p>
                <div
                    className={clsx("bg-white rounded-lg min-w-[6rem] drop-shadow-lg text-left break-words",
                        isMyMessage ? "rounded-br-none" : "rounded-bl-none"
                    )}
                >
                    <div>
                        <p className="py-2 px-4 text-white bg-primary inline-block rounded-br-lg rounded-tl-lg">
                            {bannerText}
                        </p>
                    </div>
                    <div className="p-4 flex flex-col gap-6 break-words md:break-normal">
                        {messageContent}
                        {
                            messageMedia && messageMedia?.length > 0 ?
                                <>
                                    <br />
                                    <MessageMedia
                                        messageMedia={messageMedia}
                                        onDownload={onDownload}
                                        onOpen={onOpen}
                                    />
                                </>
                                : null
                        }
                        {buttonHref && (
                            <div className="w-full">
                                <Link
                                    className="bg-black w-full block text-center text-white py-2 px-4 rounded-lg"
                                    to={buttonHref ?? ""}
                                >
                                    {buttonText || "View"}
                                </Link>
                            </div>
                        )}
                    </div>
                </div>
                <div className="flex justify-end text-right text-secondary-text gap-3">
                    {messageTime}
                    {
                        (!messageStatus.Sending || messageStatus.Failed) ? <Check size={25} /> : <></>
                    }
                </div>
            </div>

        </div>
    );
};

export default MessageTypeSpecial;