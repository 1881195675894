import clsx from "clsx";
import { useOrganizationContext } from "../api/current-organization/organizationContext";
import { RoleAssignmentSummaryDto, roleAssignmentStatusOptions, roleAssignmentStatusTypes } from "../models/app/roleAssignment";
import { successToast } from "../toast";
import formatDateOnlyString from "../utils/formatDateString";
import Button from "./Button";
import Card from "./Card";
import DialogBox, { useDialogBoxState } from "./DialogBox";
import { dialogWidths } from "./DialogBox/DialogBox";
import Tooltip from "./Tooltip";
import Typography from "./Typography";
import { useRoleAssignment } from "../api/roleAssignment";

export type ClientRoleAssignmentCardProps = {
    roleAssignment: RoleAssignmentSummaryDto
}
export const ClientRoleAssignmentCard = ({
    roleAssignment,
}: ClientRoleAssignmentCardProps) => {
    const cancelAssignmentDialogState = useDialogBoxState();
    const { giggedClientTerminology } = useOrganizationContext();
    const {
        cancelRoleAssignment,
        isCancellingRoleAssignment
    } = useRoleAssignment(roleAssignment.id);

    const handleCancel = async () => {
        const response = await cancelRoleAssignment();

        if (response.success) {
            successToast("Role assignment cancelled.");
            window.location.reload();
        }

        return response;
    };

    const isButtonDisabled = ![roleAssignmentStatusTypes.submitted, roleAssignmentStatusTypes.pending].includes(roleAssignment.roleAssignmentStatusId);

    return (
        <>
            <Card key={roleAssignment.roleId} className="flex flex-col md:flex-row text-center md:text-left items-center grow md:mr-4 rounded-b-none md:rounded-b-lg pb-2 md:pb-6">
                <div className="md:mr-8">
                    <Typography variant="title-large" component="p" className="mb-4">{roleAssignment.roleTitle}</Typography>
                    <p className="mb-2">
                        <span className="font-bold">{giggedClientTerminology}: </span>
                        {roleAssignment.giggedClientName}</p>
                    <p className="mb-2">
                        <span className="font-bold">Sent on: </span>
                        {formatDateOnlyString(roleAssignment.dateSent)}</p>
                    <p className="mb-2">
                        <span className="font-bold">Status: </span>
                        {roleAssignmentStatusOptions[roleAssignment.roleAssignmentStatusId].label}</p>
                </div>
                <div className="md:ml-auto">
                    <Tooltip
                        tooltipText="This assignment is not in 'Submitted' or 'Pending' status. You can only cancel assignments that are marked as 'Submitted' or 'Pending'."
                        isEnabled={isButtonDisabled}
                    >
                        <Button
                            onClick={cancelAssignmentDialogState.open}
                            disabled={isButtonDisabled}
                            className={clsx(isButtonDisabled && "cursor-not-allowed", "block my-4 md:my-0 md:ml-auto")}
                            variant="primary"
                        >
                            Cancel
                        </Button>
                    </Tooltip>
                </div>
            </Card>
            <DialogBox
                {...cancelAssignmentDialogState}
                title="Cancel Role Assignment"
                maxWidth={dialogWidths.medium}
                titleBold
            >
                <div className="flex flex-col gap-4">
                    <Typography variant="body" component="p">{"Are you sure you want to cancel this role assignment?"}</Typography>
                </div>
                <div className="flex items-center sm:justify-between sm:space-x-4 flex-col-reverse sm:flex-row">
                    <Button className="w-full sm:w-fit mt-4 sm:mt-0" disabled={isCancellingRoleAssignment} variant="secondary" onClick={cancelAssignmentDialogState.close}>Back</Button>
                    <Button className="w-full sm:w-fit" loading={isCancellingRoleAssignment} variant="primary" onClick={() => handleCancel()}>Confirm</Button>
                </div>
            </DialogBox>
        </>
    );
};

export default ClientRoleAssignmentCard;