import { ChatCircle } from "phosphor-react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { useOrganizationContext } from "../../../api/current-organization/organizationContext";
import { themeValues } from "../../../muiTheme";
import { actionCreators, AppState } from "../../../store";
import { ReduxConversation } from "../../../store/reducers/conversationReducer";
import Typography from "../../Typography";
import ListHeader from "../ListHeader";
import MessageInputField from "./MessageInputField";
import MessagesBox from "./MessagesBox";

export type MessagesContainerProps = {
    conversation?: ReduxConversation
    handleBackClick?: () => void
};

const MessagesContainer = ({
    conversation,
    handleBackClick
}: MessagesContainerProps) => {
    const { theme } = useOrganizationContext();
    const sid = useSelector((state: AppState) => state.sid);
    const loadingStatus = useSelector((state: AppState) => state.loadingStatus);
    const participants = useSelector((state: AppState) => state.participants)[sid] ?? [];
    const messages = useSelector((state: AppState) => state.messages);
    const lastReadIndex = useSelector((state: AppState) => state.lastReadIndex);
    const conversations = useSelector((state: AppState) => state.conversations);

    const dispatch = useDispatch();
    const { pushMessages } = bindActionCreators(actionCreators, dispatch);

    return (
        <div className="flex flex-1 flex-col bg-surface text-secondary border-surface border-t-[1px] border-r-[1px] border-b-[1px] md:w-[66%] rounded-r-lg h-[85vh]">
            {
                (!sid || !conversation) ?
                    <div className="mx-auto my-auto flex flex-col items-center">
                        <ChatCircle size={55} color={themeValues[theme].primaryMain} />
                        <Typography className="mt-4 font-bold" component="p" variant="title-small">{conversations && conversations.length > 0 ? "Select a conversation to start" : "You currently don't have any conversations"}</Typography>
                    </div>
                    :
                    <>
                        <ListHeader className="rounded-tr-lg" name={conversation.friendlyName ?? ""} handleBackClick={handleBackClick} />
                        <div id="scrollable-div" className="flex flex-col-reverse overflow-y-auto justify-between h-full">
                            <MessagesBox
                                key={sid}
                                conversation={conversation}
                                addMessage={pushMessages}
                                messages={messages[sid]}
                                loadingStatus={loadingStatus}
                                participants={participants}
                                lastReadIndex={lastReadIndex}
                            />
                        </div>
                        <MessageInputField conversation={conversation} />
                    </>
            }
        </div>
    );

};

export default MessagesContainer;