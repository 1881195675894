import { Link } from "react-router-dom";

import Card from "../../components/Card";
import CardDataFieldLabel from "../../components/Card/CardDataFieldLabel";
import Chip, { ChipContainer } from "../../components/Chip";
import TitleUnderline from "../../components/TitleUnderline";
import Typography from "../../components/Typography";
import { useAppPaths, useRoleId } from "../../Routes";
import { useOrganizationContext } from "../../api/current-organization/organizationContext";
import Loader from "../../components/Loader";
import { useRoles } from "../../api/roles";
import RoleSummaryCard from "../../components/RoleSummaryCard";
import ReportButton from "../../components/Reports";
import { useRole } from "../../api/role";
import SubmitApplicationButton from "./SubmitApplicationButton";
import { roleAssignmentTypes, roleStatuses } from "../../models/app/role";

const FindARoleDetailsPage = () => {
    const appPaths = useAppPaths();
    const roleId = useRoleId();
    const { role, isLoading } = useRole(roleId);
    const { roles: clientRoles } = useRoles({
        roleStatusIds: [roleStatuses.open],
        giggedClientId: role?.giggedClientId,
        pauseFetching: !role,
        allClients: true,
    });
    const { organizationConfig } = useOrganizationContext();
    
    if (!role || isLoading) return <Loader />;

    const otherClientRoles = clientRoles.filter(_ => _.id !== role.id);

    return (
        <div className="m-4 lg:m-8 break-words">
            <div className="mx-auto space-y-8 max-w-6xl">
                <Card key={role.id} className="space-y-6 md:flex">
                    <div className="space-y-4 w-full md:mr-8 overflow-hidden">
                        <div className="space-y-4">
                            <Typography variant="title-small" component="p">
                                <Link to={appPaths.clients.details(role.giggedClientId)}>
                                    {role.giggedClientName}
                                </Link>
                            </Typography>
                            <Typography variant="display-medium" component="h1">{role?.title}</Typography>
                            <div className="flex flex-row space-x-2"><CardDataFieldLabel>Role category: </CardDataFieldLabel><Typography variant="body" component="p">{role.roleCategoryName}</Typography></div>
                            <Typography variant="body" component="p" className="italic whitespace-pre-wrap">{role.description}</Typography>
                            <TitleUnderline fullWidth />
                        </div>
                        <div>
                            <div className="space-y-2">
                                <CardDataFieldLabel>Skills required</CardDataFieldLabel>
                                <ChipContainer>
                                    {role.skills.map(skill => (
                                        <Chip key={skill.id} variant="secondary" label={skill.name} />
                                    ))}
                                </ChipContainer>
                            </div>
                        </div>
                        {organizationConfig.isReportsEnabled && (
                            <div className="pt-2">
                                <ReportButton
                                    type="Gig"
                                    entityId={roleId}
                                    className="text-black hover:text-primary"
                                />
                            </div>
                        )}
                    </div>
                    {role.roleAssignmentTypeId === roleAssignmentTypes.applications && (
                        <div className="md:ml-auto md:self-start md:shrink-0 !mt-auto py-4 md:py-0">
                            <SubmitApplicationButton role={role} />
                        </div>
                    )}
                </Card>

                {otherClientRoles.length > 0 && (
                    <div>
                        <Typography variant="headline-large" component="h2" gutterBottom>More from {role.giggedClientName}</Typography>
                        <div className="md:flex md:space-x-6">
                            <Card className="mb-6 md:self-start shrink-0 md:w-[250px]">
                                <div className="h-32 w-32 pt-[4px] text-[54px] flex items-center justify-center rounded-full bg-white mx-auto mb-8">
                                    {role.giggedClientName && role.giggedClientName[0].toUpperCase()}
                                </div>

                                <p className="text-center text-title-medium font-bold">
                                    {role.giggedClientName}
                                </p>
                            </Card>
                            <div className="space-y-6 md:grow">
                                {otherClientRoles.map(clientRole => (
                                    <RoleSummaryCard key={clientRole.id} role={clientRole} viewRoleLink={appPaths.roles.findARoleDetails(clientRole.id)} />
                                ))}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default FindARoleDetailsPage;
