export type UserAvatarProps = {
    name: string
    userImageUrl?: string
}

const UserAvatar = ({
    name,
    userImageUrl
}: UserAvatarProps) => {

    return (
        <div>
            {userImageUrl ? (
                <div className="flex items-center justify-center mx-auto mb-2 rounded-full">
                    <img
                        src={userImageUrl}
                        className="h-12 w-12 rounded-full object-cover"
                    />
                </div>
            ) :
                <div className="h-12 w-12 text-[24px] flex items-center justify-center mx-auto mb-2 rounded-full bg-white">
                    {name[0].toUpperCase()}
                </div>
            }
            <p className="text-center text-title-small font-bold">
                {name}
            </p>
        </div>
    );
};

export default UserAvatar;
