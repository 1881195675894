import { UseFormReturn } from "react-hook-form";
import { ApplicationFormValues } from "./ApplicationFormValues";
import FormTextAreaInput from "../FormTextAreaInput";
import FormTextInput from "../FormTextInput";

export type ApplicationFormFieldsProps = {
    methods: UseFormReturn<ApplicationFormValues>
}

const ApplicationFormFields = ({
    methods,
}: ApplicationFormFieldsProps) => {
    return (
        <div className="md:flex">
            <div className="space-y-8 w-full">
                <FormTextAreaInput
                    required
                    id="create-application-form-cover-letter"
                    label="Cover letter"
                    placeholder="Type your cover letter"
                    register={methods.register("coverLetter")}
                    error={methods.formState.errors.coverLetter}
                    defaultValue={""}
                />
                <FormTextInput
                    id="create-application-form-portfolio-url"
                    label="Portfolio URL"
                    placeholder="Enter your portfolio URL"
                    error={methods.formState.errors.portfolioUrl}
                    defaultValue={""}
                    {...methods.register("portfolioUrl")}
                />
            </div>
        </div>
    );
};

export default ApplicationFormFields;