import { useGiggedApiSWR } from "./common/giggedApiSWR";
import { ApiTalentDashboardDto } from "../models/api/talentDashboard";

export const useTalentDashboard = () => {
    const url = "api/me/talent-dashboard";

    const apiCall = useGiggedApiSWR<ApiTalentDashboardDto>(url);

    return {
        dashboardData: apiCall.data,
        isLoading: apiCall.isLoading,
    };
};
