import { useState } from "react";
import { useTalents } from "../../api/talents";
import { silentlyReplaceSearchParams } from "../../utils/searchParams";
import { FindTalentContext } from "./findTalentContext";

export type FindTalentContextProviderProps = {
    children: React.ReactNode
}

const FindTalentContextProvider = ({
    children,
}: FindTalentContextProviderProps) => {
    const {
        talents,
        isLoading,
        sortOrder,
        orderBy,
        searchTerm: searchTermQueryParam,
        skillIds: skillIdsQueryParam,
        badgeIds: badgeIdsQueryParam,
        specializationIds: specializationIdsQueryParam,
        industrialExperienceIds: industrialExperienceIdsQueryParam,
        locationIds: locationIdsQueryParam,
        setSearchTerm: setUseTalentsSearchTerm,
        setSkillIds: setUseTalentsSkillIds,
        setBadgeIds: setUseTalentsBadgeIds,
        setSpecializationIds: setUseTalentsSpecializationIds,
        setIndustrialExperienceIds: setUseTalentsIndustrialExperienceIds,
        setLocationIds: setUseTalentsLocationIds,
        setSortOrder,
        setOrderBy,
    } = useTalents({ pageSize: 100 });

    const [searchTerm, setSearchTerm] = useState(searchTermQueryParam);
    const [skillIds, setSkillIds] = useState<string[]>(skillIdsQueryParam);
    const [badgeIds, setBadgeIds] = useState<string[]>(badgeIdsQueryParam);
    const [specializationIds, setSpecializationIds] = useState<string[]>(specializationIdsQueryParam);
    const [industrialExperienceIds, setIndustrialExperienceIds] = useState<string[]>(industrialExperienceIdsQueryParam);
    const [locationIds, setLocationIds] = useState<string[]>(locationIdsQueryParam);

    const anyActiveFilters =
        searchTerm.length > 0 ||
        skillIds.length > 0 ||
        badgeIds.length > 0 ||
        specializationIds.length > 0 ||
        industrialExperienceIds.length > 0 ||
        locationIds.length > 0;

    const clearAllFilters = () => {
        setSearchTerm("");
        setSkillIds([]);
        setBadgeIds([]);
        setSpecializationIds([]);
        setIndustrialExperienceIds([]);
        setLocationIds([]);
        setUseTalentsSearchTerm("");
        setUseTalentsSkillIds([]);
        setUseTalentsBadgeIds([]);
        setUseTalentsSpecializationIds([]);
        setUseTalentsIndustrialExperienceIds([]);
        setUseTalentsLocationIds([]);
        silentlyReplaceSearchParams("");
    };

    const searchNow = () => {
        setUseTalentsSearchTerm(searchTerm);
        setUseTalentsSkillIds(skillIds);
        setUseTalentsBadgeIds(badgeIds);
        setUseTalentsSpecializationIds(specializationIds);
        setUseTalentsIndustrialExperienceIds(industrialExperienceIds);
        setUseTalentsLocationIds(locationIds);
    };

    return (
        <FindTalentContext.Provider value={{
            talents,
            isLoading,
            searchTerm,
            skillIds,
            badgeIds,
            specializationIds,
            industrialExperienceIds,
            locationIds,
            sortOrder,
            orderBy,
            anyActiveFilters,
            setSearchTerm,
            setSkillIds,
            setBadgeIds,
            setSpecializationIds,
            setIndustrialExperienceIds,
            setLocationIds,
            setSortOrder,
            setOrderBy,
            clearAllFilters,
            searchNow,
        }}>
            {children}
        </FindTalentContext.Provider>
    );
};

export default FindTalentContextProvider;
