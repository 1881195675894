import Card from "../../components/Card";
import Typography from "../../components/Typography";
import { useAppPaths } from "../../Routes";
import { ApiRoleSummaryDto } from "../../models/api/role";
import RoleSummaryCard from "../../components/RoleSummaryCard";

export type RolesListProps = {
    roles: ApiRoleSummaryDto[]
    isLoading: boolean
};

const RolesList = ({ roles, isLoading }: RolesListProps) => {
    const appPaths = useAppPaths();

    if (isLoading) return <p>Loading...</p>;

    if (roles.length === 0) {
        return (
            <Card className="w-full self-start">
                <Typography variant="title-large" component="p">No results</Typography>
            </Card>
        );
    }

    return (
        <div className="space-y-8 w-full overflow-hidden">
            {roles.map(role => (
                <RoleSummaryCard key={role.id} role={role} viewRoleLink={appPaths.roles.findARoleDetails(role.id)} />
            ))}
        </div>
    );
};

export default RolesList;
