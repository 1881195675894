import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

export type ClientPersonalDetailsFormValues = {
    name?: string | null
    aboutMe?: string
    companyCostCode?: string | null
    locationId?: string | null
}

const buildClientPersonalDetailsFormValidationSchema = (
    giggedClientTerminology: string,
): yup.SchemaOf<ClientPersonalDetailsFormValues> => {
    return yup.object({
        name: yup.string()
            .required(`${giggedClientTerminology} name is required.`)
            .max(150, `${giggedClientTerminology} name cannot be longer than 150 characters.`),
        aboutMe: yup.string().typeError("Overview is required.").required("Overview is required."),
        companyCostCode: yup.string().nullable().max(25, "Company number cannot be longer than 25 characters."),
        locationId: yup.string().required("Location is required"),
    });
};

export const useClientPersonalDetailsForm = (
    giggedClientTerminology: string,
    defaultValues?: ClientPersonalDetailsFormValues
) => useForm<ClientPersonalDetailsFormValues>({
    resolver: yupResolver(buildClientPersonalDetailsFormValidationSchema(giggedClientTerminology)),
    defaultValues: {
        ...defaultValues,
        name: defaultValues?.name ?? "",
        aboutMe: defaultValues?.aboutMe ?? "",
        companyCostCode: defaultValues?.companyCostCode ?? "",
    }
});