import { useEffect, useState } from "react";
import { Controller } from "react-hook-form";

import { useClientPersonalInformationContext } from "../../api/clientPersonalInformation";
import ApiError from "../../api/common/apiError";
import { useOrganizationContext } from "../../api/current-organization/organizationContext";
import { fetchLocationOptions } from "../../api/locations";
import useAccessToken from "../../auth/useAccessToken";
import Button from "../../components/Button";
import FormDropdown, { FormDropdownOption } from "../../components/FormDropdown";
import FormImageUploadInput, { useFormFileUploadInputState } from "../../components/FormFileUploadInput";
import FormTextAreaInput from "../../components/FormTextAreaInput";
import FormTextInput from "../../components/FormTextInput";
import GeneralFormError from "../../components/GeneralFormError";
import { successToast } from "../../toast";
import { clientProfileEditTooltips } from "../../tooltipsContent";
import { ClientPersonalDetailsFormValues, useClientPersonalDetailsForm } from "./ClientPersonalDetailsFormValues";
import { useNavigate } from "react-router-dom";
import { useAppPaths } from "../../Routes";

const ClientPersonalDetailsForm = () => {
    const [allLocationOptions, setAllLocationOptions] = useState<FormDropdownOption<string>[]>([]);
    const [isLoadingLocationOptions, setIsLoadingLocationOptions] = useState(true);
    const [isClientPersonalInformationLoaded, setIsClientPersonalInformationLoaded] = useState(false);
    const [submissionError, setSubmissionError] = useState<ApiError | undefined>(undefined);

    const navigate = useNavigate();
    const appPaths = useAppPaths();

    const accessToken = useAccessToken();
    const { giggedClientTerminology, organizationConfig } = useOrganizationContext();

    const {
        clientPersonalInformation,
        updateClientPersonalInformation,
        isUpdatingClientPersonalInformation,
        isLoading,
        isValidating,
        reload: reloadPersonalInformation,
        setClientProfileCompletionStatus
    } = useClientPersonalInformationContext();
    const methods = useClientPersonalDetailsForm(
        giggedClientTerminology,
        clientPersonalInformation
    );
    const { reset, register, formState } = methods;

    const {
        urlOrDataUri: profileImageDataUri,
        loadFile,
        error: profileImageError,
        isDirty: isProfileImageDirty,
        reset: resetImageInput,
        ...fileInputProps
    } = useFormFileUploadInputState({
        maxFileSizeKb: 1000,
        initialUrl: clientPersonalInformation?.profileImageUrl,
    });

    useEffect(() => {
        if (isClientPersonalInformationLoaded) return;

        reset({
            ...clientPersonalInformation,
            locationId: clientPersonalInformation?.location?.id,
            aboutMe: clientPersonalInformation?.aboutMe || "",
            companyCostCode: clientPersonalInformation?.companyCostCode || ""
        });

        if (clientPersonalInformation) {
            setIsClientPersonalInformationLoaded(true);
        }
    }, [reset, clientPersonalInformation, isClientPersonalInformationLoaded]);

    useEffect(() => {
        if (accessToken) {
            const loadAllLocations = async () => {
                setIsLoadingLocationOptions(true);
                try {
                    const fetchedLocationOptions = await fetchLocationOptions(undefined, accessToken);
                    setAllLocationOptions(fetchedLocationOptions);
                }
                finally {
                    setIsLoadingLocationOptions(false);
                }
            };
            loadAllLocations();
        }

    }, [accessToken]);

    const handleSaveAndUpdate = async (values: ClientPersonalDetailsFormValues) => {
        window.scrollTo(0, 0);
        const isProfileAlreadyComplete = clientPersonalInformation?.isProfileComplete;

        const response = await updateClientPersonalInformation({
            ...values,
            name: values.name || undefined,
            profileImageDataUri: isProfileImageDirty ? profileImageDataUri : undefined,
        });

        if (response.success) {
            resetImageInput();
            successToast(`${giggedClientTerminology} details successfully updated.`);
            reloadPersonalInformation();
            methods.reset(values);
            if (!isProfileAlreadyComplete) {
                setClientProfileCompletionStatus(true);
                navigate(appPaths.dashboard, { replace: true });
            }
            return;
        }

        setSubmissionError(response.error);
    };

    if (isLoading || isValidating) {
        return <p>Loading...</p>;
    }

    return (
        <form onSubmit={methods.handleSubmit(handleSaveAndUpdate)}>
            <div className="space-y-6">
                <div className="md:flex md:space-x-6 md:space-y-0">
                    <FormTextInput
                        required
                        label={`${giggedClientTerminology} name`}
                        placeholder={`Enter ${giggedClientTerminology.toLowerCase()} name`}
                        error={formState.errors.name}
                        tooltip={clientProfileEditTooltips.displayNameItm(giggedClientTerminology)}
                        {...register("name")}
                    />
                </div>
                <FormTextAreaInput
                    required
                    id="client-personal-information-about-me"
                    label={`${organizationConfig.giggedClientTerminology} overview`}
                    placeholder={`Write an overview about this ${giggedClientTerminology.toLowerCase()}`}
                    error={formState.errors.aboutMe}
                    tooltip={clientProfileEditTooltips.clientOverview(giggedClientTerminology)}
                    register={register("aboutMe")}
                />
                <div className="space-y-6">
                    <div className="w-full">
                        <Controller
                            name="locationId"
                            control={methods.control}
                            render={({ field: { onChange, value } }) => (
                                <FormDropdown
                                    required
                                    label="Location"
                                    value={value || ""}
                                    options={allLocationOptions}
                                    disabled={isValidating || isLoading}
                                    onChange={onChange}
                                    error={methods.formState.errors.locationId}
                                    isLoadingOptions={isLoadingLocationOptions}
                                />
                            )}
                        />
                    </div>
                    <div className="md:flex md:space-x-6 md:space-y-0">
                        <FormTextInput
                            label="Cost code"
                            placeholder="Enter cost code unique identifier"
                            error={formState.errors.companyCostCode}
                            tooltip={clientProfileEditTooltips.companyCostCode}
                            {...register("companyCostCode")}
                        />
                    </div>
                </div>

                <FormImageUploadInput
                    {...fileInputProps}
                    label="Profile image"
                    helpText="Recommended minimum size: 300x300px"
                    inputId="client-profile-image-upload-button"
                    onLoadFile={loadFile}
                    error={profileImageError}
                    tooltip={clientProfileEditTooltips.profileImage(giggedClientTerminology)}
                    dataUrl={profileImageDataUri}
                    isDirty={isProfileImageDirty}
                    previewImgClassName="object-cover"
                />
            </div>

            <GeneralFormError error={submissionError} className="ml-8" />

            <div className="md:flex mt-8">
                <Button
                    className="md:ml-auto w-full md:w-auto"
                    loading={isUpdatingClientPersonalInformation || isLoading || isValidating}
                    disabled={!formState.isDirty && !isProfileImageDirty || !!profileImageError}
                    type="submit"
                >
                    Save &amp; update
                </Button>
            </div>
        </form >
    );
};

export default ClientPersonalDetailsForm;