import { Link, useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import Card from "../../components/Card";
import DialogBox, { useDialogBoxState } from "../../components/DialogBox";
import ProposalFilesButton from "../../components/ProposalFilesButton";
import UserAvatar from "../../components/UserAvatar";
import { useAppPaths } from "../../Routes";
import { useClientPersonalInformationContext } from "../../api/clientPersonalInformation";
import { dialogWidths } from "../../components/DialogBox/DialogBox";
import BadgeList from "../../components/BadgeList";
import { ApplicationSummary, applicationStatuses } from "../../models/app/application";
import ApplicationCoverLetterButton from "../../components/ApplicationCoverLetterButton";
import { useAcceptApplication } from "../../api/application";
import Tooltip from "../../components/Tooltip";
import { useOrganizationContext } from "../../api/current-organization/organizationContext";
import clsx from "clsx";
import { successToast } from "../../toast";
import ApplicationStatusChip from "../../components/ApplicationStatusChip";

export type RoleApplicationCardProps = {
    application: ApplicationSummary
}

const RoleApplicationCard = ({
    application,
}: RoleApplicationCardProps) => {
    const appPaths = useAppPaths();
    const confirmHireDialogState = useDialogBoxState();
    const [acceptApplication, isLoading] = useAcceptApplication();
    const navigate = useNavigate();
    const { giggedClientTerminology } = useOrganizationContext();

    const {
        isLoading: isLoadingClientPersonalInformation
    } = useClientPersonalInformationContext();

    if (isLoadingClientPersonalInformation) {
        return <p>Loading...</p>;
    }

    const handleConfirmHire = async () => {
        const response = await acceptApplication(application.id);

        if (response.success) {
            successToast("Application accepted.");
            navigate(appPaths.roles.details(application.roleId));
        }
    };

    const handleHire = async () => {
        confirmHireDialogState.open();
    };

    const userDisplayName = `${application.talentFirstName} ${application.talentLastName}`;
    const displayBadgeSection = application.badges.length > 0;
    const isHired = application.applicationStatusId === applicationStatuses.hired;
    const isPending = application.applicationStatusId === applicationStatuses.pending;
    const isSubmitted = application.applicationStatusId === applicationStatuses.submitted;

    return (
        <div className="md:flex md:space-x-4 max-w-lg md:max-w-none mx-auto">
            <Card className="pb-0 md:pb-6 flex flex-col justify-center items-center rounded-b-none md:rounded-lg md:w-1/5 min-w-[150px]">
                <Link target="blank" to={appPaths.talents.findTalentDetails(application.talentId)}>
                    <UserAvatar name={userDisplayName} userImageUrl={application.talentProfileImageThumbnailUrl} />
                </Link>
                {displayBadgeSection &&
                    <BadgeList badges={application.badges} keyPrefix={application.talentId.toString()} className="mt-2" />
                }
            </Card>
            <Card key={application.id} className="grow flex justify-center">
                <div className="md:flex md:grow items-center space-y-4 md:space-y-0 rounded-t-none md:rounded-lg">
                    <div className="flex flex-col md:flex-row md:ml-4 md:space-x-4 gap-4 items-center md:justify-between md:w-full text-center md:text-left">
                        <ApplicationStatusChip applicationStatusId={application.applicationStatusId} />
                        <div className="flex flex-col md:flex-row gap-4">
                            <ApplicationCoverLetterButton application={application} />
                            {/* <ProposalFilesButton proposal={proposal} /> */}
                            {(isHired || isPending || isSubmitted) && (
                                isPending ? (
                                    <Tooltip tooltipText={`This hire requires approval of the applicant's current ${giggedClientTerminology.toLowerCase()}`}>
                                        <Button variant="primary" className={"cursor-help"} disabled>{"Pending approval"}</Button>
                                    </Tooltip>
                                ) : <Button variant="primary" className={clsx(isPending && "cursor-help")} disabled={isHired || isPending} onClick={handleHire}>{isHired ? "Hired" : "Hire"}</Button>
                            )}
                        </div>
                    </div>
                </div>

                <DialogBox
                    {...confirmHireDialogState}
                    title={`Are you sure you want to hire ${userDisplayName} for this role?`}
                    maxWidth={dialogWidths.small}
                >
                    <div className="flex items-center sm:justify-between sm:space-x-4 flex-col-reverse sm:flex-row">
                        <Button className="w-full sm:w-fit mt-4 sm:mt-0" disabled={isLoading} variant="secondary" onClick={confirmHireDialogState.close}>Cancel</Button>
                        <Button className="w-full sm:w-fit" loading={isLoading} variant="primary" onClick={handleConfirmHire}>Confirm</Button>
                    </div>
                </DialogBox>
            </Card>
        </div>
    );
};

export default RoleApplicationCard;
