

import { FormProvider, UseFormReturn } from "react-hook-form";

import DialogBox, { dialogWidths } from "../DialogBox/DialogBox";
import FormInputLabel from "../FormInputLabel";
import { useOrganizationContext } from "../../api/current-organization/organizationContext";
import FormTextAreaInput from "../FormTextAreaInput";
import Button from "../Button";
import GeneralFormError from "../GeneralFormError";
import ApiError from "../../api/common/apiError";
import { AssignRoleFormValues } from "./AssignRoleFormValues";
import { useEffect, useState } from "react";
import { GigApiFetcherResponse } from "../../api/common/fetching";
import { mapToAssignRoleDto } from "../../models/mappers/formMappers/roleAssignment";
import { ApiAssignRoleDto } from "../../models/api/role";

export type AssignRoleFormDialogProps = {
    isOpen: boolean
    onClose: () => void
    onAssign: (values: ApiAssignRoleDto) => Promise<GigApiFetcherResponse<void>>
    loading: boolean
    talentId: string
    disabledTalentIds?: string[]
    methods: UseFormReturn<AssignRoleFormValues>
    closeDialog: () => void
}

const AssignRoleFormDialog = ({
    isOpen,
    onClose,
    onAssign,
    talentId,
    loading,
    disabledTalentIds,
    methods,
    closeDialog
}: AssignRoleFormDialogProps) => {
    const { talentTerminology } = useOrganizationContext();
    const [submissionError, setSubmissionError] = useState<ApiError | undefined>();

    const handleAssign = async (values: AssignRoleFormValues) => {
        setSubmissionError(undefined);

        const assignRoleDto = mapToAssignRoleDto(values, { talentId: talentId });
        const response = await onAssign(assignRoleDto);

        if (response && response.success) {
            closeDialog();
            methods.reset();
        } else {
            setSubmissionError(response?.error);
        }
    };
    const errors = methods.formState.errors;

    useEffect(() => {
        console.log({errors})
    }, [errors])

    return (
        <DialogBox
            isOpen={isOpen}
            onClose={onClose}
            title={`Assign ${talentTerminology.toLowerCase()} to role`}
            minWidth={dialogWidths.medium}
        >
            <FormProvider {...methods} >
                <form
                    onSubmit={methods.handleSubmit(handleAssign)}
                    noValidate
                >
                    <div className="space-y-4">
                        <div className="space-y-2">
                            <FormInputLabel required>Message</FormInputLabel>
                            <FormTextAreaInput
                                id="assign-message-input"
                                required
                                rows={3}
                                placeholder="Type your message here"
                                register={methods.register("message")}
                                error={methods.formState.errors.message}
                                disabled={methods.formState.isSubmitting}
                            />
                        </div>
                        <div className="mt-6">
                            <div className="flex flex-col md:flex-row-reverse md:justify-between md:items-center md:space-x-2 w-full space-y-2 md:space-y-0">
                                <Button
                                    type="submit"
                                    variant="primary"
                                    loading={loading}
                                >
                                    Assign now
                                </Button>
                                <Button type="button" variant="tertiary" disabled={loading} onClick={onClose}>Cancel</Button>
                            </div>
                        </div>
                    </div>
                </form>
            </FormProvider>
            <GeneralFormError error={submissionError} />
        </DialogBox>
    );
};

export default AssignRoleFormDialog;