import { ApiApplicationSummary } from "../models/api/application";
import { ApplicationSummary, CreateApplicationDto } from "../models/app/application";
import { GigApiPaginatedData } from "./common/apiResponse";
import { GigApiFetcherResponse, useAuthenticatedGigApiFetcher } from "./common/fetching";
import { useGiggedApiSWR, UseGiggedApiSWRReturn } from "./common/giggedApiSWR";

const useCreateApplication = () => useAuthenticatedGigApiFetcher<CreateApplicationDto, string, CreateApplicationDto>("POST",
    (dto) => ({
        url: "api/applications",
        body: dto,
    }));

export type UseApplicationsParams = {
    roleId?: string,
    allTalents?: boolean
}

export type UseApplicationsReturn =
    Omit<UseGiggedApiSWRReturn<GigApiPaginatedData<ApiApplicationSummary>>, "data"> &
    Partial<Omit<UseGiggedApiSWRReturn<GigApiPaginatedData<ApiApplicationSummary>>["data"], "items">> &
    {
        applications: ApplicationSummary[]
        createApplication: (dto: CreateApplicationDto) => Promise<GigApiFetcherResponse<string>>
        isCreatingApplication: boolean
    }

export type UseApplications = (params?: UseApplicationsParams) => UseApplicationsReturn

export const useApplications: UseApplications = ({
    roleId,
    allTalents,
} = {}) => {
    const url = `api/${allTalents ? "" : "me/"}applications?` +
        "pageIndex=0" +
        "&pageSize=100" +
        "&orderBy=CreatedAt" +
        "&sortOrder=desc" +
        (roleId ? `&roleId=${roleId}` : "");

    const apiCall = useGiggedApiSWR<GigApiPaginatedData<ApiApplicationSummary>>(url);

    const [createApplication, isCreatingApplication] = useCreateApplication();

    const applications = apiCall.data?.items ? apiCall.data.items : [];

    return {
        ...apiCall,
        ...apiCall.data,
        applications,
        createApplication: async (dto: CreateApplicationDto) => {
            const response = await createApplication(dto);

            if (response.success) {
                apiCall.mutate();
            }

            return response;
        },
        isCreatingApplication
    };
};