import HistoryListAddItemButton from "./HistoryListAddItemButton";
import { useDialogBoxState } from "../../components/DialogBox";
import { successToast } from "../../toast";
import FormInputLabel from "../../components/FormInputLabel";
import { useTalentEducations } from "../../api/talentEducations";
import { EducationFormValues } from "./EducationFormValues";
import { EducationFormDialog } from "./EducationFormDialog";
import EducationCard from "./EducationCard";

export const Education = () => {
    const {
        courses,
        addCourse,
        removeCourse,
        updateCourse,
    } = useTalentEducations();

    const createCourseDialogState = useDialogBoxState();

    const handleAddCourse = async (values: EducationFormValues) => {
        if (values.startDate === null) throw new Error("Expected start date not to be null.");

        const response = await addCourse({
            ...values,
            startDate: values.startDate as Date,
            endDate: values.endDate || undefined,
        });

        if (response.success) {
            successToast("Course successfully added.");
            createCourseDialogState.close();
        }

        return response;
    };

    const makeUpdateCourseHandler = (id: string) => async (values: EducationFormValues) => {
        if (values.startDate === null) throw new Error("Expected start date not to be null.");

        const response = await updateCourse(id, {
            ...values,
            startDate: values.startDate as Date,
            endDate: values.endDate || undefined,
        });

        if (response.success) {
            successToast("Course successfully updated.");
        }

        return response;
    };

    const makeRemoveJobCourseHandler = (id: string) => async () => {
        const response = await removeCourse(id);

        if (response.success) {
            successToast("Course successfully removed.");
        }

        return response;
    };

    return (
        <>
            <div>
                <FormInputLabel className="ml-0 mb-4" tooltipText="Add any educational qualifications here e.g. Masters Degree, Bachelors Degree, Diploma etc.">Education</FormInputLabel>
                <div className="space-y-4">
                    <HistoryListAddItemButton onClick={createCourseDialogState.open}>Add education</HistoryListAddItemButton>
                    {courses.map(course => (
                        <EducationCard
                            key={course.id}
                            course={course}
                            onRemove={makeRemoveJobCourseHandler(course.id)}
                            updateJob={makeUpdateCourseHandler(course.id)}
                        />
                    ))}
                </div>
            </div>
            <EducationFormDialog
                {...createCourseDialogState}
                initialValues={{
                    courseTitle: "",
                    instituteName: "",
                    educationLevelId: "",
                    startDate: null,
                    endDate: null,
                    courseDescription: "",
                }}
                resetOnSubmit
                onSubmit={handleAddCourse}
            />
        </>
    );
};

export default Education;