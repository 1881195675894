import { ApiApplicationDetails, ApiUpdateApplicationDto } from "../models/api/application";
import ApiError from "./common/apiError";
import { GigApiFetcherResponse, useAuthenticatedGigApiFetcher } from "./common/fetching";
import { useGiggedApiSWR } from "./common/giggedApiSWR";

export const useUpdateApplication = () => useAuthenticatedGigApiFetcher<ApiUpdateApplicationDto, void, { applicationId: string, dto: ApiUpdateApplicationDto }>("PUT",
    ({ applicationId, dto }) => ({
        url: `api/applications/${applicationId}`,
        body: dto,
    }));

export const useAcceptApplication = () => useAuthenticatedGigApiFetcher<never, void, string>("PUT",
    (applicationId) => ({
        url: `api/applications/${applicationId}/actions/accept`
    }));

export type UseApplicationReturn<TApplicationId extends string | undefined> = TApplicationId extends undefined ?
    {
        application: undefined,
        updateApplication: undefined,
    } :
    {
        application?: ApiApplicationDetails
        isLoading: boolean
        error?: ApiError
        updateApplication: (dto: ApiUpdateApplicationDto) => Promise<GigApiFetcherResponse<void>>
        isUpdatingApplication: boolean
        refetchApplication: () => void
        isValidating: boolean
    }

export const useApplication = <TApplicationId extends string | undefined>(applicationId: TApplicationId): UseApplicationReturn<TApplicationId> => {
    const url = `api/applications/${applicationId}`;

    const apiCall = useGiggedApiSWR<ApiApplicationDetails>(url);

    const [updateApplication, isUpdatingApplication] = useUpdateApplication();

    if (applicationId === undefined) return { application: undefined } as UseApplicationReturn<TApplicationId>;

    return {
        application: apiCall.data,
        isLoading: apiCall.isLoading,
        error: apiCall.error,
        updateApplication: async (dto: ApiUpdateApplicationDto) => {
            const response = await updateApplication({ applicationId, dto });

            if (response.success) {
                apiCall.mutate();
            }

            return response;

        },
        isUpdatingApplication,
        refetchApplication: apiCall.refetch,
        isValidating: apiCall.isValidating
    } as UseApplicationReturn<TApplicationId>;
};