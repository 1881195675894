import Card from "../components/Card";
import Loader from "../components/Loader";
import PageContent from "../components/PageContent";
import Typography from "../components/Typography";
import { useIsLargeScreen } from "../hooks/screenSizes";
import { useRoleAssignments } from "../api/roleAssignments";
import ClientRoleAssignmentCard from "../components/RoleAssigmentCardClient";

const ClientRoleAssignmentsPage = () => {
    const { roleAssignments, isLoading } = useRoleAssignments();
    const isLargeScreen = useIsLargeScreen();

    if (isLoading) return <Loader />;

    return (
        <PageContent>
            <Typography variant={isLargeScreen ? "display-large" : "display-small"} component="h1" gutterBottom>Role Assignments</Typography>
            {roleAssignments.length === 0 ? (
                <Card>
                    <Typography variant="title-large" component="p">None yet...</Typography>
                </Card>
            ) : (
                <div className="space-y-8">
                    {roleAssignments.map(roleAssignment => (
                        <ClientRoleAssignmentCard
                            key={roleAssignment.id}
                            roleAssignment={roleAssignment}
                        />
                    ))}
                </div>
            )}
        </PageContent >
    );
};

export default ClientRoleAssignmentsPage;