import Card from "../../components/Card";
import TalentSummaryCard from "../../components/TalentSummaryCard";
import Typography from "../../components/Typography";
import { TalentSummary } from "../../models/app/talent";

export type TalentsListProps = {
    talents: TalentSummary[]
    isLoading: boolean
}

const TalentsList = ({ talents, isLoading }: TalentsListProps) => {
    if (isLoading) return <p>Loading...</p>;

    if (talents.length === 0) {
        return (
            <Card className="w-full self-start">
                <Typography variant="title-large" component="p">No results</Typography>
            </Card>
        );
    }

    return (
        <div className="space-y-8 w-full">
            {talents.map(talent => (
                <TalentSummaryCard key={talent.id} talent={talent} />
            ))}
        </div>
    );
};

export default TalentsList;
