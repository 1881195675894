import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";

import Button from "../Button";
import CreateFormPageLayout from "./CreateGigFormPageLayout";
import FormRadioGroup from "../FormRadioGroup";
import { GigFormValues, buildGigFormValidationSchemaFields } from "../GigForm";
import { expectedDurationOptions } from "../../models/app/gig";
import { formClassNames } from "./classNames";
import { useOrganizationContext } from "../../api/current-organization/organizationContext";

export type CreateGigFormStep2DurationFields = Pick<GigFormValues, "expectedDurationId">;

export type CreateGigFormStep2DurationProps = {
    onSubmit: (value: CreateGigFormStep2DurationFields) => void
    onBackClick: (value: CreateGigFormStep2DurationFields) => void
    defaultValues: CreateGigFormStep2DurationFields
}

const CreateGigFormStep2Duration = ({
    onSubmit,
    onBackClick,
    defaultValues,
}: CreateGigFormStep2DurationProps) => {
    const { gigTerminology } = useOrganizationContext();

    const validationSchema: yup.SchemaOf<CreateGigFormStep2DurationFields> = yup.object({
        expectedDurationId: buildGigFormValidationSchemaFields.expectedDurationId,
    });

    const methods = useForm<CreateGigFormStep2DurationFields>({
        resolver: yupResolver(validationSchema),
        defaultValues,
    });
    
    const handleBackClick = () => {
        onBackClick(methods.getValues());
    };

    return (
        <form
            className={formClassNames}
            onSubmit={methods.handleSubmit(onSubmit)}
            noValidate
        >
            <CreateFormPageLayout
                title={`How long will your ${gigTerminology.toLowerCase()} take?`}
                description={`Give your best estimate of how long you think this ${gigTerminology.toLowerCase()} will take. If necessary, this can be updated throughout the engagement.`}
                content={
                    <Controller
                        name="expectedDurationId"
                        control={methods.control}
                        render={({ field: { onChange, value } }) => (
                            <FormRadioGroup
                                required
                                label={`Expected duration of the ${gigTerminology.toLowerCase()}`}
                                options={expectedDurationOptions}
                                error={methods.formState.errors.expectedDurationId}
                                defaultValue={value}
                                onChange={onChange}
                                value={value}
                            />
                        )}
                    />
                }
                iconName="Clock"
                buttons={
                    <>
                        <Button variant="secondary" onClick={handleBackClick} type="button">Back</Button>
                        <Button type="submit">Next</Button>
                    </>
                }
            />
        </form>
    );
};

export default CreateGigFormStep2Duration;