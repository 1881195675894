import Card from "../../components/Card";
import LinkButton from "../../components/LinkButton";
import PageContent from "../../components/PageContent";
import Typography from "../../components/Typography";
import { useAppPaths } from "../../Routes";
import { useIsLargeScreen } from "../../hooks/screenSizes";
import Loader from "../../components/Loader";
import { useRoles } from "../../api/roles";
import { roleStatuses } from "../../models/app/role";

const RolesListDraftPage = () => {
    const appPaths = useAppPaths();
    const { roles, isLoading } = useRoles({
        roleStatusIds: [roleStatuses.draft],
        initialOrderBy: "CreatedAt",
        initialSortOrder: "desc"
    });
    const isLargeScreen = useIsLargeScreen();


    if (isLoading) return <Loader />;

    return (
        <PageContent>
            <Typography variant={isLargeScreen ? "display-large" : "display-small"} component="h1" gutterBottom>Draft Roles</Typography>
            {roles.length === 0 ? (
                <Card>
                    <Typography variant="title-large" component="p">None yet...</Typography>
                </Card>
            ) : (
                <div className="space-y-8">
                    {roles.map(role => (
                        <Card key={role.id} className="flex items-center flex-col md:flex-row mb-4 break-words">
                            <div key={role.id} className="space-y-4 md:mr-8 text-center md:text-left">
                                <Typography variant="title-large" component="p" className="max-w-md">{role.title}</Typography>
                                <p className="italic line-clamp-3 md:mr-8">{role.description}</p>
                            </div>
                            <LinkButton className="block md:ml-auto self" to={appPaths.roles.edit(role.id)} variant="tertiary">Edit</LinkButton>
                        </Card>
                    ))}
                </div>
            )}
        </PageContent>
    );
};

export default RolesListDraftPage;