import { Navigate, Route, Routes, useParams } from "react-router-dom";
import { useOrganizationContext } from "./api/current-organization/organizationContext";
import RequireAuthenticated from "./auth/RequireAuthenticated";
import useCurrentUser from "./auth/useCurrentUser";
import WithTitle from "./components/PageLayout/WithTitle";
import { FourOFourPage } from "./pages/404";
import ClientDashboardPage from "./pages/ClientDashboard/ClientDashboardPage";
import ClientDetails from "./pages/ClientDetails";
import ClientProfileEditPage from "./pages/ClientProfileEdit/ClientProfileEditPage";
import FindAGigPage from "./pages/FindAGig";
import FindAGigDetailsPage from "./pages/FindAGigDetails";
import FindTalentPage from "./pages/FindTalent";
import FindTalentDetails from "./pages/FindTalentDetails";
import GigCreatePage from "./pages/GigCreate";
import GigCreateIntroPage from "./pages/GigCreateIntro";
import GigDetailsPage from "./pages/GigDetails";
import GigEditPage from "./pages/GigEdit";
import GigInvitesPage from "./pages/GigInvites";
import GigsMatchesListPage from "./pages/GigMatchesList";
import GigsProposalsListPage from "./pages/GigProposalsList";
import GigsCancelledClientPage from "./pages/GigsCancelledClient";
import GigsCancelledTalentPage from "./pages/GigsCancelledTalent";
import GigsCompletedClientPage from "./pages/GigsCompletedClient";
import GigsCompletedTalentPage from "./pages/GigsCompletedTalent";
import GigsListDraftPage from "./pages/GigsListDraft";
import GigsListPostedPage from "./pages/GigsListPosted";
import GigsOngoingClientPage from "./pages/GigsOngoingClient";
import GigsOngoingTalentPage from "./pages/GigsOngoingTalent";
import { InboxPage, InboxArchivePage } from "./pages/Inbox";
import ManageUsersPage from "./pages/ManageUsers/ManageUsersPage";
import ProposalCreatePage from "./pages/ProposalCreate";
import ProposalEditPage from "./pages/ProposalEdit";
import ProposalsListPage from "./pages/ProposalsList";
import SignUpPage from "./pages/SignUp";
import TalentDashboardPage from "./pages/TalentDashboard";
import TalentProfileEditPage from "./pages/TalentProfileEdit";
import LoginPage from "./components/Login";
import RoleCreatePage from "./pages/RoleCreate";
import RoleCreateIntroPage from "./pages/RoleCreateIntro";
import FindARolePage from "./pages/FindARole";
import FindARoleDetailsPage from "./pages/FindARoleDetails";
import ApplicationCreatePage from "./pages/ApplicationCreate";
import ApplicationsListPage from "./pages/ApplicationsList";
import RoleEditPage from "./pages/RoleEdit";
import RolesListDraftPage from "./pages/ManageRoles/RolesListDraft";
import RolesListInReviewPage from "./pages/ManageRoles/RolesListInReview";
import RolesListOpenPage from "./pages/ManageRoles/RolesListOpen";
import RolesListActivePage from "./pages/ManageRoles/RolesListActive";
import RolesListClosedPage from "./pages/ManageRoles/RolesListClosed";
import RolesListArchivedPage from "./pages/ManageRoles/RolesListArchived";
import RoleApplicationsListPage from "./pages/RoleApplicationsList";
import RoleDetailsPage from "./pages/RoleDetails";
import RolesMatchesListPage from "./pages/RoleMatchesList";
import ApplicationEditPage from "./pages/ApplicationEdit";
import RoleInvitesPage from "./pages/RoleInvites";
import TalentRoleAssignmentsPage from "./pages/RoleAssignmentsTalent";
import ClientRoleAssignmentsPage from "./pages/RoleAssignmentsClient";

export const fourOFourPath = "/404";
export const signUpPath = "/sign-up";

const appPaths = (gigTerminology: string, giggedTerminologyPlural: string, giggedClientTerminologyPlural: string, talentTerminology: string, talentTerminologyPlural: string) => {
    const gig = gigTerminology.toLowerCase().replace(" ", "-");
    const gigPlural = giggedTerminologyPlural.toLowerCase().replace(" ", "-");
    const clientPlural = giggedClientTerminologyPlural.toLowerCase().replace(" ", "-");
    const talentPlural = talentTerminologyPlural.toLowerCase().replace(" ", "-");

    return ({
        login: "/login",
        applications: {
            index: "/applications",
            create: (roleId: string) => `/roles/${roleId}/applications/create`,
            edit: (applicationId: string) => `/application/${applicationId}/edit`
        },
        gigs: {
            indexDraft: `/draft-${gigPlural}`,
            indexPosted: `/posted-${gigPlural}`,
            invites: "/gigs/invites",
            ongoing: `/ongoing-${gigPlural}`,
            completed: `/completed-${gigPlural}`,
            cancelled: `/cancelled-${gigPlural}`,
            details: (gigId: string) => `/${gigPlural}/${gigId}`,
            createIntro: `/${gigPlural}/create-intro`,
            create: `/${gigPlural}/create`,
            proposals: (gigId: string) => `/${gigPlural}/${gigId}/proposals`,
            matches: (gigId: string) => `/${gigPlural}/${gigId}/matches`,
            findAGig: `/find-a-${gig}`,
            findAGigDetails: (gigId: string) => `/find-a-${gig}/${gigId}`,
            edit: (gigId: string) => `/${gig}/${gigId}/edit`,
        },
        roles: {
            createIntro: "/roles/create-intro",
            create: "/roles/create",
            edit: (roleId: string) => `/role/${roleId}/edit`,
            findARole: "/find-a-role/",
            findARoleDetails: (roleId: string) => `/find-a-role/${roleId}`,
            draft: "/draft-roles",
            inReview: "/in-review-roles",
            open: "/open-roles",
            active: "/active-roles",
            closed: "/closed-roles",
            archived: "/archived-roles",
            applications: (roleId: string) => `/roles/${roleId}/applications`,
            details: (roleId: string) => `/roles/${roleId}`,
            matches: (roleId: string) => `/roles/${roleId}/matches`,
            invites: "/roles/invites",
            assignments: "/roles/assignments"
        },
        clients: {
            details: (clientId: string) => `/${clientPlural}/${clientId}`
        },
        talents: {
            findTalent: `/find-${talentPlural}`,
            findTalentDetails: (talentId: string) => `/find-${talentPlural}/${talentId}`
        },
        dashboard: "/dashboard",
        proposals: {
            index: "/proposals",
            create: (gigId: string) => `/${gigPlural}/${gigId}/proposals/create`,
            edit: (proposalId: string) => `/proposals/${proposalId}/edit`,
        },
        profile: {
            edit: "/edit-profile"
        },
        fourOFour: fourOFourPath,
        inbox: {
            index: "/inbox",
            archive: "/inbox/archive"
        },
        account: {
            manageUsers: "/manage-users"
        },
    });
};

export const useAppPaths = () => {
    const {
        gigTerminology,
        gigTerminologyPlural,
        giggedClientTerminologyPlural,
        talentTerminology,
        talentTerminologyPlural
    } = useOrganizationContext();

    return appPaths(
        gigTerminology,
        gigTerminologyPlural,
        giggedClientTerminologyPlural,
        talentTerminology,
        talentTerminologyPlural
    );
};

const AppRoutes: React.FC = () => {
    const { userRole, idpUserId } = useCurrentUser();
    const { organizationConfig } = useOrganizationContext();
    const routeAppPaths = useAppPaths();
    const { gigTerminology, gigTerminologyPlural, talentTerminology } = useOrganizationContext();
    const isAdmin = !userRole && idpUserId.length > 0;

    const disableFindTalentRoute =
        organizationConfig?.isTalentProfilesPrivate &&
        userRole === "talent";

    return (
        <Routes>
            <Route element={<RequireAuthenticated />}>
                <Route path={routeAppPaths.gigs.findAGigDetails(":gigId")} element={<WithTitle pageTitle={`Find a ${gigTerminology} - Details`} C={FindAGigDetailsPage} />} />
                {!isAdmin && (
                    <Route path={routeAppPaths.dashboard} element={userRole === "client" ? (
                        <WithTitle pageTitle="Your Dashboard - Gigged.AI" C={ClientDashboardPage} />
                    ) : (
                        <WithTitle pageTitle="Your Dashboard - Gigged.AI" C={TalentDashboardPage} />
                    )} />
                )}
                <Route path={routeAppPaths.gigs.indexDraft} element={<WithTitle pageTitle={`Draft ${gigTerminologyPlural} - Gigged.AI`} C={GigsListDraftPage} />} />
                <Route path={routeAppPaths.gigs.invites} element={<WithTitle pageTitle={`${gigTerminology} Invites - Gigged.AI`} C={GigInvitesPage} />} />
                <Route path={routeAppPaths.gigs.indexPosted} element={<WithTitle pageTitle={`Posted ${gigTerminologyPlural} - Gigged.AI`} C={GigsListPostedPage} />} />
                {userRole === "client" ? (
                    <Route path={routeAppPaths.gigs.ongoing} element={<WithTitle pageTitle={`Ongoing ${gigTerminologyPlural} - Gigged.AI`} C={GigsOngoingClientPage} />} />
                ) : (
                    <Route path={routeAppPaths.gigs.ongoing} element={<WithTitle pageTitle={`Ongoing ${gigTerminologyPlural} - Gigged.AI`} C={GigsOngoingTalentPage} />} />
                )}
                {userRole === "client" ? (
                    <Route path={routeAppPaths.gigs.completed} element={<WithTitle pageTitle={`Completed ${gigTerminologyPlural} - Gigged.AI`} C={GigsCompletedClientPage} />} />
                ) : (
                    <Route path={routeAppPaths.gigs.completed} element={<WithTitle pageTitle={`Completed ${gigTerminologyPlural} - Gigged.AI`} C={GigsCompletedTalentPage} />} />
                )}
                {userRole === "client" ? (
                    <Route path={routeAppPaths.gigs.cancelled} element={<WithTitle pageTitle={`Cancelled ${gigTerminologyPlural} - Gigged.AI`} C={GigsCancelledClientPage} />} />
                ) : (
                    <Route path={routeAppPaths.gigs.cancelled} element={<WithTitle pageTitle={`Cancelled ${gigTerminologyPlural} - Gigged.AI`} C={GigsCancelledTalentPage} />} />
                )}
                <Route path={routeAppPaths.gigs.details(":gigId")} element={<WithTitle pageTitle={`${gigTerminology} Details - Gigged.AI`} C={GigDetailsPage} />} />
                <Route path={routeAppPaths.gigs.createIntro} element={<WithTitle pageTitle={`Post a ${gigTerminology} - Gigged.AI`} C={GigCreateIntroPage} />} />
                <Route path={routeAppPaths.gigs.create} element={<WithTitle pageTitle={`Post a ${gigTerminology} - Gigged.AI`} C={GigCreatePage} />} />
                <Route path={routeAppPaths.gigs.matches(":gigId")} element={<WithTitle pageTitle={"Matches - Gigged.AI"} C={GigsMatchesListPage} />} />
                <Route path={routeAppPaths.gigs.edit(":gigId")} element={<WithTitle pageTitle={`Edit ${gigTerminology} - Gigged.AI`} C={GigEditPage} />} />
                <Route path={routeAppPaths.gigs.proposals(":gigId")} element={<WithTitle pageTitle={"Proposals - Gigged.AI"} C={GigsProposalsListPage} />} />

                <Route path={routeAppPaths.roles.edit(":roleId")} element={<WithTitle pageTitle={"Edit Role - Gigged.AI"} C={RoleEditPage} />} />
                <Route path={routeAppPaths.roles.createIntro} element={<WithTitle pageTitle={"Post a role - Gigged.AI"} C={RoleCreateIntroPage} />} />
                <Route path={routeAppPaths.roles.create} element={<WithTitle pageTitle={"Post a role - Gigged.AI"} C={RoleCreatePage} />} />
                <Route path={routeAppPaths.roles.findARoleDetails(":roleId")} element={<WithTitle pageTitle={"Find a role - Details"} C={FindARoleDetailsPage} />} />
                <Route path={routeAppPaths.roles.draft} element={<WithTitle pageTitle={"Draft Roles - Gigged.AI"} C={RolesListDraftPage} />} />
                <Route path={routeAppPaths.roles.inReview} element={<WithTitle pageTitle={"In Review Roles - Gigged.AI"} C={RolesListInReviewPage} />} />
                <Route path={routeAppPaths.roles.open} element={<WithTitle pageTitle={"Active Roles - Gigged.AI"} C={RolesListOpenPage} />} />
                <Route path={routeAppPaths.roles.active} element={<WithTitle pageTitle={"Active Roles - Gigged.AI"} C={RolesListActivePage} />} />
                <Route path={routeAppPaths.roles.closed} element={<WithTitle pageTitle={"Closed Roles - Gigged.AI"} C={RolesListClosedPage} />} />
                <Route path={routeAppPaths.roles.archived} element={<WithTitle pageTitle={"Archived Roles - Gigged.AI"} C={RolesListArchivedPage} />} />.
                <Route path={routeAppPaths.roles.applications(":roleId")} element={<WithTitle pageTitle={"Applications - Gigged.AI"} C={RoleApplicationsListPage} />} />
                <Route path={routeAppPaths.roles.details(":roleId")} element={<WithTitle pageTitle={"Role Details - Gigged.AI"} C={RoleDetailsPage} />} />
                <Route path={routeAppPaths.roles.matches(":roleId")} element={<WithTitle pageTitle={"Matches - Gigged.AI"} C={RolesMatchesListPage} />} />
                <Route path={routeAppPaths.roles.invites} element={<WithTitle pageTitle={"Role Invites - Gigged.AI"} C={RoleInvitesPage} />} />
                {userRole === "client" ? (
                    <Route path={routeAppPaths.roles.assignments} element={<WithTitle pageTitle={"Role Assignments - Gigged.AI"} C={ClientRoleAssignmentsPage} />} />
                ) : (
                    <Route path={routeAppPaths.roles.assignments} element={<WithTitle pageTitle={"Role Assignments - Gigged.AI"} C={TalentRoleAssignmentsPage} />} />
                )}

                <Route path={routeAppPaths.talents.findTalentDetails(":talentId")} element={<WithTitle pageTitle={`${talentTerminology} Details - Gigged.AI`} C={FindTalentDetails} />} />

                <Route path={routeAppPaths.proposals.index} element={<WithTitle pageTitle={"Proposals - Gigged.AI"} C={ProposalsListPage} />} />
                <Route path={routeAppPaths.proposals.create(":gigId")} element={<WithTitle pageTitle={"Create Proposal - Gigged.AI"} C={ProposalCreatePage} />} />
                <Route path={routeAppPaths.proposals.edit(":proposalId")} element={<WithTitle pageTitle={"Edit Proposal - Gigged.AI"} C={ProposalEditPage} />} />

                <Route path={routeAppPaths.applications.index} element={<WithTitle pageTitle={"Applications - Gigged.AI"} C={ApplicationsListPage} />} />
                <Route path={routeAppPaths.applications.create(":roleId")} element={<WithTitle pageTitle={"Create Application - Gigged.AI"} C={ApplicationCreatePage} />} />
                <Route path={routeAppPaths.applications.edit(":applicationId")} element={<WithTitle pageTitle={"Edit Application - Gigged.AI"} C={ApplicationEditPage} />} />

                {userRole === "client" ? (
                    <Route path={routeAppPaths.profile.edit} element={<WithTitle pageTitle={"Edit Profile - Gigged.AI"} C={ClientProfileEditPage} />} />
                ) : (
                    <Route path={routeAppPaths.profile.edit} element={<WithTitle pageTitle={"Edit Profile - Gigged.AI"} C={TalentProfileEditPage} />} />
                )}
                <Route path={routeAppPaths.fourOFour} element={<WithTitle pageTitle={"404 - Gigged.AI"} C={FourOFourPage} />} />
                <Route path={routeAppPaths.clients.details(":clientId")} element={<WithTitle pageTitle={"Client Profile - Gigged.AI"} C={ClientDetails} />} />
                <Route path={routeAppPaths.inbox.index} element={<WithTitle pageTitle="Inbox - Gigged.AI" C={InboxPage} />} />
                <Route path={routeAppPaths.inbox.archive} element={<WithTitle pageTitle={"Inbox Archive - Gigged.AI"} C={InboxArchivePage} />} />

                {organizationConfig.isAllowGiggedClientUserToInviteEnabled && <Route path={routeAppPaths.account.manageUsers} element={<WithTitle pageTitle={"Manage Users - Gigged.AI"} C={ManageUsersPage} />} />}

                <Route path={"/"} element={userRole === "talent" ? (
                    <WithTitle pageTitle="Your Dashboard - Gigged.AI" C={TalentDashboardPage} />
                ) : userRole === "client" ? (
                    <WithTitle pageTitle="Your Dashboard - Gigged.AI" C={ClientDashboardPage} />
                ) : <Navigate to={routeAppPaths.gigs.findAGig} replace />} />

                <Route path={routeAppPaths.gigs.findAGig} element={<WithTitle pageTitle={`Find a ${gigTerminology} - Gigged.AI`} C={FindAGigPage} />} />

                {!disableFindTalentRoute && <Route path={routeAppPaths.talents.findTalent} element={<WithTitle pageTitle={`Find ${talentTerminology} - Gigged.AI`} C={FindTalentPage} />} />}

                <Route path={routeAppPaths.roles.findARole} element={<WithTitle pageTitle={"Find a Role- Gigged.AI"} C={FindARolePage} />} />
            </Route>

            <Route path={routeAppPaths.login} element={<LoginPage />} />

            <Route path={signUpPath} element={<WithTitle pageTitle={"Sign Up - Gigged.AI"} C={SignUpPage} />} />

            <Route path="*" element={<Navigate to={routeAppPaths.login} replace />} />

        </Routes>
    );
};

export const useGigId = (): string => {
    const { gigId } = useParams();

    if (!gigId) throw new Error("No gig id param available.");

    return gigId;
};
export const useRoleId = (): string => {
    const { roleId } = useParams();

    if (!roleId) throw new Error("No role id param available.");

    return roleId;
};

export const useTalentId = (): string => {
    const { talentId } = useParams();

    if (!talentId) throw new Error("No talent id param available.");

    return talentId;
};

export const useProposalId = (): string => {
    const { proposalId } = useParams();

    if (!proposalId) throw new Error("No proposal id param available.");

    return proposalId;
};

export const useClientId = (): string => {
    const { clientId } = useParams();

    if (!clientId) throw new Error("No client id param available.");

    return clientId;
};

export const useApplicationId = (): string => {
    const { applicationId } = useParams();

    if (!applicationId) throw new Error("No application id param available.");

    return applicationId;
};

export default AppRoutes;