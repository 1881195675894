import { Check } from "phosphor-react";
import { Link } from "react-router-dom";

import Typography from "./Typography";
import LinkButton from "./LinkButton";
import Card from "./Card";
import Chip, { ChipContainer } from "./Chip";
import CardDataFieldLabel from "./Card/CardDataFieldLabel";
import TitleUnderline from "./TitleUnderline";
import { useAppPaths } from "../Routes";
import { ApiRoleSummaryDto } from "../models/api/role";
import useCurrentUser from "../auth/useCurrentUser";
import Loader from "./Loader";
import { getApplicationStatusLabelText } from "../utils/getApplicationStatusText";

export type RoleSummaryCardProps = {
    role: ApiRoleSummaryDto
    viewRoleLink: string
}

const RoleSummaryCard = ({
    role,
    viewRoleLink,
}: RoleSummaryCardProps) => {
    const appPaths = useAppPaths();
    const { userRole } = useCurrentUser();

    if (!role) return <Loader />;

    const applicationStatusLabel = getApplicationStatusLabelText(role.submittedApplicationStatusId);

    return (
        <Card className="space-y-6 w-full break-words" key={role.id}>
            <div className="flex sm:flex-row flex-col-reverse justify-between">
                <div className="space-y-4 pr-2">
                    <Typography variant="title-small" component="p">
                        <Link to={appPaths.clients.details(role.giggedClientId)}>
                            {role.giggedClientName}
                        </Link>
                    </Typography>
                    <Typography variant="title-large" component="h2" className="max-w-2xl">{role?.title}</Typography>
                    <div className="flex flex-row space-x-2"><CardDataFieldLabel>Role category: </CardDataFieldLabel><Typography variant="body" component="p">{role.roleCategoryName}</Typography></div>
                    <Typography variant="body" component="p" className="italic line-clamp-3">{role.description}</Typography>
                </div>
                {(userRole === "talent"  && applicationStatusLabel) && (
                    <div className="flex flex-row items-center mb-4 sm:mb-auto space-x-2 border rounded-md py-[0.25rem] px-[0.5rem] w-fit">
                        <Check size={18} />
                        <p className="text-[0.9rem] whitespace-nowrap">{applicationStatusLabel}</p>
                    </div>
                )}
            </div>
            <TitleUnderline fullWidth />
            <div className="flex justify-between flex-col md:flex-row space-y-4">
                <div className="space-y-2 md:w-4/5">
                    <CardDataFieldLabel>Skills required</CardDataFieldLabel>
                    <ChipContainer className="mr-4">
                        {role.skills.map(skill => (
                            <Chip key={skill.id} variant="secondary" label={skill.name} />
                        ))}
                    </ChipContainer>
                </div>
                <div className="block mt-4 sm:self-end sm:mt-0">
                    <LinkButton
                        to={viewRoleLink}
                        variant="secondary"
                        scrollToTop
                    >
                        View role
                    </LinkButton>
                </div>
            </div>
        </Card >
    );
};

export default RoleSummaryCard;
